import {createProvider} from "../../vue-apollo"
import axios from "axios";

const tableHeaders = [
    {
        text: "Code",
        value: "Code",
    },
    {
        text: "Name",
        value: "CentreName",
    },
    {
        text: "City",
        value: "City",
    },
    {
        text: "Country",
        value: "Country",
    },
    {
        text: "Address",
        value: "Address",
    },
    {
        text: "Status",
        value: "Status",
    },
    {
        text: "Fitting Cap.",
        value: "FittingCap",
    },
    {
        text: "JIT Cap.",
        value: "JITCap",
    },
    {
        text: "Store Code",
        value: "StoreCode",
    },
    {
        text: "Order Sequence",
        value: "OrderSequence",
    },
    {
        text: "Is Warehouse",
        value: "IsWarehouse",
    },
    {
        text: "Priority",
        value: "FulfilmentCentrePriorities",
    },
    {
        text: "Is Active For Consolidated Stock",
        value: "IsActiveForConsolidatedStock",
    },
]

const apolloProvider = createProvider()

const state = () => ({
    data: [],
    tableHeaders,
    loadingData: false,
    meta: {
        totalCount: 0,
        page: 1,
        perPage: 10,
    },
})

const getters = {
    getData: (state) => {
        return state.data
    },
    getTotalCount: (state) => {
        return state.meta.totalCount
    },
    getFulfilmentCentreHeaders: (state) => {
        return state.tableHeaders
    },
    loadingData: (state) => {
        return state.loadingData
    },
}

const actions = {
    async fetchFulfilmentCentres({commit}) {
        commit('FULFILMENT_CENTRES_REQUESTED')
        const response = await axios.get('/fulfilment-centres')
        commit('FULFILMENT_CENTRES_FETCHED', {"data": response.data})
    },
}

const mutations = {
    FULFILMENT_CENTRES_REQUESTED(state) {
        state.loadingData = true
        state.meta = {
            totalCount: 0,
            page: 1,
            perPage: 10,
        }
    },
    FULFILMENT_CENTRES_FETCHED(state, {data}) {
        state.data = data
        state.meta.totalCount = data.length
        state.loadingData = false
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
