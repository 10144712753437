import {onLogin, createProvider, onLogout} from "../../vue-apollo"
import axios from 'axios'

const apolloProvider = createProvider()

const state = () => ({
  loggedIn: false,
  user : {
    name : '',
    email: '',
    avatar: '',
  },
  sessionExpired: false,
});

const getters = {
  getUser: (state) => {
    return state.user
  },
  getToken: () => {
    return localStorage.getItem('apollo-token')
  },
  sessionExpired: (state) => {
    return state.sessionExpired
  }
}

const actions = {
  async login({commit, dispatch}, googleUser) {
    const response = googleUser.getAuthResponse()

    try {
      const loginResponse = await axios.post('/login', {
        token: response.id_token
      })
      const {access_token} = loginResponse.data
      await onLogin(apolloProvider.defaultClient, access_token)
      commit('LOGGED_IN')
      dispatch('getMe')
      return
    } catch (error) {
      commit('LOGIN_FAILED')
    }
  },
  async getMe({commit}) {
    try {
      const user = await axios.get('/me')
      commit('SET_USER', user.data)
    } catch (error) {
      commit('LOGOUT')
    }

  },
  async logout({commit}) {
    onLogout(apolloProvider.defaultClient)
    commit('LOGOUT')
  },
}

const mutations = {
  LOGGED_IN(state) {
    state.loggedIn =  true;
    state.sessionExpired = false
  },
  SET_USER(state, user) {
    localStorage.setItem('user', JSON.stringify(user))
    state.user = user
  },
  LOGOUT(state) {
    localStorage.removeItem('apollo-token')
    localStorage.removeItem('user')
    state.loggedIn = false
  },
  LOGIN_FAILED(state) {
    state.loggedIn = false
  },

  SESSION_EXPIRED(state) {
    localStorage.removeItem('apollo-token')
    localStorage.removeItem('user')
    state.sessionExpired = true
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
