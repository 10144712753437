import {createProvider} from "../../vue-apollo"
import dayjs from 'dayjs'

const apolloProvider = createProvider()

const state = () => ({
  systemSettings: {
    prescription_reminder_settings: {
      reminder_switch: true,
      reminder_frequency: null,
    },
  },
  saving: false,
  saveError: null,
})

const getters = {
  getSystemSettings(state) {
    return state.systemSettings
  },
  isSaving(state) {
    return state.saving
  }
}

const actions = {
  async fetchSystemSettings({ commit }) {
    commit('SYSTEM_SETTINGS_REQUESTED')

    try {
      const response = await apolloProvider.defaultClient.query({
        query: require("@/graphql/system_settings/FetchSystemSettings.gql"),
        fetchPolicy: 'no-cache',
      })
      commit('SYSTEM_SETTINGS_FETCHED', response.data.systemSettings)

    } catch (err) {
      let errMessage = 'Failed to fetch system settings!'
      if( err.graphQLErrors && err.graphQLErrors.length > 0) {
        errMessage = err.graphQLErrors[0].message
      }
      commit('SYSTEM_SETTINGS_REQUEST_FAILED', errMessage)
    }


  },
  async updateSystemSettings({ commit, getters, dispatch }) {


    if (!getters.getSystemSettings.prescription_reminder_settings.reminder_frequency ||
      getters.getSystemSettings.prescription_reminder_settings.reminder_switch === null ) {
      dispatch('notifications/showError', 'All fields are required!', { root: true})
      return
    }

    if (isNaN(getters.getSystemSettings.prescription_reminder_settings.reminder_frequency)) {
      dispatch('notifications/showError', 'Frequency needs to be a number!', { root: true})
      return
    }

    commit('SYSTEM_SETTINGS_UPDATING')

    let variables = getters.getSystemSettings.prescription_reminder_settings
    try {
      await apolloProvider.defaultClient.mutate({
        mutation: require("@/graphql/system_settings/UpdateSystemSettings.gql"),
        variables
      })
      commit('SYSTEM_SETTINGS_UPDATED')

    } catch (err) {
      commit('SYSTEM_SETTINGS_UPDATE_FAILED', err.graphQLErrors[0].message)
      return
    }

  },
}

const mutations = {

  SYSTEM_SETTINGS_REQUESTED(state, settings) {
    state.saving = true
  },
  SYSTEM_SETTINGS_REQUEST_FAILED(state) {
    state.saving = false
  },
  SYSTEM_SETTINGS_FETCHED(state, settings) {
    state.systemSettings = settings
    state.saving = false
  },
  SYSTEM_SETTINGS_UPDATING(state){
    state.saving = true
  },
  SYSTEM_SETTINGS_UPDATE_FAILED(state) {
    state.saving = false
  },
  SYSTEM_SETTINGS_UPDATED(state) {
    state.saving = false
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
