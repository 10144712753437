<template>
  <div>
    <component :is="layout" />
  </div>
</template>

<script>
  export default {
    name: 'App',
    computed: {
      layout() {
        return this.$route.meta && this.$route.meta.layout
          ? this.$route.meta.layout
          : 'admin-layout'
      },
    },
    beforeCreate() {
      const self = this
      self.$store.dispatch('auth/getMe')
      self.unsubscribe = self.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'auth/SET_USER':
            if (this.$route.name === 'Login') {
              self.$router.push({ 'name': 'Orders' })
              window.location.reload()
            }
            break
          case 'auth/LOGOUT':
            if (this.$route.name !== 'Login') {
              self.$router.push({ 'name': 'Login' })
            }
            break
          case 'auth/LOGIN_FAILED':
            self.$store.dispatch('notifications/showError', 'Login failed! Your account needs to be an eyewa.com email')
            break
        }
      })
     },
    destroyed() {
      this.unsubscribe()
    },
  }
</script>
<style>
.v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
  line-height: 1.4 !important;
}
label, select, .v-list-item__title {
  color: #757575 !important;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px  rgba(255, 255, 255, 0.726);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.726);
}
.scrolldiv {
  height:250px!important;
  overflow-y:scroll;
}
</style>
