<template>
  <v-container>
    <div class="vld-parent">
      <loading
          :active.sync="isFileUploading"
          :is-full-page="true"
      />
    </div>
    <search/>
    <v-container class="pt-7 pl-2 pb-8">
      <v-row class="pa-1">
        <form
            :action="exportUrl"
            method="post"
        >
          <input
              type="hidden"
              name="fileType"
              value="orders_export"
          >
          <input
              type="hidden"
              name="fulfilment_centre"
              :value="getCurrentFulfilmentCentre"
          >
          <input
              type="hidden"
              name="token"
              :value="getToken"
          >
          <input
              type="hidden"
              name="filters"
              :value="getValue"
          >
          <v-btn
              depressed
              large
              type="submit"
              class="mr-3"
              color="grey darken-3 white--text"
          >
            Export View as CSV
          </v-btn>
        </form>

        <form
            :action="exportUrl"
            method="post"
        >
          <input
              type="hidden"
              name="fileType"
              value="vendor_po_export"
          >
          <input
              type="hidden"
              name="fulfilment_centre"
              :value="getCurrentFulfilmentCentre"
          >
          <input
              type="hidden"
              name="token"
              :value="getToken"
          >
          <input
              type="hidden"
              name="filters"
              :value="getValue"
          >
          <v-btn
              type="submit"
              depressed
              large
              class="mr-3"
              color="grey darken-3 white--text"
              :disabled="getCurrentFulfilmentCentre.length > 1"
          >
            Generate Vendor PO
          </v-btn>
        </form>
        <v-btn
            depressed
            large
            type="submit"
            class="mr-3"
            color="light-green darken-1 white--text"
            @click="openDialog"
        >
          Update All Selected
        </v-btn>

        <v-dialog
            v-model="showDialog"
            persistent
            max-width="300"
        >
          <v-card>
            <v-card-title>
              <h3 class="headline">
                Update All Selected:
              </h3>
            </v-card-title>
            <v-card-text class="pb-0">
              <v-container>
                <v-row>
                  <v-select
                      v-model="order_status"
                      :items="getOrderStatuses"
                      label="Order Status"
                      outlined
                      @change="unsetSubReason"
                  />
                </v-row>
                <v-row v-if="showOOSHoldReason">
                  <v-select
                      v-model="oos_hold_reason"
                      :items="getHoldStatuses"
                      label="Order Status (Sub Reason)"
                      outlined
                  />
                </v-row>
                <v-row v-if="showEditedCancelReason">
                  <v-select
                      v-model="edited_cancel_reason"
                      :items="getCancellationReasons"
                      label="Order Status (Sub Reason)"
                      outlined
                  />
                </v-row>
                <v-row>
                  <v-select
                      v-model="fulfilment_centre"
                      outlined
                      :items="excludeCurrentFulfilmentCentre"
                      label="Select Fulfilment Centre"
                      item-value="Code"
                      item-text="DisplayName"
                  />
                </v-row>
                <v-row>
                  <v-text-field
                      v-model="status_comment"
                      label="Status Comment"
                      placeholder="Status Comment"
                      outlined
                  />
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="pt-0 pb-7 pr-6">
              <v-spacer/>
              <v-btn
                  depressed
                  color="grey darken-3 white--text"
                  class="pa-3"
                  :disabled="isSaving"
                  @click="closeDialog"
              >
                Cancel
              </v-btn>
              <v-btn
                  depressed
                  :disabled="shouldDisable"
                  color="light-green darken-1 white--text"
                  class="pa-3"
                  :loading="isSaving"
                  @click="bulkUpdateOrders"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
            depressed
            large
            type="submit"
            class="mr-3"
            color="light-green darken-1 white--text"
            :loading="isFileUploading"
            @click="chooseFile"
        >
          Bulk Upload
        </v-btn>
        <input
            ref="uploadBulkStock"
            type="file"
            style="display: none;"
            @change="uploadBulkStock"
        >
      </v-row>
    </v-container>

    <v-dialog
        v-model="show_comments_dialog"
        scrollable
        max-width="800px"
    >
      <v-card class="pa-0">
        <v-card-title class="pl-4 pb-3">
          Comments
        </v-card-title>
        <v-card-text
            style="max-height: 300px;"
            class="pa-0"
        >
          <v-simple-table
              dense
              class="pa-0"
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th
                    class="text-left"
                    width="30%"
                >
                  Time
                </th>
                <th class="text-left">
                  User
                </th>
                <th class="text-left">
                  Comment
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(comment, index) in dialog_comments"
                  :key="index"
              >
                <td>{{ formatCommentTS(comment.timestamp) }}</td>
                <td>{{ comment.user_email }}</td>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <td v-html="nl2br(comment.comment)"/>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-divider/>
        <v-card-actions class="pt-5 pb-5">
          <v-spacer/>
          <v-btn
              depressed
              color="grey darken-3 white--text"
              class="pa-3"
              @click="closeCommentsDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
        v-model="selectedOrders"
        :headers="getHeaders"
        :items="getOrders"
        :search="search"
        :loading="loading"
        :server-items-length.sync="totalCount"
        :options.sync="options"
        show-select
        :expanded="expanded"
        :footer-props="footer_props"
    >
      <template v-slot:top="{ pagination, updateOptions }">
        <v-data-footer
            :pagination="pagination"
            :options="options"
            :items-per-page-options="pagination_options"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            @update:options="updateOptions"
        />
      </template>

      <template v-slot:[`item.order_tracking_number`]="{ item }">
        <a
            v-if="isUrl(item.order_tracking_number)"
            :href="item.order_tracking_number"
            target="_blank"
        >
          {{ item.order_tracking_number }}
        </a>
        <span v-else>
          {{ item.order_tracking_number }}
        </span>
      </template>
      <template v-slot:[`item.grand_total`]="{ item }">
        {{ getCurrency(item) }} {{ item.grand_total }}
      </template>
      <template v-slot:[`item.cod_fee`]="{ item }">
        {{ getCurrency(item) }} {{ item.cod_fee }}
      </template>
      <template v-slot:[`item.discount_amount`]="{ item }">
        {{ getCurrency(item) }} {{ item.discount_amount }}
      </template>
      <template v-slot:[`item.shipping_fee`]="{ item }">
        {{ getCurrency(item) }} {{ item.shipping_fee }}
      </template>
      <template v-slot:[`item.shipping_date`]="{ item }">
        {{ getShippingDate(item) }}
      </template>

      <template v-slot:[`item.store`]="{ item }">
        {{ getStoreName(item) }}
      </template>

      <template v-slot:[`item.order_number`]="{ item }">
        <router-link :to="{name:'Order', params: { id: item.id }}">
          {{ item.order_number }}
        </router-link>
      </template>

      <template v-slot:[`item.delivery_date`]="{ item }">
        {{ item.delivery_date }}
      </template>

      <template v-slot:[`item.related_order`]="{ item }">
        <router-link :to="{name:'Order', params: { id: item.related_order }}">
          {{ item.related_order }}
        </router-link>
      </template>

      <template v-slot:[`item.comments`]="{ item }">
        <v-btn
            v-if="item.comments.length > 0"
            color="blue-grey lighten-1"
            class="white--text"
            fab
            x-small
            depressed
            @click="showCommentsDialog(item.comments)"
        >
          <v-icon>mdi-comment-text-multiple-outline</v-icon>
        </v-btn>
        <v-btn
            v-else
            fab
            x-small
            depressed
            disabled
        >
          <v-icon>mdi-comment-text-multiple-outline</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.address`]="{ item }">
        {{ !item.show_less ? item.address.substring(0, 9) : item.address }}
        <v-btn
            v-if="item.address.length > 9 && !item.show_less"
            icon
            @click="toggleAddress(item)"
        >
          <v-icon>mdi-plus-thick</v-icon>
        </v-btn>

        <v-btn
            v-if="item.address.length > 9 && item.show_less"
            icon
            @click="toggleAddress(item)"
        >
          <v-icon>mdi-minus-thick</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.products`]="{ item }">
        <v-btn
            v-if="item.products.length > 0"
            color="light-blue lighten-1"
            class="white--text"
            fab
            x-small
            depressed
            @click="expand(item)"
        >
          <v-icon>mdi-cart-variant</v-icon>
        </v-btn>
        <v-btn
            v-else
            disabled
            fab
            x-small
            depressed
        >
          <v-icon>mdi-cart-variant</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.order_created_at`]="{ item }">
        <span>{{ formatTS(item.order_created_at) }}</span>
      </template>

      <template v-slot:[`item.fulfilment_centre`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span
                color="primary"
                dark
                v-bind="attrs"
                class="text-underline"
                v-on="on"
            >
              {{ item.fulfilment_centre.id }}
            </span>
          </template>
          <span>
            {{ getFulfilmentCentreName(item.fulfilment_centre.id) }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.shipped_to_fc`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span
                color="primary"
                dark
                v-bind="attrs"
                class="text-underline"
                v-on="on"
            >
              {{ item.shipped_to_fc.id }}
            </span>
          </template>
          <span>
            {{ getFulfilmentCentreName(item.shipped_to_fc.id) }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.order_status`]="{ item }">
        <v-chip
            :color="colorCodeOrderStatus(item.order_status)"
            class="white--text"
        >
          {{ item.order_status }}
        </v-chip>
      </template>

      <template v-slot:[`item.order_type`]="{ item }">
        <v-chip
            class="grey lighten-1 ml-2"
            color="orange"
            label
            outlined
        >
          {{ item.order_type }}
        </v-chip>
      </template>

      <template v-slot:[`item.secondary_order_status_timestamp`]="{ item }">
        <span>{{ formatTS(item.secondary_order_status_timestamp) }}</span>
      </template>

      <template
          v-if="enabledProducts"
          v-slot:[`expanded-item`]="{ headers, item }"
      >
        <td
            :colspan="headers.length"
            class="pt-5 pb-7"
        >
          <v-col
              style="width:80vw;"
          >
            <v-simple-table
                dense
                fixed-header
            >
              <template v-slot:default>
                <thead>
                <tr>
                  <th
                      width="1%"
                  >
                    Qty
                  </th>
                  <th width="5%">
                    Sku
                  </th>
                  <th width="5%">
                    Product
                  </th>
                  <th width="5%">
                    Power
                  </th>
                  <th width="5%">
                    Prescription URL
                  </th>
                  <th width="2%">
                    SKU Status
                  </th>
                  <th width="2%">
                    Original Price
                  </th>
                  <th width="2%">
                    Sale Price
                  </th>
                  <th width="2%">
                    Sub Total
                  </th>
                  <th width="2%">
                    Tax Amount
                  </th>
                  <th width="2%">
                    Discount Amount
                  </th>
                  <th width="2%">
                    Total Price
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="product in item.products"
                    :key="`${item.order_number}-${product.sku}`"
                >
                  <td valign="top">
                    {{ product.quantity }}
                  </td>
                  <td valign="top">
                    {{ product.sku }}
                  </td>
                  <td valign="top">
                    {{ product.name }}
                  </td>
                  <td
                      v-if="product.power !== null"
                      valign="top"
                  >
                    {{ formatPower(product.power) }}
                  </td>
                  <td
                      v-else
                      valign="top"
                  >
                    N/A
                  </td>
                  <td valign="top">
                    <a
                        v-if="getUrlFromPower(product.power)"
                        target="_blank"
                        :href="getUrlFromPower(product.power)"
                    >
                      View Prescription
                      <v-icon>mdi-open-in-new</v-icon>
                    </a>
                    <template v-else>
                      N/A
                    </template>
                  </td>
                  <td
                      valign="top"
                      class="font-weight-bold"
                  >
                    {{ product.status }}
                  </td>
                  <td valign="top">
                    {{ product.original_price }}
                  </td>
                  <td valign="top">
                    {{ product.price_incl_tax }}
                  </td>
                  <td valign="top">
                    {{ product.sub_total }}
                  </td>
                  <td valign="top">
                    {{ product.tax_amount }}
                  </td>
                  <td valign="top">
                    {{ product.discount_amount }}
                  </td>
                  <td valign="top">
                    {{ product.row_total_incl_tax }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {mapGetters} from 'vuex'
import Search from "../components/Search"
import Configuration from '../utils/config'
import strings from "../mixins/strings";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    Loading,
    Search
  },
  mixins: [strings],
  data() {
    return {
      unsubscribe: () => {
      },
      totalCount: 0,
      options: {},
      search: '',
      orders: [],
      enabledHeaders: [],
      selectedOrders: [],
      isSaving: false,
      showDialog: false,
      fulfilment_centre: '',
      status_comment: '',
      order_status: '',
      oos_hold_reason: '',
      edited_cancel_reason: '',
      expanded: [],
      pagination_options: [5, 10, 15, 20],
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      show_comments_dialog: false,
      dialog_comments: [],
    }
  },
  computed: {
    showOOSHoldReason() {
      switch (this.order_status) {
        case 'OOS':
        case 'On_Hold':
          return true
        default:
          return false
      }
    },
    showEditedCancelReason() {
      switch (this.order_status) {
        case 'Edited':
        case 'Cancelled':
          return true
        default:
          return false
      }
    },
    enabledProducts() {
      return this.getEnabledHeaders && this.getEnabledHeaders.includes('products')
    },
    getValue() {
      const filters = {...this.$store.getters['filters/getNonEmptyCurrentFilters']};
      if (filters.id === 'default') {
        const extraFields = ['customer_surveyed', 'related_orders', 'retail_telephone', 'shipping_street', 'shipping_area', 'gmap_coordinates', 'latitude', 'longitude']
        extraFields.forEach(column => {
          if (!filters.columns.includes(column)) {
            filters.columns.push(column)
          }
        })
      }

      return JSON.stringify(filters)
    },
    exportUrl() {
      return Configuration.value("apiBaseURL") + '/export'
    },
    shouldDisable() {
      return this.selectedOrders.length === 0 ||
          (this.order_status.length > 0 && this.status_comment.length < 1) ||
          (['OOS', 'On_Hold'].includes(this.order_status) && this.oos_hold_reason.length < 1) ||
          (['Edited', 'Cancelled'].includes(this.order_status) && this.edited_cancel_reason.length < 1)
    },
    getSelectedItems() {
      return this.selectedOrders
    },
    ...mapGetters('orders', [
      'getOrders',
      'loading',
      'getMeta',
      'savedBulkOrderUpdates',
    ]),
    ...mapGetters('metas', [
      'getOrderStatuses',
      'getFulfilmentCentres',
      'getCancellationReasons',
      'getHoldStatuses',
      'getPOSStores',

    ]),
    ...mapGetters('filters', [
      'getCurrentFilter',
      'getHeaders',
      'getDefaultHeaders',
      'getEnabledHeaders',
      'getCurrentFulfilmentCentre',
      'excludeCurrentFulfilmentCentre',
    ]),
    ...mapGetters('auth', [
      'getUser',
      'getToken',
    ]),
    ...mapGetters('uploads', [
      'isFileUploading',
    ]),
  },
  watch: {
    options: {
      handler() {
        this.$store.dispatch('orders/fetchOrders', this.options)
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch('metas/fetchFulfilmentCentres')
    dayjs.extend(localizedFormat)
    dayjs.extend(utc)
    dayjs.extend(timezone)

    const self = this
    self.unsubscribe = self.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'orders/META_FETCHED':
          self.totalCount = state.orders.meta.totalCount
          break
        case 'orders/ORDERS_FETCHING_FAILED':
          self.$store.dispatch('notifications/showError', 'Error fetching orders. Please try again')
          break
        case 'orders/RESET_PAGINATION':
          //we listen to reset pagination and trigger options change,
          // which we are watching for pagination,
          //hence, this automatically calls orders list
          this.options = {page: 1, itemsPerPage: 10}
          break
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  methods: {
    getUrlFromPower(power) {
      return power ? power.prescription_url : ''
    },
    getStoreName(order) {
      return order.store_details.store_name
    },
    getFulfilmentCentreName(id) {
      const fulfillmentCentre = this.getFulfilmentCentres.find(centre => centre.Code === id)

      if (fulfillmentCentre) {
        return `${fulfillmentCentre.Code} - ${fulfillmentCentre.CentreName}`
      }
      return ''
    },
    getCurrency(item) {
      if (item.store_details) {
        return item.store_details.currency
      }
      return ''
    },
    getShippingDate(item) {
      return item.shipping_date ? dayjs(item.shipping_date).format('YYYY-MM-DD') : ""
    },
    toggleAddress(item) {
      this.$store.commit('orders/TOGGLE_ADDRESS_LENGTH', item)
    },
    chooseFile() {
      this.$refs.uploadBulkStock.click()
    },
    uploadBulkStock($event) {
      const files = $event.target.files
      const formData = new FormData()
      formData.append('fulfilment_centre', this.getCurrentFulfilmentCentre)

      for (let index in files) {
        if (Object.prototype.hasOwnProperty.call(files, index)) {
          formData.append('uploadfile', files[index])
        }
      }

      formData.append('fileType', 'orders_upload_type')
      this.$store.dispatch('uploads/uploadFile', {
        formData,
      })
      // Reset the FileList, as otherwise the second and subsequent times
      // we browse, nothing will happen, because nothing changed.
      $event.target.value = "";
    },
    expand(item) {
      //we need to keep only one products open at one time
      let exists = this.expanded.find(element => element.order_number === item.order_number)
      if (exists !== undefined) {
        this.expanded = []
        return
      }

      this.expanded = [item]
    },
    bulkUpdateOrders() {
      if (this.getSelectedItems.length > 0) {
        let orderIDs = []
        this.isSaving = true
        this.getSelectedItems.map(({id}) => {
          orderIDs.push(id)
        })
        let variables = {
          'order_ids': orderIDs,
          'fulfilment_centre': this.fulfilment_centre,
          'status_comment': {
            'user_email': this.getUser.email,
            'comment': this.status_comment
          },
          'order_status': this.order_status,
          'oos_hold_reason': this.oos_hold_reason,
          'edited_cancel_reason': this.edited_cancel_reason,
          'selected_items': this.getSelectedItems,
        }
        this.$store.dispatch('orders/bulkUpdateOrders', variables)
            .then(() => {
              if (this.savedBulkOrderUpdates) {
                this.resetData()
                this.$store.dispatch('notifications/showSuccess', 'Order(s) updated successfully.')
              } else {
                this.$store.dispatch('notifications/showError', 'Order(s) selected failed to update.')
              }
              this.isSaving = false
            })
      }
    },
    openDialog() {
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
      this.resetData()
    },
    resetData() {
      this.showDialog = false
      this.status_comment = ''
      this.fulfilment_centre = ''
      this.order_status = ''
      this.selectedOrders = []
      this.oos_hold_reason = ''
      this.edited_cancel_reason = ''
    },
    formatPower(power) {

      const clonedPower = {...power}
      delete clonedPower.__typename
      delete clonedPower.prescription_url

      return clonedPower
    },
    unsetSubReason() {
      switch (this.order_status) {
        case 'OOS':
        case 'On_Hold':
        case 'Edited':
        case 'Cancelled':
          break
        default:
          this.oos_hold_reason = ''
          this.edited_cancel_reason = ''
      }
    },
    showCommentsDialog(comments) {
      this.show_comments_dialog = true
      this.dialog_comments = comments
    },
    closeCommentsDialog() {
      this.show_comments_dialog = false
      this.dialog_comments = []
    },
    formatTS(timestamp) {
      if (timestamp !== "") {
        return dayjs.tz(timestamp, "Asia/Dubai").format('lll')
      }
    },
    formatCommentTS(timestamp) {
      if (timestamp !== "") {
        return dayjs(timestamp).format('lll')
      }
    },
    colorCodeOrderStatus(status) {
      switch (status) {
        case 'In_Stock':
          return 'green lighten-1'
        case 'Partially_In_Stock':
          return 'teal lighten-2'
        case 'Ordered':
          return 'amber lighten-1'
        case 'OOS':
          return 'grey lighten-1'
        case 'On_Hold':
          return 'blue lighten-1'
        case 'Cancelled':
          return 'red lighten-1'
        case 'Shipped':
          return 'light-blue lighten-2'
        case 'Edited':
          return 'yellow darken-4'
        default:
          return 'purple lighten-2'
      }
    },
  },
}
</script>



