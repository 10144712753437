<template>
  <v-container>
    <div class="vld-parent">
      <loading
        :active.sync="isFileUploading"
        :is-full-page="true"
      />
    </div>
    <v-row>
      <v-col
        sm="6"
        md="3"
        lg="3"
        xs="12"
      >
        <v-select
          v-model="currentFC"
          :items="getFulfilmentCentres"
          label="Fulfilment Centre"
          item-text="DisplayName"
          item-value="Code"
          filled
          @change="update"
        />
      </v-col>
      <v-col
        sm="6"
        md="5"
        lg="6"
      >
        <v-text-field
          v-model="searchText"
          placeholder="Search By SKU or Product Name, Press Enter to Search"
          outlined
          @keypress.enter="searchJIT"
        />
      </v-col>
      <v-col>
        <v-btn
          x-large
          depressed
          :loading="loadingInvJIT"
          color="light-blue darken-1"
          class="white--text text--darken-3"
          @click="searchJIT"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="3"
      >
        <v-select
          v-model="filter_jit_cap"
          :items="getJITCapabilities"
          label="JIT Cap"
          outlined
          @change="searchJIT"
        />
      </v-col>
    </v-row>
    <v-row class="pt-0 pb-3 pl-3">
      <template>
        <form
          :action="exportUrl"
          method="post"
        >
          <input
            type="hidden"
            name="inventory_filters"
            :value="inventoryFilters"
          >
          <input
            type="hidden"
            name="fileType"
            value="inventory_jit_export"
          >
          <input
            type="hidden"
            name="fulfilment_centre"
            :value="getCurrentFulfilmentCentre"
          >
          <input
            type="hidden"
            name="token"
            :value="getToken"
          >
          <v-btn
            depressed
            large
            type="submit"
            class="mr-3"
            color="grey darken-3 white--text"
          >
            Export View as CSV
          </v-btn>
        </form>
        <v-btn
          depressed
          large
          type="submit"
          class="mr-3"
          color="light-green darken-1 white--text"
          @click="openDialog"
        >
          Update All Selected
        </v-btn>
        <v-btn
          depressed
          large
          type="submit"
          color="light-green darken-1 white--text"
          class="mr-3"
          :loading="isFileUploading"
          @click="chooseFile"
        >
          Bulk Upload
        </v-btn>
        <input
          ref="uploadInventory"
          type="file"
          style="display: none;"
          @change="uploadInventoryJIT"
        >
      </template>
    </v-row>
    <v-row class="pt-7">
      <v-col
        sm="6"
        md="5"
        lg="3"
        xs="12"
        class="pb-10 pt-0"
      >
        <v-text-field
          v-model="keyword"
          outlined
          placeholder="Press Enter to search"
          label="Keyword Search"
          hide-details="auto"
          @keypress.enter="addKeyword"
        />
      </v-col>
      <v-col>
        <v-chip
          v-for="(word, index) in keywords"
          :key="`keyword-${index}`"
          class="ma-2"
          close
          color="red"
          label
          outlined
          @click:close="removeKeyword(index)"
        >
          {{ word }}
        </v-chip>
      </v-col>
    </v-row>

    <v-data-table
      v-model="selectedItems"
      show-select
      :headers="getInventoryJITHeaders"
      :items="getInventoryJIT"
      :loading="loadingInvJIT"
      item-key="sku"
      :options.sync="options"
      :server-items-length.sync="getJITTotalCount"
      :footer-props="footer_props"
    >
      <template v-slot:top="{ pagination, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          :items-per-page-options="pagination_options"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          @update:options="updateOptions"
        />
      </template>

      <template v-slot:[`item.vendor`]="{ item }">
        <v-edit-dialog
          large
          :return-value.sync="item.vendor"
          @open="openVendor(item.vendor)"
          @save="updateVendor(getInventoryJIT.indexOf(item), item.sku, item.vendor)"
        >
          <a href="#">{{ item.vendor }}</a>
          <template v-slot:input>
            <v-text-field
              v-model="item.vendor"
              :rules="[rules.notempty]"
              label="Edit"
              single-line
            />
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.jitcap`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.jitcap"
          large
          @open="openJITCap(item.jitcap)"
          @save="updateJITCapability(getInventoryJIT.indexOf(item), item.sku, item.jitcap)"
        >
          <a
            href="#"
          >{{ item.jitcap }}</a>
          <template v-slot:input>
            <v-select
              v-model="item.jitcap"
              :items="getJITCapForBulk"
            />
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="300"
    >
      <v-card>
        <v-card-title>
          <h3 class="headline">
            Update All Selected:
          </h3>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-select
                v-model="bulk_jit_cap"
                label="JIT Cap"
                :items="getJITCapForBulk"
                outlined
              />
            </v-row>
            <v-row>
              <v-text-field
                v-model="bulk_jit_vendor"
                label="Vendor"
                outlined
                class="pt-0"
              />
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-0 pb-7 pr-6">
          <v-spacer />
          <v-btn
            depressed
            color="grey darken-3 white--text"
            class="pa-3"
            @click="closeDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            depressed
            color="light-green darken-1 white--text"
            :disabled="showUpdateBtn"
            @click="updateSelectedItems"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Configuration from  '../utils/config'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    components: {
      Loading
    },
    data() {
      return {
        totalCount: 0,
        inventory: [],
        options: {},
        currentFC: '',
        searchText : '',
        keyword: '',
        keywords: [],
        bulk_jit_cap: '',
        bulk_jit_vendor: '',
        filter_jit_cap: 'all',
        current_jit_cap: null,
        current_vendor: null,
        selectedItems: [],
        showDialog: false,
        bulkUpdating: false,
        pagination_options: [5, 10, 15, 20],
        footer_props: {
          'items-per-page-options': [5, 10, 15, 20],
        },
        rules: {
          notempty: value => value !== '' || 'Cannot be empty!',
        },
      }
    },
    computed: {
      ...mapGetters('inventory',[
        'getInventoryJIT',
        'getInventoryJITHeaders',
        'loadingInvJIT',
        'getJITTotalCount',
        'getCurrentFulfilmentCentre',
      ]),
      ...mapGetters('metas', [
        'getFulfilmentCentres',

      ]),
      ...mapGetters('auth', [
        'getUser',
        'getToken',
      ]),
      ...mapGetters('uploads', [
        'isFileUploading',
      ]),
      exportUrl() {
        return Configuration.value("apiBaseURL") + '/export'
      },
      getJITCapabilities() {
        return [
          {
            text: 'Yes',
            value: true,
          },
          {
            text: 'No',
            value: false,
          },
          {
            text: 'All',
            value: 'all',
          },
        ]
      },
      getJITCapForBulk() {
        return this.getJITCapabilities.filter( item => item.text !== 'All')
      },
      getSelectedItems() {
        return this.selectedItems
      },
      showUpdateBtn() {
        return this.getSelectedItems.length === 0 ||
          (this.bulk_jit_cap === '' && this.bulk_jit_vendor === '')
      },
      inventoryFilters() {
        return JSON.stringify({
          'fulfilment_centre': this.getCurrentFulfilmentCentre,
          'search_text' : this.searchText,
          'keywords' : this.keywords,
        })
      },
    },
    watch: {
      currentFC: {
        handler () {
          this.searchInventoryJIT()
        },
      },
      options: {
        handler() {
          this.searchInventoryJIT()
        },
        deep: true,
      },
    },
    beforeDestroy() {
      this.unsubscribe()
    },
    mounted() {
      this.currentFC = this.getCurrentFulfilmentCentre
      this.$store.dispatch('metas/fetchFulfilmentCentres')

      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case 'inventory/BULK_INVENTORY_JIT_UPDATED':
            self.$store.dispatch('notifications/showSuccess', 'Inventory uploaded successfully.')
            break
          case 'inventory/BULK_INVENTORY_JIT_UPDATE_FAILED':
            self.$store.dispatch('notifications/showError', 'Failed to upload Inventory. Please try again.')
            break
          case 'uploads/FILE_UPLOADED':
            this.$store.dispatch('notifications/showSuccess', 'File successfully processed!')
            break
          case 'uploads/FILE_UPLOAD_FAILED':
            this.$store.dispatch('notifications/showError', 'File upload failed!')
            break
        }
      })
    },
    methods: {
      addKeyword(keyword) {
        if(this.keyword.length > 0) {
          this.keywords.push(this.keyword)
          this.keyword = ''
        }
        this.searchInventoryJIT()
      },
      removeKeyword(index) {
        this.keywords.splice(index, 1)
        this.searchInventoryJIT()
      },
      searchInventoryJIT() {
        let variables = {
          'fulfilment_centre': this.getCurrentFulfilmentCentre,
          'page': this.options.page,
          'per_page': this.options.itemsPerPage,
          'search_text' : this.searchText,
          'keywords' : this.keywords,
        }
        if (this.filter_jit_cap !== 'all') {
          variables.jit_cap = this.filter_jit_cap
        }
        this.$store.dispatch('inventory/fetchInventoryJIT', variables)
      },
      searchJIT() {
        this.options = { page: 1, itemsPerPage: 10 }
      },
      update(centre) {
        this.$store.dispatch('inventory/setCurrentFC', centre)
      },
      chooseFile() {
        this.$refs.uploadInventory.click()
      },
      uploadInventoryJIT($event) {
        const files = $event.target.files
        const formData = new FormData()
        formData.append('fulfilment_centre', this.getCurrentFulfilmentCentre)
        for (let index in files) {
          if (Object.prototype.hasOwnProperty.call(files, index)) {
            formData.append('uploadfile', files[index])
          }
        }

        formData.append('fileType', 'inventory_jit_upload')
        this.$store.dispatch('uploads/uploadFile', {formData})

        // Reset the FileList, as otherwise the second and subsequent times
        // we browse, nothing will happen, because nothing changed.
        $event.target.value = "";
      },
      openDialog() {
        this.showDialog = true
      },
      closeDialog() {
        this.bulk_jit_cap = ''
        this.showDialog = false
        this.selectedItems = []
      },
      openVendor(vendor) {
        this.current_vendor = vendor
      },
      openJITCap(cap) {
        this.current_jit_cap = cap
      },
      updateSelectedItems() {
        if (this.getSelectedItems.length > 0) {
          this.bulkUpdating = true
          let skus = []
          this.getSelectedItems.map( ({sku}) => {
            skus.push(sku)
          })
          let variables = {
            'skus' : skus,
            'vendor' : this.vendor,
            'fulfilment_centre': this.getCurrentFulfilmentCentre,
            'selected_items': this.getSelectedItems,
          }
          if (this.bulk_jit_cap !== '') {
            variables.jit_cap = this.bulk_jit_cap
          }
          if (this.bulk_jit_vendor !== '') {
            variables.vendor = this.bulk_jit_vendor
          }
          this.$store.dispatch('inventory/bulkUpdateJIT', variables)
          .then( (response) => {
            if (response !== undefined && response.errors === undefined) {
              this.showDialog = false
              this.bulk_jit_cap = ''
              this.bulk_jit_vendor = ''
              this.bulkUpdating = false
              this.selectedItems = []
              this.$store.dispatch('notifications/showSuccess', 'SKUs updated successfully.')
            } else {
              this.bulkUpdating = false
              this.$store.dispatch('notifications/showError', 'SKUs update failed.')
            }
          })
        } else {
          this.bulkUpdating = false
          this.$store.dispatch('notifications/showError', 'No SKU(s) was selected!')
        }
      },
      updateVendor(item, sku, vendor) {
        sku = this.getSKUIndx(sku)
        let current_vendor = this.current_vendor
        this.$store.dispatch('inventory/updateJITVendor', {sku, vendor, item, current_vendor})
        .then( (res) => {
          if (res !== undefined && res.errors === undefined) {
            this.$store.dispatch('notifications/showSuccess', 'Vendor updated successfully')
            this.resetData()
          } else {
            console.log(res)
            this.$store.dispatch('notifications/showError', "Failed to update Vendor name")
          }
        })
      },
      updateJITCapability(item, sku, jit_cap) {
        sku = this.getSKUIndx(sku)
        let current_jit_cap = this.current_jit_cap
        this.$store.dispatch('inventory/updateJITCap', {sku, jit_cap, item, current_jit_cap})
        .then( (res) => {
          if (res !== undefined && res.errors === undefined) {
            this.$store.dispatch('notifications/showSuccess', 'JIT Cap updated successfully')
            this.resetData()
          } else {
            console.log(res)
            this.$store.dispatch('notifications/showError', "Failed to update JIT Cap for SKU")
          }
        })
      },
      resetData() {
        this.current_jit_cap = null
        this.current_vendor = null
      },
      getSKUIndx(sku) {
        return sku.toLowerCase() + "-" + this.getCurrentFulfilmentCentre.toLowerCase()
      },
    },
  }
</script>
