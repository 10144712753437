<template>
  <v-app id="inspire">
    <v-app-bar
      app
      color="blue lighten-3 accent-4"
      dense
      dark
    >
      <v-app-bar-nav-icon @click="toggleDrawer" />

      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>

      <v-spacer />

      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-avatar size="40">
              <img
                :alt="getUser.name"
                :src="getUser.avatar"
              >
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ getUser.name }}</v-list-item-title>
              <v-list-item-subtitle
                color="light-blue darken-1"
                class="grey--text text--lighten-1"
              >
                {{ getUser.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item
            link
            @click="logout"
          >
            <v-list-item-title>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              Eyewa OMS
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list
        nav
        dense
      >
        <v-list-item
          link
          :to="{name: 'Orders'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-cart-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Orders
          </v-list-item-title>
        </v-list-item>

        <v-list-group
          :value="false"
          prepend-icon="mdi-cogs"
        >
          <template v-slot:activator>
            <v-list-item-title>Operations</v-list-item-title>
          </template>
          <v-list-item
            link
            to="/sku-status-transition"
          >
            <v-list-item-icon>
              <v-icon>mdi-transit-connection-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>SKU Status Transition</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :value="false"
          prepend-icon="mdi-clipboard-list"
        >
          <template v-slot:activator>
            <v-list-item-title>Inventory</v-list-item-title>
          </template>
          <v-list-item
            link
            to="/inventory"
          >
            <v-list-item-icon>
              <v-icon>mdi-clipboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Stock Sheet</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            to="/inventory-jit"
          >
            <v-list-item-icon>
              <v-icon>mdi-clipboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Back Order</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            to="/fulfilment-centres"
          >
            <v-list-item-icon>
              <v-icon>mdi-warehouse</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Fulfilment Centres</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <div
          v-show="isMerchandiserUploadEnabled"
          id="merch"
        >
          <v-list-group
            :value="false"
            prepend-icon="mdi-clipboard-list"
          >
            <template v-slot:activator>
              <v-list-item-title>Merchandiser</v-list-item-title>
            </template>
            <v-list-item
              link
              to="/merchandiser-uploads"
            >
              <v-list-item-icon>
                <v-icon>mdi-clipboard</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Upload</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>


        <v-list-item
          link
          :to="{name: 'Uploads'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-upload</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Uploads</v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          :to="{name: 'SystemSettings'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-wrench</v-icon>
          </v-list-item-icon>
          <v-list-item-title>System Settings</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <errors />
      <notification />
      <login-popup />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Notification from "@/components/common/Notification"
  import LoginPopup from "@/components/LoginPopup"
  import Errors from "@/components/common/Errors";
  import Configuration from "../utils/config";

  export default {
    name : 'Admin',
    components: { Notification, LoginPopup, Errors },
    data: () => ({
      drawer: false
    }),
    computed : {
      ...mapGetters('auth', [
        'getUser'
      ]),
      title() {
        return this.$route.meta && this.$route.meta.title
      },
      isMerchandiserUploadEnabled() {
        const features =  Configuration.value("features")
        return features.merchandiserUploadEnabled === 'true'
      },
    },
    mounted() {
      const params = new URLSearchParams(window.location.search)
      const error = params.get("error")
      if(error) {
        this.$store.dispatch('notifications/showError', error)
        window.history.pushState({}, document.title, window.location.pathname);
      }
    },
    methods: {
      logout() {
        this.$store.dispatch('auth/logout')
      },
      toggleDrawer() {
        this.drawer = !this.drawer
      },
    },
  }
</script>


