<template>
  <v-card>
    <GoogleLogin
      style="margin: auto;"
      :params="params"
      :render-params="renderParams"
      :on-success="onSuccess"
      :on-failure="onFailure"
    />
  </v-card>
</template>
<script>
  import GoogleLogin from 'vue-google-login'
  import Configuration from  '../utils/config'

  export default {
    name: 'Login',
    components: {
      GoogleLogin
    },
    data() {
      return {
        // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
        params: {
          client_id: Configuration.value("googleKey"),
          logoutButton: true
        },
        // only needed if you want to render the button with the google ui
        renderParams: {
          width: 400,
          height: 50,
          longtitle: true
        }
      }
    },
    methods: {
      onSuccess(googleUser) {
        this.$store.dispatch('auth/login', googleUser)
      },
      onFailure() {
        this.$store.dispatch('notifications/showError', 'Login failed!')
      }
    }
  }
</script>
