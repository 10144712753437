<template>
  <v-snackbar
    v-model="show"
    top
    :color="type"
    class="pa-0"
  >
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        class="min-width-auto"
        @click="removeNotification"
      >
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'Notification',
    data() {
      return {
        unsubscribe: () => {},
        message: null,
        type: 'success',
        show: false,
      };
    },
    mounted() {
      const self = this;
      self.unsubscribe = self.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case 'notifications/SHOW_SUCCESS':
          case 'notifications/SHOW_ERROR':
            self.message = state.notifications.message
            self.show = true
            self.type = state.notifications.type
            break
          case 'notifications/REMOVE_MESSAGE':
            self.show = false
            break
        }
      });
    },
    beforeDestroy() {
      this.unsubscribe()
    },
    methods: {
      ...mapActions('notifications', ['removeNotification']),
    },
  }
</script>
<style scoped>
  .min-width-auto {
    min-width: auto !important;
  }
</style>
