import axios from 'axios'

const state = () => ({
  fileUploading: false,
  lastUploadedFile: null,
})

const getters = {
  isFileUploading: (state) => {
    return state.fileUploading
  },
}

const actions = {
  async uploadFile({commit, dispatch}, {formData}) {
    commit('FILE_UPLOAD_START', formData)
    try {
      await axios.post(`/upload`, formData, {
        'Content-Type': 'multipart/form-data'
      })
      commit('FILE_UPLOADED')
    } catch (err) {
      let label = "File you uploaded has following error(s)."
      let errors = []
      if(err.response.data.errors) {
        errors = err.response.data.errors
      }

      commit('FILE_UPLOAD_FAILED')
      if(errors.length > 0) {
        dispatch('errors/showErrors', { label, errors }, {root: true})
      }
    }
  }
}

const mutations = {
  FILE_UPLOAD_START(state) {
    state.fileUploading = true
  },
  FILE_UPLOADED(state) {
    state.fileUploading = false
  },
  FILE_UPLOAD_FAILED(state) {
    state.fileUploading = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
