<template>
  <div class="text-center">
    <v-dialog
      v-model="sessionExpired"
      width="380"
      persistent
    >
      <v-card>
        <v-card-title class="subtitle-1 blue-grey darken-3 white--text">
          Your session has expired!
        </v-card-title>

        <v-card-text class="mt-3 font-weight-medium pt-2">
          Please log in again to continue.
        </v-card-text>

        <v-card-actions class="d-flex justify-center pb-8">
          <GoogleLogin
            :params="params"
            :render-params="renderParams"
            :on-success="onSuccess"
            :on-failure="onFailure"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import Configuration from "../utils/config";
  import GoogleLogin from 'vue-google-login';
  import {mapGetters} from 'vuex';

  export default {
    components: {
      GoogleLogin
    },
    data() {
      return {
        dialog: true,
        params: {
          client_id: Configuration.value("googleKey"),
          logoutButton: true
        },
        renderParams: {
          width: 250,
          height: 50,
          longtitle: true
        }
      }
    },
    computed: {
      ...mapGetters('auth',[
        'sessionExpired',
      ])
    },
    methods:{
      onSuccess(googleUser) {
        this.$store.dispatch('auth/login', googleUser)
      },
      onFailure() {
        this.$store.dispatch('notifications/showError', 'Login failed!')
      }
    },
  }
</script>
