<template>
  <v-container>
    <div class="vld-parent">
      <loading
        :active.sync="isFileUploading"
        :is-full-page="true"
      />
    </div>
    <v-row class="">
      <v-col
        sm="6"
        md="3"
        lg="3"
        xs="12"
      >
        <v-select
          v-model="currentFC"
          :items="getFulfilmentCentres"
          label="Fulfilment Centre"
          item-text="DisplayName"
          item-value="Code"
          filled
          @change="update"
        />
      </v-col>
      <v-col
        sm="6"
        md="5"
        lg="6"
      >
        <v-text-field
          v-model="searchText"
          placeholder="Search By SKU or Product Name, Press Enter to Search"
          outlined
          @keypress.enter="searchInventory"
        />
      </v-col>
      <v-col>
        <v-btn
          x-large
          depressed
          :loading="loadingInv"
          color="light-blue darken-1"
          class="white--text text--darken-3"
          @click="searchInventory"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        md="3"
      >
        <v-select
          v-model="inventory_type"
          :items="getInventoryTypes"
          label="Inventory Type"
          outlined
          @change="searchInventory"
        />
      </v-col>
    </v-row>
    <v-row class="pt-0 pb-3 pl-3">
      <template>
        <form
          :action="exportUrl"
          method="post"
        >
          <input
            type="hidden"
            name="fileType"
            value="inventory_export"
          >
          <input
            type="hidden"
            name="inventory_filters"
            :value="inventoryFilters"
          >
          <input
            type="hidden"
            name="fulfilment_centre"
            :value="getCurrentFulfilmentCentre"
          >
          <input
            type="hidden"
            name="token"
            :value="getToken"
          >
          <v-btn
            depressed
            large
            type="submit"
            class="mr-3"
            color="grey darken-3 white--text"
          >
            Export View as CSV
          </v-btn>
        </form>
        <v-btn
          depressed
          large
          type="submit"
          class="mr-3"
          color="light-green darken-1 white--text"
          @click="openDialog"
        >
          Update All Selected
        </v-btn>
        <v-btn
          depressed
          large
          type="submit"
          color="light-green darken-1 white--text"
          class="mr-3"
          :loading="isFileUploading"
          @click="chooseFile"
        >
          Bulk Upload
        </v-btn>
        <input
          ref="uploadInventory"
          type="file"
          style="display: none;"
          @change="uploadInventory"
        >
      </template>

      <v-dialog
        v-model="showDialog"
        persistent
        max-width="300"
      >
        <v-card>
          <v-card-title>
            <h3 class="headline">
              Update All Selected:
            </h3>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-container>
              <v-row>
                <v-select
                  v-model="selectedInventoryType"
                  label="Inventory Type"
                  :items="getInventoryTypesForBulk"
                  outlined
                />
              </v-row>
              <v-row>
                <v-text-field
                  v-model="inventory_buffer"
                  label="Inventory Buffer"
                  outlined
                  class="pt-0"
                  :rules="[rules.integer]"
                />
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pt-0 pb-7 pr-6">
            <v-spacer />
            <v-btn
              depressed
              color="grey darken-3 white--text"
              class="pa-3"
              @click="closeDialog"
            >
              Cancel
            </v-btn>
            <v-btn
              depressed
              color="light-green darken-1 white--text"
              class="pa-3"
              :disabled="enabledBulkBtn"
              :loading="bulkUpdating"
              @click="updateSelectedItems"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row class="pt-7">
      <v-col
        sm="6"
        md="5"
        lg="3"
        xs="12"
        class="pb-10 pt-0"
      >
        <v-text-field
          v-model="keyword"
          outlined
          placeholder="Press Enter to search"
          label="Keyword Search"
          hide-details="auto"
          @keypress.enter="addKeyword"
        />
      </v-col>
      <v-col>
        <v-chip
          v-for="(word, index) in keywords"
          :key="`keyword-${index}`"
          class="ma-2"
          close
          color="red"
          label
          outlined
          @click:close="removeKeyword(index)"
        >
          {{ word }}
        </v-chip>
      </v-col>
    </v-row>

    <v-data-table
      v-model="selectedItems"
      show-select
      :headers="getInventoryHeaders"
      :items="getInventory"
      :loading="loadingInv"
      :options.sync="options"
      item-key="sku"
      :server-items-length.sync="getTotalCount"
      :footer-props="footer_props"
    >
      <template v-slot:top="{ pagination, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          :items-per-page-options="pagination_options"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          @update:options="updateOptions"
        />
      </template>

      <template v-slot:[`item.gender`]="{ item }">
        <v-edit-dialog
          large
          :return-value.sync="item.gender"
          @open="openGender(item.gender)"
          @save="saveGender(getInventory.indexOf(item), item.sku, item.gender)"
        >
          <a href="#">{{ item.gender }}</a>
          <template v-slot:input>
            <v-text-field
              v-model="item.gender"
              label="Edit"
              single-line
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        <v-edit-dialog
          large
          :return-value.sync="item.price"
          @open="openPrice(item.price)"
          @save="savePrice(getInventory.indexOf(item), item.sku, item.price)"
        >
          <a href="#">{{ item.price }}</a>
          <template v-slot:input>
            <v-text-field
              v-model="item.price"
              label="Edit"
              single-line
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.buffer`]="{ item }">
        <v-edit-dialog
          large
          :return-value.sync="item.buffer"
          @open="openBuffer(item.buffer)"
          @save="saveBuffer(getInventory.indexOf(item), item.sku, item.buffer)"
        >
          <a href="#">{{ item.buffer }}</a>
          <template v-slot:input>
            <v-text-field
              v-model="item.buffer"
              :rules="[rules.integer]"
              label="Edit"
              single-line
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.available`]="{ item }">
        <v-edit-dialog
          large
          :return-value.sync="item.available"
          @open="openAvailable(item.available)"
          @save="saveAvailable(getInventory.indexOf(item), item.sku, item.available)"
        >
          <a href="#">{{ item.available }}</a>
          <template v-slot:input>
            <v-text-field
              v-model="item.available"
              :rules="[rules.integer]"
              label="Edit"
              single-line
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.threshold`]="{ item }">
        <v-edit-dialog
          large
          :return-value.sync="item.threshold"
          @open="openThreshold(item.threshold)"
          @save="saveThreshold(getInventory.indexOf(item), item.sku, item.threshold)"
        >
          <a href="#">{{ item.threshold }}</a>
          <template v-slot:input>
            <v-text-field
              v-model="item.threshold"
              :rules="[rules.integer]"
              label="Edit"
              single-line
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.hold`]="{ item }">
        <v-edit-dialog
          large
          :return-value.sync="item.hold"
          @open="openHold(item.hold)"
          @save="saveHold(getInventory.indexOf(item), item.sku, item.hold)"
        >
          <a href="#">{{ item.hold }}</a>
          <template v-slot:input>
            <v-text-field
              v-model="item.hold"
              :rules="[rules.integer]"
              label="Edit"
              single-line
              counter
            />
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import Configuration from '../utils/config'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    Loading
  },
  data() {
    return {
      unsubscribe: () => {
      },
      totalCount: 0,
      inventory: [],
      options: {},
      currentFC: '',
      isNaN: v => !isNaN(v),
      currentBuffer: null,
      currentAvailable: null,
      currentThreshold: null,
      currentHold: null,
      gender: '',
      price: 0.00,
      selectedInventoryType: '',
      showDialog: false,
      selectedItems: [],
      searchText: '',
      keyword: '',
      keywords: [],
      inventory_type: 'all',
      inventory_buffer: '',
      rules: {
        integer: value => !isNaN(value) || 'Not a number!',
        string: value => 'M' || 'F',
      },
      bulkUpdating: false,
      pagination_options: [5, 10, 15, 20],
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      }
    }
  },
  computed: {
    inventoryFilters() {
      return JSON.stringify({
        'fulfilment_centre': this.getCurrentFulfilmentCentre,
        'search_text': this.searchText,
        'keywords': this.keywords,
        inventory_type: this.inventory_type
      })
    },
    getSelectedItems() {
      return this.selectedItems
    },
    getShowDialog() {
      return this.showDialog
    },
    getInventoryTypes() {
      return [
        {
          text: 'Managed',
          value: 'Managed',
        },
        {
          text: 'Unmanaged',
          value: 'Unmanaged',
        },
        {
          text: 'All',
          value: 'all',
        },
      ]
    },
    enabledBulkBtn() {
      return this.getSelectedItems.length === 0 || (this.selectedInventoryType === '' ||
          (this.inventory_buffer !== '' && isNaN(this.inventory_buffer)))
    },
    getInventoryTypesForBulk() {
      return this.getInventoryTypes.filter(item => item.text !== 'All')
    },
    ...mapGetters('inventory', [
      'getInventory',
      'getInventoryHeaders',
      'loadingInv',
      'getTotalCount',
      'getCurrentFulfilmentCentre',
    ]),
    ...mapGetters('metas', [
      'getFulfilmentCentres',
    ]),
    ...mapGetters('auth', [
      'getUser',
      'getToken',
    ]),
    ...mapGetters('uploads', [
      'isFileUploading',
    ]),
    exportUrl() {
      return Configuration.value("apiBaseURL") + '/export'
    }
  },
  watch: {
    currentFC: {
      handler() {
        this.fetchInventory()
      },
    },
    options: {
      handler() {
        this.fetchInventory()
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  mounted() {
    this.currentFC = this.getCurrentFulfilmentCentre
    this.$store.dispatch('metas/fetchFulfilmentCentres')
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'inventory/INVENTORY_AVAILABLE_UPDATE_FAILED':
          this.$store.dispatch('notifications/showError', "Failed to update inventory available.")
          break
        case 'inventory/INVENTORY_AVAILABLE_UPDATED':
          this.$store.dispatch('notifications/showSuccess', 'Inventory available updated successfully.')
          this.resetData()
          break
        case 'inventory/INVENTORY_HOLD_UPDATED':
          this.$store.dispatch('notifications/showSuccess', 'Inventory hold updated successfully.')
          this.resetData()
          break
        case 'inventory/INVENTORY_HOLD_UPDATE_FAILED':
          this.$store.dispatch('notifications/showError', "Failed to update inventory hold.")
          break
        case 'inventory/INVENTORY_BUFFER_UPDATED':
          this.$store.dispatch('notifications/showSuccess', 'Inventory buffer updated successfully.')
          this.resetData()
          break
        case 'inventory/INVENTORY_BUFFER_UPDATE_FAILED':
          this.$store.dispatch('notifications/showError', "Failed to update inventory buffer.")
          break
        case 'inventory/INVENTORY_GENDER_UPDATED':
          this.$store.dispatch('notifications/showSuccess', 'Inventory gender updated successfully.')
          this.resetData()
          break  
        case 'inventory/INVENTORY_GENDER_UPDATE_FAILED':
          this.$store.dispatch('notifications/showError', "Failed to update inventory gender.")
          break  
        case 'inventory/INVENTORY_PRICE_UPDATED':
          this.$store.dispatch('notifications/showSuccess', 'Inventory price updated successfully.')
          this.resetData()
          break  
        case 'inventory/INVENTORY_PRICE_UPDATE_FAILED':
          this.$store.dispatch('notifications/showError', "Failed to update inventory price.")
          break
        case 'inventory/INVENTORY_THRESHOLD_UPDATED':
          this.$store.dispatch('notifications/showSuccess', 'Inventory threshold updated successfully.')
          this.resetData()
          break
        case 'inventory/INVENTORY_THRESHOLD_UPDATE_FAILED':
          this.$store.dispatch('notifications/showError', "Failed to update inventory threshold.")
          break
        case 'inventory/BULK_INVENTORY_UPDATED':
          self.$store.dispatch('notifications/showSuccess', 'Inventory uploaded successfully.')
          break
        case 'inventory/BULK_INVENTORY_UPDATE_FAILED':
          self.$store.dispatch('notifications/showError', 'Failed to upload Inventory. Please try again.')
          break
        case 'uploads/FILE_UPLOADED':
          this.$store.dispatch('notifications/showSuccess', 'File successfully processed!')
          break
        case 'uploads/FILE_UPLOAD_FAILED':
          this.$store.dispatch('notifications/showError', 'File upload failed!')
          break
      }
    })
  },
  methods: {
    fetchInventory() {
      let variables = {
        'fulfilment_centre': this.getCurrentFulfilmentCentre,
        'page': this.options.page,
        'per_page': this.options.itemsPerPage,
        'search_text': this.searchText,
        'keywords': this.keywords,
        inventory_type: this.inventory_type
      }
      this.$store.dispatch('inventory/fetchInventory', variables)
    },
    addKeyword(keyword) {
      if (this.keyword.length > 0) {
        this.keywords.push(this.keyword)
        this.keyword = ''
      }
      this.fetchInventory()
    },
    removeKeyword(index) {
      this.keywords.splice(index, 1)
      this.fetchInventory()
    },
    searchInventory() {
      this.options = {page: 1, itemsPerPage: 10}
    },
    update(centre) {
      this.$store.dispatch('inventory/setCurrentFC', centre)
    },
    saveAvailable(item, sku, available) {
      sku = this.getSKUIndx(sku)
      let current_available = this.currentAvailable
      this.$store.dispatch('inventory/updateAvailable', {sku, available, item, current_available})
    },
    saveThreshold(item, sku, threshold) {
      sku = this.getSKUIndx(sku)
      let current_threshold = this.currentThreshold
      this.$store.dispatch('inventory/updateThreshold', {sku, threshold, item, current_threshold})
    },
    saveHold(item, sku, hold) {
      sku = this.getSKUIndx(sku)
      let current_hold = this.currentHold
      this.$store.dispatch('inventory/updateHold', {sku, hold, item, current_hold})
    },
    openBuffer(buffer) {
      this.currentBuffer = buffer
    },
    openGender(gender) {
      this.gender = gender
    },
    openPrice(price) {
      this.price = price
    },
    openAvailable(available) {
      this.currentAvailable = available
    },
    openHold(hold) {
      this.currentHold = hold
    },
    openThreshold(threshold) {
      this.currentThreshold = threshold
    },
    saveBuffer(item, sku, buffer) {
      sku = this.getSKUIndx(sku)
      let current_buffer = this.currentBuffer
      this.$store.dispatch('inventory/updateBuffer', {sku, buffer, item, current_buffer})
    },
    saveGender(item, sku, gender) {
      sku = this.getSKUIndx(sku)
      let current_gender = this.gender
      this.$store.dispatch('inventory/updateGender', {sku, gender, item, current_gender})
    },
    savePrice(item, sku, price) {
      sku = this.getSKUIndx(sku)
      let current_price = this.price
      this.$store.dispatch('inventory/updatePrice', {sku, price, item, current_price})
    },
    getSKUIndx(sku) {
      return sku.toLowerCase() + "-" + this.getCurrentFulfilmentCentre.toLowerCase()
    },
    resetData() {
      this.currentBuffer = null
      this.currentAvailable = null
      this.currentHold = null
      this.currentThreshold = null
    },
    closeDialog() {
      this.selectedInventoryType = ''
      this.inventory_buffer = ''
      this.showDialog = false
      this.selectedItems = []
    },
    openDialog() {
      this.showDialog = true
    },
    updateSelectedItems() {
      if (this.getSelectedItems.length > 0) {
        this.bulkUpdating = true
        let skus = []
        this.getSelectedItems.map(({sku}) => {
          skus.push(sku)
        })
        let variables = {
          'skus': skus,
          'fulfilment_centre': this.getCurrentFulfilmentCentre,
          'inventory_type': this.selectedInventoryType,
          'selected_items': this.getSelectedItems,
        }
        if (this.inventory_buffer !== '') {
          variables.inventory_buffer = this.inventory_buffer
        }

        this.$store.dispatch('inventory/bulkUpdate', variables)
            .then((response) => {
              if (response !== undefined && response.errors === undefined) {
                this.showDialog = false
                this.bulkUpdating = false
                this.selectedInventoryType = ''
                this.selectedItems = []
                this.inventory_buffer = ''
                this.$store.dispatch('notifications/showSuccess', 'SKUs updated successfully.')
              } else {
                console.log(response)
                this.bulkUpdating = false
                this.$store.dispatch('notifications/showError', 'SKUs update failed.')
              }
            })
      } else {
        this.bulkUpdating = false
        this.$store.dispatch('notifications/showError', 'No SKU(s) was selected!')
      }
    },
    chooseFile() {
      this.$refs.uploadInventory.click()
    },
    uploadInventory($event) {
      const files = $event.target.files
      const formData = new FormData()
      formData.append('fulfilment_centre', this.getCurrentFulfilmentCentre)
      for (let index in files) {
        if (Object.prototype.hasOwnProperty.call(files, index)) {
          formData.append('uploadfile', files[index])
        }
      }

      formData.append('fileType', 'inventory_upload')
      this.$store.dispatch('uploads/uploadFile', {formData})

      // Reset the FileList, as otherwise the second and subsequent times
      // we browse, nothing will happen, because nothing changed.
      $event.target.value = "";
    },
  },
}
</script>
