<template>
  <v-container>
    <v-toolbar flat>
      <v-toolbar-title>
        <h3>Upload Documents</h3>
      </v-toolbar-title>
    </v-toolbar>
    <v-divider />

    <!--  SKUBaseDetails Upload Button  -->
    <v-row>
      <v-col>
        <v-card
          outlined
          class="pa-1 text-wrap"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                SKU base details Data Upload
              </v-list-item-title>
              <v-list-item-subtitle>
                Upload file to add or update to SKU base details
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="uploadFile('sku_base_details_data_upload')"
            >
              <v-icon>mdi-upload</v-icon>
              Click to Upload file to update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <input
      ref="uploadFile"
      type="file"
      style="display: none;"
      @change="chooseFile"
    >
    <v-overlay
      :value="isFileUploading"
      :opacity=".7"
      :absolute="true"
    >
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-center text--black headline"
          cols="12"
        >
          Processing file. Please wait
        </v-col>
        <v-col cols="6">
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-col>
      </v-row>
    </v-overlay>
  </v-container>
</template>
<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'MerchandiserUploads',
    data() {
      return {
        currentFileType: "",
        unsubscribe: () => {
        },
      }
    },
    computed: {
      ...mapGetters('uploads', [
        'isFileUploading'
      ]),
    },
    mounted() {
      const self = this

      self.unsubscribe = self.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'uploads/FILE_UPLOADED':
            this.$store.dispatch('notifications/showSuccess', 'File successfully processed!')
            break
          case 'uploads/FILE_UPLOAD_FAILED':
            this.$store.dispatch('notifications/showError', 'File upload failed!')
            break
        }
      })
    },
    destroyed() {
      this.unsubscribe()
    },
    methods: {
      uploadFile(type) {
        this.currentFileType = type
        this.$refs.uploadFile.click()
      },
      chooseFile($event) {
        const files = $event.target.files
        const formData = new FormData()
        for (let index in files) {
          if (Object.prototype.hasOwnProperty.call(files, index)) {
            formData.append('uploadfile', files[index])
          }
        }
        formData.append('fileType', this.currentFileType)
        this.$store.dispatch('uploads/uploadFile', {
          formData,
        })
      },
    },
  }
</script>
