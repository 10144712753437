import axios from 'axios'

const countryCodes = require('i18n-iso-countries')
countryCodes.registerLocale(require("i18n-iso-countries/langs/en.json"));

const countryList = Object.entries(countryCodes.getNames("en"))
const countries = []
countryList.forEach(([key, value]) => {
  countries.push({'country': value, 'code': key})
})

const cities = [
  {
    text: 'Dubai',
    value: 'dubai'
  }
]

const fulfilmentCentres = [
  {
    "Code": "WH_00",
    "CentreName": "Unassigned Fulfilment Centre",
    "DisplayName": "WH_00 - Unassigned Fulfilment"
  },
  {
    "Code": "WH_01",
    "CentreName": "Dubai Fulfilment Centre",
    "DisplayName": "WH_01 - Dubai Fulfilment"
  },
  {
    "Code": "WH_02",
    "CentreName": "Riyadh Fulfilment Centre",
    "DisplayName": "WH_02 - Riyadh Fulfilment"
  },
  {
    "Code": "WH_03",
    "CentreName": "Khobar Fulfilment Centre",
    "DisplayName": "WH_03 - Khobar Fulfilment"
  },
  {
    "Code": "WH_04",
    "CentreName": "Jeddah Fulfilment Centre",
    "DisplayName": "WH_04 - Jeddah Fulfilment"
  },
  {
    "Code": "WH_05",
    "CentreName": "Bahrain Fulfilment Centre",
    "DisplayName": "WH_05 - Bahrain Fulfilment"
  },
  {
    "Code": "WH_06",
    "CentreName": "Kuwait Fulfilment Centre",
    "DisplayName": "WH_06 - Kuwait Fulfilment"
  }
]

const priceRange = Array(5).fill().map((x, i) => i * 100).filter((x) => x > 0)

const sphereRange = []
for (let sphere = -20; sphere <= 20; sphere = sphere + .25) {
  let thisSphere = sphere.toFixed(2).toString()
  if (thisSphere > 0) {
    thisSphere = "+" + thisSphere
  }

  sphereRange.push(thisSphere)
}

const cylinderRange = []
for (let cylinder = -20; cylinder <= 20; cylinder = cylinder + .25) {
  let thisCylinder = cylinder.toFixed(2).toString()
  if (thisCylinder > 0) {
    thisCylinder = "+" + thisCylinder
  }
  cylinderRange.push(thisCylinder)
}

const axisRange = []
for (let axis = 0; axis <= 180; axis++) {
  axisRange.push(axis.toString())
}

const pdRange = []
for (let pd = 30; pd <= 80; pd++) {
  pdRange.push(pd.toString())
}

const coatings = [
  {
    text: "Standard",
    value: 'STD LENS',
  },
  {
    value: 'PR BL LENS',
    text: "Blue Light"
  },
]

const lensTypes = [
  'PRESCRIPTION',
  'READING',
  'NO POWER',
]

const productCategories = [
  {
    "text": "Eyeglass",
    "value": "EG"
  },
  {
    "text": "Sunglass",
    "value": "SG"
  },
  {
    "text": "Reading Glasses",
    "value": "RG"
  },
  {
    "text": "Accessories",
    "value": "ACC"
  },
  {
    "text": "PCL",
    "value": "PCL"
  },
  {
    "text": "Blue Light Glasses",
    "value": "BL"
  },
  {
    "text": "CCL",
    "value": "CCL"
  },
  {
    "text": "Solution",
    "value": "SOL"
  },

]

const holdStatuses = [
  "OOS - Full",
  "OOS - Partial",
  "OOS - Partially Shipped",
  "Hold - Special Order",
  "Hold - Wrong Phone Number/Address",
  "Hold - City Mapping Missing",
  "Hold - Prescription",
  "Hold - Other Verification",
  "Hold - POS Last Piece",
  "Split_WH",
]

const orderStatuses = [
  'Duplicate',
  'In_Stock',
  'Partially_In_Stock',
  'Ordered',
  'OOS',
  'On_Hold',
  'AWB_Generated',
  'Ready_to_Ship',
  'Shipped',
  'RTO',
  'Delivered',
  'CIR',
  'Edited',
  'Cancelled',
  "Special_Order",
  "Exchange",
  "Return",
  "Shipped_to_Store",
  "Ready_to_Collect",
  "Shipped_to_WH_IP",
  "Shipped_to_WH_HD",
  "Received_from_Store",
  "Ready_for_Supplier_Pickup",
  "Shipped_to_Supplier",
  "Received_from_Supplier",
  "Reverse_AWB_Created",
  "Reverse_Shipment_Received",
  "Reverse_QC_Completed",
  "Reverse_QC_Failed",
  "RTO_Refunded",
  "Partially_Delivered",
]

const cancellationReasons = [
  "Customer request - Before Shipment",
  "Customer request - After Shipment",
  "Unreachable",
  "Undeliverable",
  "Items Unavailable",
  "Junk / Spam",
  "Test Order",
  "Unreachable - OOS",
  "Split_Order",
]

const skuStatuses = [
  "In_Stock",
  "Ordered",
  "OOS",
  "PO_Placed",
  "Reqd_Prescription",
  "Special_Item",
  "Exchange_Item",
  "Return_Item",
  "Reqd_Processing",
  "In_Process",
  "Lens_Requested",
  "Lens_Received",
  "Collected",
]

const paymentMethods = [
  "free",
  "paypal_express",
  "cashondelivery",
  "checkout_com_applepay",
  "checkout_com",
  "checkoutcom_apm",
  "tabby_installments",
  "tabby_checkout",
  "retailmultiple",
  "tamara",
]
const shippingCountries = []
const shippingCities = []
let stores = [
  {
    "text": "UAE English",
    "value": "eyewa-ARE-EN",
  },
  {
    "text": "UAE Arabic",
    "value": "eyewa-ARE-AR"
  },
  {
    "text": "KSA English",
    "value": "eyewa-SAU-EN"
  },
  {
    "text": "KSA Arabic",
    "value": "eyewa-SAU-AR"
  },
  {
    "text": "KW English",
    "value": "eyewa-KWT-EN"
  },
  {
    "text": "KW Arabic",
    "value": "eyewa-KWT-AR"
  },
  {
    "text": "USA English",
    "value": "eyewa-USA-EN"
  },
  {
    "text": "Qatar English",
    "value": "eyewa-QAT-EN"
  },
  {
    "text": "Qatar Arabic",
    "value": "eyewa-QAT-AR"
  },
  {
    "text": "Oman English",
    "value": "eyewa-OMN-EN"
  },
  {
    "text": "Oman Arabic",
    "value": "eyewa-OMN-AR"
  },
  {
    "text": "BHD Arabic",
    "value": "eyewa-BHR-AR"
  },
  {
    "text": "BHD English",
    "value": "eyewa-BHR-EN"
  },
]

const posStores = [
  {
    "text": "Saudi Store One (POS_01 Mall of Dhahran, Dhahran)",
    "value": "mall_of_dhahran-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_02 Al Yasmin Mall, Jeddah) ",
    "value": "al_yasmin_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_03 Al Salaam Mall, Jeddah)",
    "value": "al_salaam_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_04 Nakheel Mall, Riyadh)",
    "value": "nakheel_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_05 Hayat Mall, Riyadh)",
    "value": "hayat_mall-SAU-AR"
  },
  {
    "text": "UAE Store One (POS_06 Mirdiff City Center, Dubai)",
    "value": "mirdiff_city_center-ARE-AR"
  },
  {
    "text": "Saudi Store One (POS_07 Noor Mall, Madinah)",
    "value": "noor_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_08 Nakheel Dammam, Dammam)",
    "value": "nakheel_dammam-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_09 Mall of Arabia, Jeddah)",
    "value": "mall_of_arabia-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_10 Nakheel Plaza, Buraida)",
    "value": "nakheel_plaza-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_11 Buraida Othaim Mall, Buraida)",
    "value": "buraida_othaim_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_12 Ahsa Othaim Mall, Ahsa)",
    "value": "ahsa_othaim_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_13 Hail Grand Mall, Hail)",
    "value": "hail_grand_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_14 Ahsa Mall, Ahsa)",
    "value": "ahsa_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_15 Mekkah Mall, Mekkah)",
    "value": "mekkah_mall-SAU-AR"
  },
  {
    "text": "UAE Store One (POS_16 Yas Mall, Abu Dhabi)",
    "value": "yas_mall-ARE-AR"
  },
  {
    "text": "Saudi Store One (POS_17 Juri Mall, Taif)",
    "value": "juri_mall-SAU-AR"
  },
  {
    "text": "UAE Store One (POS_18 Ibn Battuta, Dubai)",
    "value": "ibn_battuta-ARE-AR"
  },
  {
    "text": "Saudi Store One (POS_19 Al Andalous Mall, Mekkah)",
    "value": "al_andalous_mall-SAU-AR"
  },
  {
    "text": "UAE Store One (POS_20 Abu Dhabi Mall, Abu Dhabi)",
    "value": "abu_dhabi_mall-ARE-AR"
  },
  {
    "text": "UAE Store One (POS_21 Dalma Mall, Abu Dhabi)",
    "value": "dalma_mall-ARE-AR"
  },
  {
    "text": "UAE Store One (POS_22 Bawabat Al Sharq, Abu Dhabi)",
    "value": "bawabat_al_sharq-ARE-AR"
  },
  {
    "text": "UAE Store One (POS_23 Al Wahda Mall, Abu Dhabi)",
    "value": "al_wahda_mall-ARE-AR"
  },
  {
    "text": "UAE Store One (POS_24 Al Zahia Mall, Sharjah)",
    "value": "al_zahia_mall-ARE-AR"
  },
  {
    "text": "UAE Store One (POS_25 Dubai Festival City, Dubai)",
    "value": "dubai_festival_city-ARE-AR"
  },
  {
    "text": "Saudi Store One (POS_26 Rabwa Othaim, Riyadh)",
    "value": "rabwa_othaim-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_27 Dammam Othaim Mall, Dammam)",
    "value": "dammam_othaim_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_28 Riyadh Park, Riyadh)",
    "value": "riyadh_park-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_29 Panorama Mall, Riyadh)",
    "value": "panorama_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_30 Aziz Mall, Jeddah)",
    "value": "aziz_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_31 Al Makan Mall, Riyadh)",
    "value": "al_makan_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_32 Galleria Mall, Jubail)",
    "value": "galleria_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_33 Jubail Mall, Jubail)",
    "value": "jubail_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_34 Dana Mall, Yanbu)",
    "value": "dana_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_35 Milago Park, Tabuk)",
    "value": "milago_park-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_36 Tabuk Park, Tabuk)",
    "value": "tabuk_park-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_37 Al Salam Mall, Riyadh)",
    "value": "al_salam_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_38 King Abdulaziz St, Riyadh)",
    "value": "king_abdullah_st-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_39 King Abdullah St, Riyadh)",
    "value": "king_abdulaziz_st-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_40 Al Aziziyyah St, Makkah)",
    "value": "al_aziziyyah_st-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_41 Al Sharaie Mall, Makkah)",
    "value": "al_sharaie_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_42 Mall of Dhahran New, Dhahran)",
    "value": "mall_of_dhahran_new-SAU-AR"
  },
  {
    "text": "BHD Store One (POS_43 - Bahrain City Center, Bahrain)",
    "value": "bahrain_city_center-BDH-AR"
  },
  {
    "text": "KWD Store One (POS_44 - Avenues Mall, Kuwait)",
    "value": "avenues_mall-KW-AR"
  },
  {
    "text": "UAE Store One (POS_45 - Mall of Emirates, Dubai)",
    "value": "mall_of_emirates-ARE-AR"
  },
  {
    "text": "Saudi Store One (POS_46 - Dareen Mall, Dammam)",
    "value": "dareen_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_47 - Azzan Plaza, Sabya)",
    "value": "azzan_plaza-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_48 - City Plaza, Abu Arish)",
    "value": "city_plaza-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_49 - Obhur Plaza, Jeddah)",
    "value": "obhur_plaza-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_50 - The Park, Taif)",
    "value": "the_park-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_51 - Kadi Mall, Jazan)",
    "value": "kadi_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_52 - Abha Rashid mall, Abha)",
    "value": "abha_rashid_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_53 - Tala Mall, Riyadh)",
    "value": "tala_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_54 - Ahsa Mall New, Ahsa)",
    "value": "ahsa_mall_new-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_55 - Jarir Althomama, Riyadh)",
    "value": "jarir_althomama-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_56 - Park Avenue, Riyadh)",
    "value": "park_avenue-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_57 - Anas Ibn Malik, Riyadh)",
    "value": "anas_ibn_malik-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_58 - Al Nokhba Center, Riyadh)",
    "value": "al_nokhba_center-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_59 - Aliat Medina, Medina)",
    "value": "aliat_medina-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_60 - Rashid Mall, Medina)",
    "value": "rashid_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_61 - Diyafa Mall, Makkah)",
    "value": "diyafa_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_62 - City Mall, Al Qatif)",
    "value": "city_mall-SAU-AR"
  },
  {
    "text": "Bahrain Store One (POS_63 - Seef Mall, Bahrain)",
    "value": "seef_mall-BHD-AR"
  },
  {
    "text": "Saudi Store One (POS_64 - Mojan Park, Khamis)",
    "value": "mojan_park-SAU-AR"
  },
  {
    "text": "Bahrain Store One (POS_65 - Enma Mall, Bahrain)",
    "value": "enma_mall-BHD-AR"
  },
  {
    "text": "Saudi Store One (POS_66 - Dhahrat Laban, Riyadh)",
    "value": "dhahrat_laban-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_67 - UWalk, Jeddah)",
    "value": "uwalk-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_68 - Flamingo Mall, Khobar)",
    "value": "flamingo_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_69 - Al Kharj, Kharj)",
    "value": "al_kharj-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_70 - The Village, Jeddah)",
    "value": "the_village-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_71 - Hasan Bil Ali St, Riyadh)",
    "value": "hasan_bil_ali_st-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_72 - Ravala Plaza, Abha)",
    "value": "ravala_plaza-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_73 - Sultana St, Medina)",
    "value": "sultana_st-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_74 - King Khaled Airport, Riyadh)",
    "value": "king_khaled_airport-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_75 - Riyadh Gallery, Riyadh)",
    "value": "riyadh_gallery-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_76 - Al Qaser Mall, Riyadh)",
    "value": "al_qaser_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_77 - Al Alia Plaza, Riyadh)",
    "value": "al_alia_plaza-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_78 - Damman Road, Damman)",
    "value": "damman_road-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_79 - King AbdAlaziz Hafar, Hafar AlBatin)",
    "value": "king_abdalaziz_hafar-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_80 - Borj AlObikan, Taif)",
    "value": "borj_alobikan-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_81 - Najran Park, Najran)",
    "value": "najran_park-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_82 - Al Sadhan Road, Riyadh)",
    "value": "al_sadhan_road-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_83 - Al Quds St, Al Qatif)",
    "value": "al_quds_st-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_84 - The Zone, Riyadh)",
    "value": "the_zone-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_85 - Amwaj Mall, Khobar)",
    "value": "amwaj_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_86 - Marina Mall, Jubail)",
    "value": "marina_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_87 - Jeddah Park, Jeddah)",
    "value": "jeddah_park-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_88 - Red Sea Mall, Jeddah)",
    "value": "red_sea_mall-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_89 - King Abdullaziz Arar, Arar)",
    "value": "king_abdullaziz_arar-SAU-AR"
  },
  {
    "text": "Saudi Store One (POS_90 - King Abdullaziz Skaka, Skaka)",
    "value": "king_abdullaziz_skaka-SAU-AR"
  },
  {
    "text": "UAE Store One (POS_91 - Manar Mall, Ras Al Khaimah)",
    "value": "manar_mall-ARE-AR"
  },
  {
    "text": "UAE Store One (POP_01 PopUp Mall of the Emirates, Dubai)",

    "value": "popup_mall_of_the_emirates-ARE-AR",
  },
  {
    "text": "UAE Store One (POP_02 PopUp Mirdif City Center, Dubai)",

    "value": "popup_mirdif_city_center-ARE-AR",
  },
  {
    "text": "UAE Store One (POP_03 PopUp Zahia City Center, Sharjah)",

    "value": "popup_zahia_city_center-ARE-AR",
  },
  {
    "text": "UAE Store One (POP_04 PopUp Yas Mall, Abu Dhabi)",

    "value": "popup_yas_mall-ARE-AR",
  },
  {
    "text": "UAE Store One (POP_05 PopUp Bawabat Al Sharq, Abu Dhabi)",

    "value": "popup_bawabat_al_sharq-ARE-AR",
  },
  {
    "text": "Saudi Store One (POP_06 PopUp Nakheel Riyadh, Riyadh)",

    "value": "popup_nakheel_riyadh-SAU-AR",
  },
  {
    "text": "Saudi Store One (POP_07 PopUp Nakheel Damman, Dammam)",

    "value": "popup_nakheel_damman-SAU-AR",
  },
  {
    "text": "Saudi Store One (POP_08 PopUp Dhahran Mall, Dammam)",
    "value": "popup_dhahran_mall-SAU-AR",
  },
  {
    "text": "Saudi Store One (POP_09 PopUp Mall of Arabia, Jeddah)",
    "value": "popup_mall_of_arabia-SAU-AR",
  },
  {
    "text": "Saudi Store One (POP_10 PopUp Makkah Mall, Makkah)",
    "value": "popup_makkah_mall-SAU-AR",
  },
  {
    "text": "Saudi Store One (POP_11 PopUp Yasmin Mall, Jeddah)",
    "value": "popup_yasmin_mall-SAU-AR",
  },
  {
    "text": "Saudi Store One (POP_12 PopUp Noor Mall, Medina)",
    "value": "popup_noor_mall-SAU-AR",
  },
  {
    "text": "Saudi Store One (POP_13 PopUp Grenada Mall, Riyadh)",
    "value": "popup_grenada_mall-SAU-AR",
  },
  {
    "text": "Saudi Store One (POP_14 PopUp Panorama Mall, Riyadh)",
    "value": "popup_panorama_mall-SAU-AR",
  },
  {
    "text": "Saudi Store One (POP_15 PopUp Riyadh Park, Riyadh)",
    "value": "popup_riyadh_park-SAU-AR",
  },
  {
    "text": "Saudi Store One (POP_16 PopUp Red Sea Mall, Jeddah)",
    "value": "popup_red_Sea_mall-SAU-AR",
  },
  {
    "text": "Saudi Store One (POP_17 - PopUp Nakheel Mall, Riyadh)",
    "value": "popup_nakheel_mall-SAU-AR",
  },
  {
    "text": "Saudi Store One (POP_18 - PopUp Mall of Dhahran, Dhahran)",
    "value": "popup_mall_of_dhahran-SAU-AR",
  },
  {
    "text": "Saudi Store One (POP_19 - PopUp Nakheel Dammam, Dammam)",
    "value": "popup_nakheel_dammam-SAU-AR",
  },
  {
    "text": "Saudi Store One (POP_20 - PopUp Mekkah Mall, Mekkah)",
    "value": "popup_mekkah_mall-SAU-AR",
  },
  {
    "text": "Saudi Store One (POP_21 - PopUp Mall of Arabia, Jeddah)",
    "value": "popup_mall_of_arabia-SAU-AR",
  },
]

stores = stores.concat(posStores)

const magentoStatuses = [
  "Synced",
  "Yet to be Synced",
  "Not to be Synced",
]

const shippingTypes = [
  'FODEL',
  'HOME DELIVERY',
  'STORE_DELIVERY',
  'IN_STORE_PICKUP',
  'IN_STORE_COMPLETE'
]

const prescriptionTypes = [
  'Image_Only',
  'Text_Only',
  'Both',
]

const couriers = [];
const posStoresFromAPI = [];
const orderTypes = [];

const state = () => ({
  cities,
  fulfilmentCentres,
  priceRange,
  orderStatuses,
  countries,
  productCategories,
  holdStatuses,
  cancellationReasons,
  skuStatuses,
  paymentMethods,
  shippingCountries,
  shippingCities,
  stores,
  magentoStatuses,
  shippingTypes,
  defaultFulfilmentCentre: ['WH_01'],
  currentFulfilmentCentre: [],
  sphereRange,
  cylinderRange,
  pdRange,
  axisRange,
  couriers,
  orderTypes,
  posStoresFromAPI,
})

const getters = {
  getCities: (state) => {
    return state.cities
  },
  getFulfilmentCentres: (state, getters) => {
    const fulfilmentCentres = []
    const stores = []
    state.fulfilmentCentres.filter(fc => fc.Status === 'Active')
      .forEach(fc => {
        if (fc.Code.includes('_')) {
          const [_, order] = fc.Code.split('_')
          fc.order = parseInt(order, 10)

          if (fc.Code.startsWith('WH_')) {
            fulfilmentCentres.push(fc)
            return
          }
          stores.push(fc)
        }
      })

    let sortedFulfilmentCentres = fulfilmentCentres.sort((a, b) => a.order - b.order)
    const sortedStores = stores.sort((a, b) => a.order - b.order)
    sortedFulfilmentCentres = sortedFulfilmentCentres.concat(sortedStores)

    return sortedFulfilmentCentres.map(fc => {
      fc.DisplayName = `${fc.Code} - ${fc.CentreName}`
      return fc
    })
  },
  getPriceRange: (state) => {
    return state.priceRange
  },
  getOrderStatuses: (state) => {
    return state.orderStatuses
  },
  getCountries: (state) => {
    return state.countries
  },
  getProductCategories: (state) => {
    return state.productCategories
  },
  getHoldStatuses: (state) => {
    return state.holdStatuses
  },
  getCancellationReasons: (state) => {
    return state.cancellationReasons
  },
  getStores: (state) => {
    return state.stores
  },
  getSkuStatuses: (state) => {
    return state.skuStatuses
  },
  getPaymentMethods: (state) => {
    return state.paymentMethods
  },
  getMagentoStatuses: (state) => {
    return state.magentoStatuses
  },
  getShippingTypes: (state) => {
    return state.shippingTypes
  },
  getSphereRange: (state) => {
    return state.sphereRange
  },
  getCylinderRange: (state) => {
    return state.cylinderRange
  },
  getPdRange: (state) => {
    return state.pdRange
  },
  getAxisRange: (state) => {
    return state.axisRange
  },
  getBooleanTypes: (state) => {
    return ['Yes', 'No', 'None']
  },
  getLensTypes: (state) => {
    return lensTypes
  },
  getCoatings: (state) => {
    return coatings
  },
  getPOSStores: (state) => {
    return posStores
  },
  getPrescriptionTypes: (state) => {
    return prescriptionTypes
  },
  getCouriers: (state) => {
    return state.couriers
  },
  getDefaultFulfilmentCentre: (state) => {
    return state.defaultFulfilmentCentre;
  },
  getPOSStoresFromAPI: (state) => {
    return state.posStoresFromAPI
  },
  getOrderTypes: (state) => {
    return state.orderTypes
  },
}

const actions = {
  setCurrentFulfilmentCentre: ({commit, rootGetters}, fulfilment_centre) => {
    const currentFilter = rootGetters['filters/getCurrentFilter']
    localStorage.removeItem('current_filter')
    localStorage.setItem('current_filter', JSON.stringify(currentFilter))
    commit('CURRENT_FULFILMENT_CENTRE', fulfilment_centre)
  },
  async fetchCities({commit}) {
    const response = await axios.get('/cities')
    commit('CITIES_FETCHED', response.data)
  },
  async fetchFulfilmentCentres({commit}) {
    const response = await axios.get('/fulfilment-centres')
    commit('FULFILMENT_CENTRES_FETCHED', response.data)
  },
  async fetchCouriers({commit}) {
    const response = await axios.get('/couriers')
    commit('COURIERS_FETCHED', response.data)
  },
  async fetchPosStores({commit}) {
    const response = await axios.get('/pos-stores')
    commit('POS_STORES_FETCHED', response.data)
  },
  async fetchOrderTypes({commit}) {
    const response = await axios.get('/order-types')
    commit('ORDER_TYPES_FETCHED', response.data)
  },
}

const mutations = {
  CURRENT_FULFILMENT_CENTRE(state, centre) {
    state.currentFulfilmentCentre = centre
  },
  CITIES_FETCHED(state, cities) {
    state.cities = cities
  },
  FULFILMENT_CENTRES_FETCHED(state, fulfilmentCentres) {
    state.fulfilmentCentres = fulfilmentCentres
  },
  COURIERS_FETCHED(state, couriers) {
    state.couriers = couriers.map(x => x["name"]).sort()
  },
  POS_STORES_FETCHED(state, stores) {
    state.posStoresFromAPI = Object.values(stores)
  },
  ORDER_TYPES_FETCHED(state, res) {
    state.orderTypes = res
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
