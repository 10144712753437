import {createProvider} from "../../vue-apollo"

let currentFulfilmentCentre = "WH_01";

if (localStorage.getItem('inventory_current_fc')) {
  currentFulfilmentCentre = localStorage.getItem('inventory_current_fc')
} else {
  localStorage.setItem('inventory_current_fc', currentFulfilmentCentre)
}

const tableInventoryHeaders = [
  {
    text: 'SKU',
    value: 'sku',
  },
  {
    text: 'Product Name',
    value: 'productname',
  },
  {
    text: 'Brand',
    value: 'brand',
  },
  {
    text: 'Category',
    value: 'category',
  },
  {
    text: 'Gender',
    value: 'gender',
  },
  {
    text: 'Price',
    value: 'price',
  },
  {
    text: 'Inv. Type',
    value: 'type',
  },
  {
    text: 'Inventory Buffer',
    value: 'buffer',
  },
  {
    text: 'Available Inventory',
    value: 'available',
  },
  {
    text: 'Inventory Threshold',
    value: 'threshold',
  },
  {
    text: 'Inventory Hold',
    value: 'hold',
  },
  {
    text: 'Fulfillable Inventory',
    value: 'fulfillable',
  },
  {
    text: 'Total Inventory in Hand',
    value: 'totalinhand',
  },
]

const tableInventoryJITHeaders = [
  {
    text: 'SKU',
    value: 'sku',
  },
  {
    text: 'Product Name',
    value: 'productname',
  },
  {
    text: 'Brand',
    value: 'brand',
  },
  {
    text: 'Category',
    value: 'category',
  },
  // Hiding for now until magento integration
  // {
  //   text: 'Magento Status',
  //   value: 'available',
  // },
  {
    text: 'Inventory JIT Cap',
    value: 'jitcap',
  },
  {
    text: 'Vendor',
    value: 'vendor',
  },
]

const apolloProvider = createProvider()

const state = () => ({
  inventory: [],
  meta: {
    totalCount: 0,
    page: 1,
    perPage: 10,
  },
  inventoryJIT: [],
  jit_meta: {
    totalCount: 0,
    page: 1,
    perPage: 10,
  },
  tableInventoryHeaders,
  tableInventoryJITHeaders,
  loadingInventory: false,
  loadingInventoryJIT: false,
  inventoryTypeUpdated: false,
  inventoryHoldUpdated: false,
  inventoryAvailableUpdated: false,
  inventoryBufferUpdated: false,
  inventoryGenderUpdated: false,
  inventoryPriceUpdated: false,
  jitVendorUpdated: false,
  jitCapUpdated: false,
  bulkInventoryUpdated: false,
  bulkInventoryJITUpdated: false,
  inventoryThresholdUpdated: false,
  currentFulfilmentCentre
})

const getters = {
  getInventory: (state) => {
    return state.inventory
  },
  getInventoryJIT: (state) => {
    return state.inventoryJIT
  },
  getInventoryHeaders: (state) => {
    return state.tableInventoryHeaders
  },
  getInventoryJITHeaders: (state) => {
    return state.tableInventoryJITHeaders
  },
  loadingInv: (state) => {
    return state.loadingInventory
  },
  loadingInvJIT: (state) => {
    return state.loadingInventoryJIT
  },
  getTotalCount: (state) => {
    return state.meta.totalCount
  },
  getJITTotalCount: (state) => {
    return state.jit_meta.totalCount
  },
  getCurrentFulfilmentCentre: (state) => {
    return state.currentFulfilmentCentre
  },
  getInventoryAvailableUpdated: (state) => {
    return state.inventoryHoldUpdated
  }
}

const actions = {
  async fetchInventory({ commit }, variables) {
    commit('INVENTORY_REQUESTED')
    apolloProvider.defaultClient.query({
      query: require("@/graphql/inventory/FetchInventory.gql"),
      variables,
      fetchPolicy: 'no-cache',
    }).then((res) => {
      commit('INVENTORY_FETCHED', { 'data': res.data.inventory.data, 'meta': res.data.inventory.meta })
    }).catch((err) => {
      console.log(err)
    })
  },
  async fetchInventoryJIT({ commit }, variables) {
    commit('INVENTORY_JIT_REQUESTED')
    apolloProvider.defaultClient.query({
      query: require("@/graphql/inventory/FetchInventoryJIT.gql"),
      variables,
      fetchPolicy: 'no-cache',
    }).then((res) => {
      commit('INVENTORY_JIT_FETCHED', { 'data': res.data.inventoryJIT.data, 'meta': res.data.inventoryJIT.meta })
    }).catch((err) => {
      console.log(err)
    })
  },
  async updateBuffer({ commit }, variables) {
    return apolloProvider.defaultClient.query({
      query: require("@/graphql/inventory/UpdateInventoryBuffer.gql"),
      variables,
      fetchPolicy: 'no-cache',
    }).then((res) => {
      this.state.inventory.inventory[variables.item].buffer = res.data.updateInventoryBuffer.buffer
      this.state.inventory.inventory[variables.item].available = res.data.updateInventoryBuffer.available
      this.state.inventory.inventory[variables.item].fulfillable = res.data.updateInventoryBuffer.fulfillable
      this.state.inventory.inventory[variables.item].hold = res.data.updateInventoryBuffer.hold
      this.state.inventory.inventory[variables.item].totalinhand = res.data.updateInventoryBuffer.totalinhand
      commit('INVENTORY_BUFFER_UPDATED')
      return res
    }).catch((err) => {
      console.log(err)
      this.state.inventory.inventory[variables.item].buffer = variables.current_buffer
      commit('INVENTORY_BUFFER_UPDATE_FAILED')
    })
  },
  async updateAvailable({ commit }, variables) {
    return apolloProvider.defaultClient.query({
      query: require("@/graphql/inventory/UpdateInventoryAvailable.gql"),
      variables,
      fetchPolicy: 'no-cache',
    }).then((res) => {
      this.state.inventory.inventory[variables.item].buffer = res.data.updateInventoryAvailable.buffer
      this.state.inventory.inventory[variables.item].available = res.data.updateInventoryAvailable.available
      this.state.inventory.inventory[variables.item].fulfillable = res.data.updateInventoryAvailable.fulfillable
      this.state.inventory.inventory[variables.item].hold = res.data.updateInventoryAvailable.hold
      this.state.inventory.inventory[variables.item].totalinhand = res.data.updateInventoryAvailable.totalinhand
      commit('INVENTORY_AVAILABLE_UPDATED')
      return res
    }).catch((err) => {
      console.log(err)
      this.state.inventory.inventory[variables.item].available = variables.current_available
      commit('INVENTORY_AVAILABLE_UPDATE_FAILED')
    })
  },
  async updateThreshold({ commit }, variables) {
    return apolloProvider.defaultClient.query({
      query: require("@/graphql/inventory/UpdateInventoryThreshold.gql"),
      variables,
      fetchPolicy: 'no-cache',
    }).then((res) => {
      console.log(res);
      this.state.inventory.inventory[variables.item].threshold = res.data.updateInventoryThreshold.threshold
      commit('INVENTORY_THRESHOLD_UPDATED')
      return res
    }).catch((err) => {
      console.log(err)
      this.state.inventory.inventory[variables.item].threshold = variables.threshold
      commit('INVENTORY_THRESHOLD_UPDATE_FAILED')
    })
  },
  async updateGender({ commit }, variables) {
    return apolloProvider.defaultClient.query({
      query: require("@/graphql/inventory/UpdateGender.gql"),
      variables,
      fetchPolicy: 'no-cache',
    }).then((res) => {
      this.state.inventory.inventory[variables.item].gender = res.data.updateGender.gender
      commit('INVENTORY_GENDER_UPDATED')
      return res
    }).catch((err) => {
      console.log(err)
      this.state.inventory.inventory[variables.item].gender = variables.gender
      commit('INVENTORY_GENDER_UPDATE_FAILED')
    })
  },
  async updatePrice({ commit }, variables) {
    return apolloProvider.defaultClient.query({
      query: require("@/graphql/inventory/UpdatePrice.gql"),
      variables,
      fetchPolicy: 'no-cache',
    }).then((res) => {
      this.state.inventory.inventory[variables.item].price = res.data.updatePrice.price
      commit('INVENTORY_PRICE_UPDATED')
      return res
    }).catch((err) => {
      console.log(err)
      this.state.inventory.inventory[variables.item].price = variables.price
      commit('INVENTORY_PRICE_UPDATE_FAILED')
    })
  },
  async updateHold({ commit }, variables) {
    return apolloProvider.defaultClient.query({
      query: require("@/graphql/inventory/UpdateInventoryHold.gql"),
      variables,
      fetchPolicy: 'no-cache',
    }).then((res) => {
      this.state.inventory.inventory[variables.item].buffer = res.data.updateInventoryHold.buffer
      this.state.inventory.inventory[variables.item].available = res.data.updateInventoryHold.available
      this.state.inventory.inventory[variables.item].fulfillable = res.data.updateInventoryHold.fulfillable
      this.state.inventory.inventory[variables.item].hold = res.data.updateInventoryHold.hold
      this.state.inventory.inventory[variables.item].totalinhand = res.data.updateInventoryHold.totalinhand
      commit('INVENTORY_HOLD_UPDATED')
      return res
    }).catch((err) => {
      console.log(err)
      this.state.inventory.inventory[variables.item].hold = variables.current_hold
      commit('INVENTORY_HOLD_UPDATE_FAILED')
    })
  },
  async updateJITVendor({ commit }, variables) {
    return apolloProvider.defaultClient.query({
      query: require("@/graphql/inventory/UpdateJITVendor.gql"),
      variables,
      fetchPolicy: 'no-cache',
    }).then((res) => {
      this.state.inventory.inventoryJIT[variables.item].vendor = res.data.updateJITVendor.vendor
      commit('JIT_VENDOR_UPDATED', true)
      return res
    }).catch((err) => {
      console.log(err)
      this.state.inventory.inventoryJIT[variables.item].vendor = variables.current_vendor
      commit('JIT_VENDOR_UPDATED', false)
    })
  },
  async updateJITCap({ commit }, variables) {
    return apolloProvider.defaultClient.query({
      query: require("@/graphql/inventory/UpdateJITCap.gql"),
      variables,
      fetchPolicy: 'no-cache',
    }).then((res) => {
      this.state.inventory.inventoryJIT[variables.item].jitcap = res.data.updateJITCap.jitcap
      commit('JIT_CAP_UPDATED', true)
      return res
    }).catch((err) => {
      console.log(err)
      this.state.inventory.inventoryJIT[variables.item].jitcap = variables.current_jit_cap
      commit('JIT_CAP_UPDATED', false)
    })
  },
  async bulkUpdate({ commit }, variables) {
    return apolloProvider.defaultClient.query({
      query: require("@/graphql/inventory/BulkUpdateInventory.gql"),
      variables,
      fetchPolicy: 'no-cache',
    }).then((res) => {
      variables.selected_items.forEach(sku => {
        sku.type = variables.inventory_type
        if (variables.inventory_buffer !== undefined) {
          sku.buffer = variables.inventory_buffer
          sku.fulfillable = sku.available - sku.buffer
        }
      })
      commit('BULK_INVENTORY_UPDATED')
      return res
    }).catch((err) => {
      commit('BULK_INVENTORY_UPDATE_FAILED')
      console.log(err)
    })
  },
  async bulkUpdateJIT({ commit }, variables) {
    return apolloProvider.defaultClient.query({
      query: require("@/graphql/inventory/BulkUpdateJIT.gql"),
      variables,
      fetchPolicy: 'no-cache',
    }).then((res) => {
      variables.selected_items.forEach(sku => {
        if (variables.jit_cap !== undefined) {
          sku.jitcap = variables.jit_cap
        }
        if (variables.vendor !== undefined) {
          sku.vendor = variables.vendor
        }
      })
      commit('BULK_INVENTORY_JIT_UPDATED')
      return res
    }).catch((err) => {
      commit('BULK_INVENTORY_JIT_UPDATE_FAILED')
      console.log(err)
    })
  },
  setCurrentFC({ commit }, fc) {
    localStorage.setItem('inventory_current_fc', fc)
    commit('CURRENT_FC_SET', fc)
  }
}

const mutations = {
  INVENTORY_REQUESTED(state) {
    state.loadingInventory = true
    state.meta = {
      totalCount: 0,
      page: 1,
      perPage: 10,
    }
  },
  INVENTORY_FETCHED(state, { data, meta }) {
    state.inventory = data
    state.meta = meta
    state.loadingInventory = false
  },
  INVENTORY_JIT_REQUESTED(state) {
    state.loadingInventoryJIT = true
    state.jit_meta = {
      totalCount: 0,
      page: 1,
      perPage: 10,
    }
  },
  INVENTORY_JIT_FETCHED(state, { data, meta }) {
    state.inventoryJIT = data
    state.jit_meta = meta
    state.loadingInventoryJIT = false
  },
  INVENTORY_TYPE_UPDATED(state, flag) {
    state.inventoryTypeUpdated = flag
  },
  INVENTORY_HOLD_UPDATED(state) {
    state.inventoryHoldUpdated = true
  },
  INVENTORY_GENDER_UPDATED(state) {
    state.inventoryGenderUpdated = true
  },
  INVENTORY_GENDER_UPDATE_FAILED(state) {
    state.inventoryGenderUpdated = false
  },
  INVENTORY_PRICE_UPDATED(state) {
    state.inventoryPriceUpdated = true
  },
  INVENTORY_PRICE_UPDATE_FAILED(state) {
    state.inventoryPriceUpdated = false
  },
  INVENTORY_HOLD_UPDATE_FAILED(state) {
    state.inventoryHoldUpdated = false
  },
  INVENTORY_BUFFER_UPDATED(state) {
    state.inventoryBufferUpdated = true
  },
  INVENTORY_BUFFER_UPDATE_FAILED(state) {
    state.inventoryBufferUpdated = false
  },
  INVENTORY_AVAILABLE_UPDATED(state) {
    state.inventoryAvailableUpdated = true
  },
  INVENTORY_AVAILABLE_UPDATE_FAILED(state) {
    state.inventoryAvailableUpdated = false
  },
  INVENTORY_THRESHOLD_UPDATED(state) {
    state.inventoryThresholdUpdated = true
  },
  INVENTORY_THRESHOLD_UPDATE_FAILED(state) {
    state.inventoryThresholdUpdated = false
  },
  JIT_VENDOR_UPDATED(state, flag) {
    state.jitVendorUpdated = flag
  },
  JIT_CAP_UPDATED(state, flag) {
    state.jitCapUpdated = flag
  },
  BULK_INVENTORY_UPDATED(state, flag) {
    state.bulkInventoryUpdated = flag
  },
  BULK_INVENTORY_JIT_UPDATED(state, flag) {
    state.bulkInventoryJITUpdated = flag
  },
  CURRENT_FC_SET(state, fc) {
    state.currentFulfilmentCentre = fc
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
