const state = () => ({
  errors: [],
  label: ''
})

const getters = {
  getLabel: (state) => {
    return state.label
  },
  getErrors: (state) => {
    return state.errors
  },
}

const actions = {
  removeErrors({ commit }) {
    commit('REMOVE_ERRORS')
  },
  showErrors({commit},{errors, label}) {
    commit('SHOW_ERRORS',{errors, label})
  }
}

const mutations = {
  SHOW_ERRORS(state, {errors, label}) {
    state.errors = errors
    state.label = label
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  },
  REMOVE_ERRORS(state) {
    state.errors = []
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
