<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="currentDate"
        outlined
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="currentDate"
      @input="dateSelected"
    />
  </v-menu>
</template>
<script>
  import dayjs from 'dayjs'

  export default {
    name: 'DatePicker',
    props: {
      label: {
        type : String,
        default: 'Select Date'
      },
      value: {
        type : String,
        default: ''
      }
    },
    data() {
      return {
        menu : false,
        currentDate: this.value ? dayjs(this.value).format('YYYY-MM-DD') : '',
      }
    },
    watch: {
      value() {
        this.currentDate = this.value ? dayjs(this.value).format('YYYY-MM-DD') : ''
      },

    },
    methods: {
      dateSelected() {
        this.menu = false
        this.$emit('input', this.currentDate)
      }
    }
  }
</script>
