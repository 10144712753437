<template>
  <v-container>
    <v-alert
      text
      outlined
      color="red darken-1"
      class="red--text darken-2"
      icon="mdi-alert"
      :value="!!getErrors.length"
      transition="fade-transition"
    >
      <v-row>
        <v-col cols="11">
          <h3>{{ getLabel }}</h3>
          <ol>
            <li
              v-for="(error, index) in visibleErrors"
              :key="`multi-error-${index}`"
            >
              {{ error }}
            </li>
          </ol>
        </v-col>
        <v-col cols="1">
          <v-icon
            color="deep-orange"
            medium
            @click="removeErrors"
          >
            mdi-close-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>
<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    computed: {
      ...mapGetters('errors', [
        'getErrors',
        'getLabel',
      ]),
      visibleErrors() {
        return this.getErrors
      }
    },
    methods: {
      ...mapActions('errors',[
        'removeErrors',
      ])
    },
  }
</script>
