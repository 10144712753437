<template>
  <div>
    <v-menu
      v-model="menu1"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          slot="activator"
          v-model="dateFormatted"
          :label="label"
          v-bind="attrs"
          v-on="on"
          @blur="date = parseDate(dateFormatted)"
        />
      </template>
      <v-date-picker
        v-model="fromDate"
        no-title
      />
      <v-date-picker
        v-model="toDate"
        no-title
        :min="fromDate"
        :disabled="!fromDate"
        @input="rangeSelected"
      />
    </v-menu>
  </div>
</template>
<script>
  import dayjs from 'dayjs'

  export default {
    name: 'RangePicker',
    props: {
      label: {
        type: String,
        default: "Select Range"
      },
      value : {
        default() {
            return {
              from: null,
              to: null
            }
        },
        type: Object,
      },
    },
    data() {
      return {
        today: dayjs().format('YYYY-MM-DD'),
        fromDate: this.value.from,
        toDate: this.value.to,
        date: null,
        menu1: false,
        menu2: false,
      }
    },
    computed: {
      dateFormatted() {

        if (this.fromDate === null || this.toDate === null || this.toDate === '' || this.fromDate === '') {
          return null
        }
        return this.formatDate(this.fromDate) + ' - ' + this.formatDate(this.toDate)
      }
    },
    watch: {
      value() {
        this.fromDate = this.value.from
        this.toDate = this.value.to
      },
    },
    methods: {
      rangeSelected() {

        this.menu1 = this.menu2 = false;
        this.$emit('input', { from: this.fromDate, to: this.toDate })
      },
      formatDate(date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${year}/${month}/${day}`
      },
      parseDate(date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
    }
  }
</script>
