<template>
  <div class="pa-0 ma-0 pl-3">
    <v-row>
      <v-col class="pb-0 pl-0 pr-4">
        <v-text-field
          v-model="from"
          :label="`${label} - Min`"
          class="red--text"
          outlined
          @keyup="rangeChanged"
        />
      </v-col>
      <v-col class="pb-0 pl-0 pr-4">
        <v-text-field
          v-model="to"
          :label="`${label} - Max`"
          outlined
          @keyup="rangeChanged"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
    export default {
      name: 'NumberRangePicker',
      props: {
        label: {
          default: 'Select Range',
          type: String,
        },
        value: {
          default () {
            return {
              from : null,
              to: null
            }
          },
        type: Object,
        }
      },
      data() {
        return {
            from : this.value.from,
            to: this.value.to,
        }
      },
      watch : {
        value() {
          this.from = this.value.from
          this.to = this.value.to
        }
      },
      methods : {
        rangeChanged() {
          if(isNaN(this.from)) {
            this.from = parseInt(this.from, 10)
            return
          }

          if(isNaN(this.to)) {
            this.to =  parseInt(this.to, 10)
            return
          }

          this.$emit('input', { from : this.from, to: this.to})
        }
      }
    }
</script>
