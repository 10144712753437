<template>
  <v-container>
    <v-row>
      <v-col md="6">
        <v-expansion-panels :mandatory="true">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template>
                <v-row no-gutters>
                  <v-col
                    md="4"
                    class="subtitle"
                  >
                    Prescription Email Reminder Settings
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col
                  md="6"
                >
                  Disable/Enable Trigger email sending
                </v-col>
                <v-col
                  md="6"
                  class="pa-0 pt-0"
                >
                  <v-switch
                    v-model="getSystemSettings.prescription_reminder_settings.reminder_switch"
                    color="info"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  md="6"
                >
                  Send the prescription email after an Order is placed
                </v-col>
                <v-col
                  md="6"
                  class="ml-0 pl-0"
                >
                  <v-text-field
                    v-model="getSystemSettings.prescription_reminder_settings.reminder_frequency"
                    outlined
                    class="pa-0 ml-0 pl-0"
                    suffix="minutes"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-btn
      fixed
      color="green"
      dark
      bottom
      right
      fab
      :loading="isSaving"
      @click="updateSystemSettings"
    >
      <v-icon>mdi-content-save</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
export default {
    data: () => ({
      unsubscribe: () => {}
    }),
    computed: {
      ...mapGetters('systemSettings',[
        'getSystemSettings',
        'isSaving',
      ])
    },
    watch: {
    },
    mounted() {
      this.fetchSystemSettings()
      const self = this

      self.unsubscribe = self.$store.subscribe(mutation => {
        switch (mutation.type) {

          case 'systemSettings/SYSTEM_SETTINGS_UPDATED':
            self.$store.dispatch('notifications/showSuccess', "Settings successfully saved!")
            break
        }
      })
    },
    beforeDestroy() {
      this.unsubscribe()
    },
    methods: {
      ...mapActions('systemSettings',[
        'updateSystemSettings',
        'fetchSystemSettings',
      ])
    }
  }
</script>
