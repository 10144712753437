import store from '../store/index';
import axios from 'axios';
import Configuration from  '../utils/config'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.baseURL = Configuration.value('apiBaseURL');
// Add a request interceptor
axios.interceptors.request.use(
  function (config) {

    const token = localStorage.getItem('apollo-token');
    if(token && token !== 'null') {
      config.headers.common['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {

    if (
      401 === error.response.status ||
      (403 === error.response.status && error.config.url === '/me')
    ) {
      store.dispatch('auth/logout');

    } else {
      return Promise.reject(error);
    }
  }
);
