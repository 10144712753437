const state = () => ({
  message: '',
  type: 'success',
})

const getters = {
  getMessage: (state) => {
    return state.message
  },
  getType: (state) => {
    return state.type
  },
  getNotifications: (state) => {
    return state.notifications
  },
}

const actions = {
  removeNotification({ commit }) {
    commit('REMOVE_MESSAGE')
  },
  showError({ commit }, message) {
    commit('SHOW_ERROR', message)
  },
  showSuccess({ commit }, message) {
    commit('SHOW_SUCCESS', message)
  },
  showWebNotification(firstParam, message) {
    if (!('Notification' in window)) {
      return
    }
    if (Notification.permission === 'granted') {
      new Notification(message)
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(function (permission) {
        if (permission === 'granted') {
          new Notification(message)
        }
      })
    }
  },
}

const mutations = {
  SHOW_ERROR(state, payload) {
    state.message = payload
    state.type = 'red darken-1'
  },
  SHOW_SUCCESS(state, payload) {
    state.message = payload
    state.type = 'green darken-1'
  },
  REMOVE_MESSAGE(state) {
    state.message = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
