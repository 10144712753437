import {createProvider} from "../../vue-apollo"
import dayjs from 'dayjs'

const apolloProvider = createProvider()

const state = () => ({
  orders: [],
  meta: {
    totalCount: 0,
    page: 1,
    perPage: 10,
  },
  loadingOrders: false,
  savingOrder: false,
  currentOrder: {
    fulfilment_centre: {},
    products: [],
  },
  savingOrderFailed: false,
  bulkOrdersUpdated: false,
  savingError: null,
  triggeringReminder: false,
})

const getters = {
  triggeringReminder: (state) => {
    return state.triggeringReminder
  },
  getOrders: (state) => {
    return state.orders
  },
  getCurrentOrder: (state) => {
    return state.currentOrder
  },
  loading: (state) => {
    return state.loadingOrders
  },
  getSavingOrderStatus: (state) => {
    return state.savingOrder
  },
  getMeta: (state) => {
    return state.meta
  },
  getSavingError: (state) => {
    return state.savingError
  },
  savedBulkOrderUpdates: (state) => {
    return state.bulkOrdersUpdated
  },
}

const actions = {
  async triggerPrescriptionReminder({commit, dispatch}, orderId) {

    commit('PRESCRIPTION_REMINDER_SENDING')
    try {
      await apolloProvider.defaultClient.query({
        query: require("@/graphql/orders/TriggerPrescriptionReminder.gql"),
        variables: {
          order_id: orderId
        },
        fetchPolicy: 'no-cache',
      })
      commit('PRESCRIPTION_REMINDER_SENT')

    } catch (error) {
        let errorMessage = "Prescription reminder failed!"
        if (error.graphQLErrors) {
          const [firstError] = error.graphQLErrors
          errorMessage = firstError.message
        }

        commit('PRESCRIPTION_REMINDER_FAILED', errorMessage )
    } finally {
      dispatch('fetchOrder', orderId)
    }

  },
  async savePower({ commit }, { sku, power }) {
    commit('POWER_SAVED', { sku, power })
  },
  async fetchOrders({ commit, rootState, rootGetters }, { page, itemsPerPage }) {
    commit('ORDERS_REQUESTED')

    const appliedFilters = rootGetters['filters/getNonEmptyCurrentFilters']
    const variables = {
      ...appliedFilters,
      page,
      per_page: itemsPerPage,
    }

    try {
      const response = await apolloProvider.defaultClient.query({
        query: require("@/graphql/orders/FetchOrders.gql"),
        variables,
        fetchPolicy: 'no-cache',
      })

      commit('ORDERS_FETCHED', response.data.orders.data)
      commit('META_FETCHED', response.data.orders.meta)

    } catch (error) {
      commit('ORDERS_FETCHING_FAILED')
    }
  },
  async fetchOrder({ commit }, orderId) {
    commit('ORDERS_REQUESTED')

    try {
      const response = await apolloProvider.defaultClient.query({
        query: require("@/graphql/orders/FetchOrder.gql"),
        variables: {
          id: orderId
        },
        fetchPolicy: 'no-cache',
      })

      commit('ORDER_FETCHED', response.data.order)
    } catch (error) {
      console.log(error)
      commit('ORDER_FETCHING_FAILED')
    }
  },
  async saveOrder({ commit }, order) {
    const requestedOrder = { ...order }
    requestedOrder.products = requestedOrder.products.map(({ status, sku, power }) => {
      const thisPower = { ...power }
      delete thisPower.__typename

      return { status, sku, power: thisPower }
    })

    if (requestedOrder.cancellation_reason === undefined) {
      requestedOrder.cancellation_reason = ""
    }

    if (requestedOrder.secondary_order_status === undefined) {
      requestedOrder.secondary_order_status = ""
    }

    if (requestedOrder.shipping_courier_name === undefined || requestedOrder.shipping_courier_name === null) {
      requestedOrder.shipping_courier_name = ""
    }

    if (requestedOrder.transit_courier_name === undefined || requestedOrder.transit_courier_name === null) {
      requestedOrder.transit_courier_name = ""
    }
    // Fill fullfilment centre name
    if (requestedOrder.fulfilment_centre) {
      const fulfilmentCentre = this.getters['metas/getFulfilmentCentres'].filter(x => x.Code === requestedOrder.fulfilment_centre.id);
      if (fulfilmentCentre.length) {
        requestedOrder.fulfilment_centre.name = fulfilmentCentre[0].CentreName;
      }
    }

    if (requestedOrder.shipped_to_fc) {
      const fulfilmentCentre = this.getters['metas/getFulfilmentCentres'].filter(x => x.Code === requestedOrder.shipped_to_fc.id);
      if (fulfilmentCentre.length) {
        requestedOrder.shipped_to_fc.name = fulfilmentCentre[0].CentreName;
      } else {
        requestedOrder.shipped_to_fc.name = ""
      }
    }
    delete requestedOrder.fulfilment_centre.__typename
    delete requestedOrder.shipped_to_fc.__typename

    try {
      const response = await apolloProvider.defaultClient.mutate({
        mutation: require("@/graphql/orders/SaveOrder.gql"),
        variables: { ...requestedOrder },
      })

      commit('ORDER_SAVED', response.data.saveOrder)
    } catch (err) {

      let errorMessage = null
      if (err.graphQLErrors) {
        const [firstError] = err.graphQLErrors
        errorMessage = firstError.message
      }
      commit('ORDER_SAVING_FAILED', errorMessage)
    }
  },
  async bulkUpdateOrders({ commit }, variables) {
    return apolloProvider.defaultClient.mutate({
      mutation: require("@/graphql/orders/BulkUpdateOrders.gql"),
      variables,
    }).then((res) => {
      if (res.errors === undefined) {
        variables.selected_items.forEach(order => {
          if (variables.order_status !== '') {
            order.order_status = variables.order_status
          }
          if (variables.fulfilment_centre !== '') {
            order.fulfilment_centre.id = variables.fulfilment_centre
          }
        })
        commit("BULK_ORDERS_UPDATED", true)
      }
    }).catch((err) => {
      console.log(err)
      commit("BULK_ORDERS_UPDATED", false)
    })
  },
}

const padNumber = (num) => {
  if (isNaN(num)) {
    return num
  }

  return parseFloat(num).toFixed(2).toString()
}
const mutations = {
  ORDERS_REQUESTED(state) {
    state.loadingOrders = true
  },
  ORDERS_FETCHED(state, orders) {
    orders.forEach(order => {
      order.comments.sort((a, b) => dayjs(b.timestamp).diff(dayjs(a.timestamp)))
    })
    orders.forEach(order => {
      order.status_change_logs.sort((a, b) => dayjs(b.timestamp).diff(dayjs(a.timestamp)))
    })

    state.orders = orders
    state.loadingOrders = false
  },
  ORDER_FETCHED(state, order) {
    order.comments = order.comments.sort((a, b) => dayjs(b.timestamp).diff(dayjs(a.timestamp)))
    order.status_change_logs = order.status_change_logs.sort((a, b) => dayjs(b.timestamp).diff(dayjs(a.timestamp)))
    order.prescription_reminders = order.prescription_reminders.sort((a, b) => dayjs(b.triggered_at).diff(dayjs(a.triggered_at)))

    order.products.forEach(product => {
      if (product.power) {
        const power = product.power
        product.power = {
          ...product.power,
          rcyl: power.rcyl,
          lcyl: power.lcyl,
          rsph: power.rsph,
          lsph: power.lsph,
        }
      }
    })

    state.currentOrder = order
    state.loadingOrders = false
  },
  ORDER_FETCHING_FAILED(state) {
    //
  },
  ORDER_SAVED(state, order) {
    order.comments = order.comments.sort((a, b) => dayjs(b.timestamp).diff(dayjs(a.timestamp)))
    order.status_change_logs = order.status_change_logs.sort((a, b) => dayjs(b.timestamp).diff(dayjs(a.timestamp)))
    state.currentOrder = order
  },
  ORDER_SAVING(state, flag) {
    state.savingOrderFailed = flag
  },
  ORDER_SAVING_FAILED(state, message) {
    state.orderSaving = false
    state.savingError = message
  },
  META_FETCHED(state, meta) {
    state.meta = meta
  },
  ORDERS_FETCHING_FAILED(state) {
    state.loadingOrders = false
  },
  POWER_SAVED(state, { sku, power }) {
    const productIndex = state.currentOrder.products.findIndex(product => product.sku === sku)
    const products = [...state.currentOrder.products]
    const currentProduct = products[productIndex]

    currentProduct.power = power
    products.splice(productIndex, 1, currentProduct)

    state.currentOrder.products = products
  },
  BULK_ORDERS_UPDATED(state, flag) {
    state.bulkOrdersUpdated = flag
  },
  RESET_PAGINATION(state) {
    //
  },
  TOGGLE_ADDRESS_LENGTH(state, item) {
    const orderIndex = state.orders.findIndex(order => order.id == item.id)
    const changedOrder = state.orders[orderIndex]
    state.orders.splice(orderIndex, 1, { ...changedOrder, show_less: !changedOrder.show_less })
  },
  TOGGLE_COMMENT(state, item) {
    const orderIndex = state.orders.findIndex(order => order.id == item.id)
    const changedOrder = state.orders[orderIndex]
    state.orders.splice(orderIndex, 1, { ...changedOrder, show_all_comments: !changedOrder.show_all_comments })
  },
  PRESCRIPTION_REMINDER_FAILED(state) {
    state.triggeringReminder = false
  },
  PRESCRIPTION_REMINDER_SENT(state) {
    state.triggeringReminder = false
  },
  PRESCRIPTION_REMINDER_SENDING(state){
    state.triggeringReminder = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
