<template>
  <v-container>
    <div class="vld-parent">
      <loading
          :active.sync="isFileUploading"
          :is-full-page="true"
      />
    </div>

    <v-row class="pt-0 pb-3 pl-3">
      <template>
        <form
            :action="exportUrl"
            method="post"
        >
          <input
              type="hidden"
              name="fileType"
              value="inventory_jit_export"
          >
          <input
              type="hidden"
              name="token"
              :value="getToken"
          >
          <v-btn
              depressed
              large
              type="submit"
              class="mr-3"
              color="grey darken-3 white--text"
          >
            Export View as CSV
          </v-btn>
        </form>
      </template>
    </v-row>

    <v-data-table
        class="pt-7"
        v-model="selectedItems"
        show-select
        :headers="getFulfilmentCentreHeaders"
        :items="getData"
        :loading="loadingData"
        item-key="sku"
        :options.sync="options"
        :server-items-length.sync="getTotalCount"
        :footer-props="footer_props"
    >
      <template v-slot:top="{ pagination, updateOptions }">
        <v-data-footer
            :pagination="pagination"
            :options="options"
            :items-per-page-options="pagination_options"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            @update:options="updateOptions"
        />
      </template>

      <template v-slot:[`item.vendor`]="{ item }">
        <v-edit-dialog
            large
            :return-value.sync="item.vendor"
            @open="openVendor(item.vendor)"
            @save="updateVendor(getInventoryJIT.indexOf(item), item.sku, item.vendor)"
        >
          <a href="#">{{ item.vendor }}</a>
          <template v-slot:input>
            <v-text-field
                v-model="item.vendor"
                :rules="[rules.notempty]"
                label="Edit"
                single-line
            />
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.jitcap`]="{ item }">
        <v-edit-dialog
            :return-value.sync="item.jitcap"
            large
            @open="openJITCap(item.jitcap)"
            @save="updateJITCapability(getInventoryJIT.indexOf(item), item.sku, item.jitcap)"
        >
          <a
              href="#"
          >{{ item.jitcap }}</a>
          <template v-slot:input>
            <v-select
                v-model="item.jitcap"
                :items="getJITCapForBulk"
            />
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import Configuration from '../utils/config'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    Loading
  },
  data() {
    return {
      totalCount: 0,
      options: {},
      pagination_options: [5, 10, 15, 20],
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      rules: {
        notempty: value => value !== '' || 'Cannot be empty!',
      },
      selectedItems: [],
    }
  },
  computed: {
    ...mapGetters('fulfilmentCentres', [
      'getData',
      'getFulfilmentCentreHeaders',
      'loadingData',
      'getTotalCount',
    ]),
    ...mapGetters('auth', [
      'getUser',
      'getToken',
    ]),
    ...mapGetters('uploads', [
      'isFileUploading',
    ]),
    exportUrl() {
      return Configuration.value("apiBaseURL") + '/export'
    },
    getSelectedItems() {
      return this.selectedItems
    },
  },
  beforeDestroy() {
  },
  mounted() {
    this.$store.dispatch('fulfilmentCentres/fetchFulfilmentCentres')
  },
  methods: {},
}
</script>
