<template>
    <v-container>
        <v-row>
            <v-col
                    class="pb-7"
            >
                <v-btn
                        depressed
                        link
                        color="cyan darken-1"
                        class="white--text text--darken-3"
                        :to="{name: 'Orders'}"
                >
                    <v-icon>
                        mdi-chevron-left
                    </v-icon>
                    Go Back to Orders
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="pt-0">
                <v-skeleton-loader
                        v-if="!getCurrentOrder.id"
                        class="mb-6"
                        boilerplate
                        elevation="3"
                        type="article, actions"
                />
                <validation-observer
                        v-if="getCurrentOrder.id"
                        ref="orderForm"
                        v-slot="{ handleSubmit }"
                >
                    <form @submit.prevent="handleSubmit(submit)">
                        <v-card class="pa-5 mb-7">
                            <v-card-title
                                    class="text-uppercase pb-0"
                            >
                                Order Information
                            </v-card-title>
                            <v-row class="pa-5 pt-0 pb-0">
                                <v-col
                                        class="pa-3 pt-0 pl-1 d-flex align-center"
                                        md="5"
                                >
                                    <h1>{{ getCurrentOrder.order_number }}</h1>
                                    <v-chip
                                            :color="colorCodeOrderStatus(getCurrentOrder.order_status)"
                                            class="white--text subtitle-2 ml-5"
                                    >
                                        {{ getCurrentOrder.order_status }}
                                    </v-chip>
                                    <v-chip
                                            filterIcon="box"
                                            class="grey lighten-1 ml-2"
                                            color="orange"
                                            label
                                            outlined
                                    >
                                        <v-icon left>
                                            mdi-label
                                        </v-icon>
                                        {{ getCurrentOrder.order_type }}
                                    </v-chip>
                                </v-col>
                                <v-col
                                        md="2"
                                >
                                    <v-text-field
                                            v-model="getCurrentOrder.magento_db_id"
                                            outlined
                                            label="Magento ID"
                                            disabled
                                    />
                                </v-col>
                                <v-col
                                        md="2"
                                >
                                    <v-btn
                                            v-model="getCurrentOrder.magento_db_id"
                                            label="Magento ID"
                                            outlined
                                            depressed
                                            medium
                                            color="light-green darken-1"
                                            class="white--text text--darken-3 mt-3"
                                            target="_blank"
                                            :href="getMagentoOrderUrlFromOrderId(getCurrentOrder.magento_db_id)"
                                    >
                                        View on Magento
                                    </v-btn>
                                </v-col>
                                <v-col
                                        md="3"
                                >
                                    <v-text-field
                                            v-model="formattedCreatedAt"
                                            outlined
                                            label="Order Date"
                                            class="ml-10"
                                            disabled
                                    />
                                </v-col>
                                <v-col
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Fulfilment Centre"
                                            rules="required"
                                    >
                                        <v-select
                                                v-model="getCurrentOrder.fulfilment_centre.id"
                                                outlined
                                                :items="getFulfilmentCentres"
                                                :error-messages="errors"
                                                label="Select Fulfilment Centre"
                                                item-value="Code"
                                                item-text="DisplayName"
                                                class="float-right"
                                        >
                                            <template
                                                    slot="selection"
                                                    slot-scope="data"
                                            >
                                                {{ data.item.Code }} - {{ data.item.CentreName }}
                                            </template>
                                            <template
                                                    slot="item"
                                                    slot-scope="data"
                                            >
                                                {{ data.item.Code }} - {{ data.item.CentreName }}
                                            </template>
                                        </v-select>
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Related Order"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.related_order"
                                                outlined
                                                :error-messages="errors"
                                                label="Related Order"
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{}"
                                            name="Shipping Date"
                                    >
                                        <date-picker
                                                v-model="getCurrentOrder.shipping_date"
                                                label="Shipping Date"
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{}"
                                            name="Delivery Date"
                                    >
                                        <date-picker
                                                v-model="getCurrentOrder.delivery_date"
                                                label="Delivery Date"
                                        />
                                    </validation-provider>
                                </v-col>
                            </v-row>
                            <v-row class="pa-5 pt-0 pb-0">
                                <v-col
                                        class="pa-2 pt-0 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="AWB"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.awb_number"
                                                outlined
                                                :error-messages="errors"
                                                label="AWB"
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        class="pa-2 pt-0 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Courier Name"
                                    >
                                        <v-select
                                                v-model="getCurrentOrder.shipping_courier_name"
                                                outlined
                                                :items="getCouriers"
                                                label="Change Courier Name"
                                                :error-messages="errors"
                                                clearable
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        class="pa-2 pt-0 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Order Tracking"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.order_tracking_number"
                                                outlined
                                                :error-messages="errors"
                                                label="Order Tracking"
                                        />
                                    </validation-provider>
                                </v-col>
                            </v-row>
                            <v-row class="pa-5 pt-0 pb-0">
                                <v-col
                                        md="3"
                                        class="pa-2 pt-0 pb-0"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Store"
                                            rules="required"
                                            vid="store"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.store_details.store_name"
                                                outlined
                                                :error-messages="errors"
                                                label="Store"
                                                disabled
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        class="pa-2 pt-0 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Order Status"
                                    >
                                        <v-select
                                                v-model="getCurrentOrder.order_status"
                                                outlined
                                                :items="getOrderStatuses"
                                                label="Change Order Status"
                                                :error-messages="errors"
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        class="pa-2 pt-0 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            ref="hold_status"
                                            name="OOS / Hold Status"
                                    >
                                        <v-select
                                                v-model="getCurrentOrder.secondary_order_status"
                                                outlined
                                                :items="getHoldStatuses"
                                                label="OOS / Hold Status"
                                                :error-messages="errors"
                                                clearable
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        class="pa-2 pt-0 pb-0"
                                        lg="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            ref="cancellation_reason"
                                            name="Edited / Cancellation Reason"
                                    >
                                        <v-select
                                                v-model="getCurrentOrder.cancellation_reason"
                                                outlined
                                                :items="getCancellationReasons"
                                                label="Edited / Cancellation Reason"
                                                :error-messages="errors"
                                                clearable
                                        />
                                    </validation-provider>
                                </v-col>
                            </v-row>
                            <v-row class="pa-5 pt-0 pb-0">
                                <v-col
                                        class="pa-2 pt-0 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Transit Courier Name"
                                    >
                                        <v-select
                                                v-model="getCurrentOrder.transit_courier_name"
                                                outlined
                                                :items="getCouriers"
                                                label="Change Transit Courier Name"
                                                :error-messages="errors"
                                                clearable
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        class="pa-2 pt-0 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Transit AWB"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.transit_awb_number"
                                                outlined
                                                :error-messages="errors"
                                                label="Transit AWB"
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        class="pa-2 pt-0 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Shipped to FC"
                                    >
                                        <v-select
                                                v-model="getCurrentOrder.shipped_to_fc.id"
                                                outlined
                                                :items="getFulfilmentCentres"
                                                :error-messages="errors"
                                                label="Select Shipped to FC"
                                                item-value="Code"
                                                item-text="DisplayName"
                                                clearable
                                        >
                                            <template
                                                    slot="selection"
                                                    slot-scope="data"
                                            >
                                                {{ data.item.Code }} - {{ data.item.CentreName }}
                                            </template>
                                            <template
                                                    slot="item"
                                                    slot-scope="data"
                                            >
                                                {{ data.item.Code }} - {{ data.item.CentreName }}
                                            </template>
                                        </v-select>
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-card class="pa-5 mt-7 pb-10">
                            <v-card-title class="text-uppercase pb-0">
                                Customer Information
                            </v-card-title>
                            <v-row class="pa-5 pb-0">
                                <v-col
                                        class="pa-3 pt-0 pl-1 pb-0"
                                        md="3"
                                >
                                    <v-select
                                            v-model="getCurrentOrder.shipping_type"
                                            label="Shipping Type"
                                            item-text="Shipping Type"
                                            :items="getShippingTypes"
                                            outlined
                                    />
                                </v-col>
                                <v-col
                                        class="pa-3 pt-0 pl-1 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Customer Name"
                                            rules="required"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.client_name"
                                                outlined
                                                :error-messages="errors"
                                                label="Customer Name"
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        class="pa-3 pt-0 pl-1 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Shipping Country"
                                            rules="required"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.country"
                                                outlined
                                                :error-messages="errors"
                                                label="Country"
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        class="pa-3 pt-0 pl-1 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Shipping City"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.city"
                                                outlined
                                                :error-messages="errors"
                                                label="Shipping City"
                                        />
                                    </validation-provider>
                                </v-col>
                            </v-row>
                            <v-row class="pa-5 pt-0 pb-0">
                                <v-col
                                        class="pa-3 pt-0 pl-1 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Phone"
                                            rules="required"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.phone"
                                                outlined
                                                :error-messages="errors"
                                                label="Customer Phone"
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        class="pa-3 pt-0 pl-1 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Email"
                                            rules="required"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.email"
                                                outlined
                                                :error-messages="errors"
                                                label="Email"
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        md="6"
                                        class="pa-3 pt-0 pl-1 pb-0"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Address"
                                            rules="required"
                                    >
                                        <v-textarea
                                                v-model="getCurrentOrder.address"
                                                outlined
                                                :error-messages="errors"
                                                label="Address"
                                        />
                                    </validation-provider>
                                </v-col>
                            </v-row>
                            <v-row class="pa-5 pt-0 pb-0">
                                <v-col
                                        class="pa-3 pt-0 pl-1 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="RetailTelephone"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.retail_telephone"
                                                outlined
                                                :error-messages="errors"
                                                label="Retail Telephone"
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        class="pa-3 pt-0 pl-1 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="ShippingStreet"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.shipping_street"
                                                outlined
                                                :error-messages="errors"
                                                label="Shipping Street"
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        class="pa-3 pt-0 pl-1 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="ShippingArea"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.shipping_area"
                                                outlined
                                                :error-messages="errors"
                                                label="Shipping Area"
                                        />
                                    </validation-provider>
                                </v-col>
                            </v-row>
                            <v-row class="pa-5 pt-1 pb-0">
                                <v-col
                                        class="pa-3 pt-0 pl-1 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="gMapCoordinates"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.gmap_coordinates"
                                                outlined
                                                :error-messages="errors"
                                                label="GMap Coordinates"
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        class="pa-3 pt-0 pl-1 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Latitude"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.latitude"
                                                outlined
                                                :error-messages="errors"
                                                label="Latitude"
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col
                                        class="pa-3 pt-0 pl-1 pb-0"
                                        md="3"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Longitude"
                                    >
                                        <v-text-field
                                                v-model="getCurrentOrder.longitude"
                                                outlined
                                                :error-messages="errors"
                                                label="Longitude"
                                        />
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-card class="pa-5 mt-7">
                            <v-card-title
                                    class="text-uppercase pb-0"
                            >
                                <v-row>
                                    <v-col>
                                        Order Items ({{ getCurrentOrder.products.length }})
                                    </v-col>
                                    <v-col v-if="shouldShowTriggerEmailBtn">
                                        <v-btn
                                                depressed
                                                small
                                                class="float-right mb-5"
                                                color="light-green darken-1 white--text"
                                                :loading="triggeringReminder"
                                                @click="triggerReminder"
                                        >
                                            Send Prescription Email
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-simple-table
                                    fixed-header
                                    height="300px"
                            >
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left">
                                            Qty
                                        </th>
                                        <th class="text-left">
                                            Sku
                                        </th>
                                        <th class="text-left">
                                            Product
                                        </th>
                                        <th class="text-left">
                                            Category
                                        </th>
                                        <th
                                                class="text-left"
                                                width="20%"
                                        >
                                            SKU Status
                                        </th>
                                        <th class="text-left">
                                            Power
                                        </th>
                                        <th class="text-left">
                                            Prescription URL
                                        </th>
                                        <th class="text-left">
                                            Original Price
                                        </th>
                                        <th class="text-left">
                                            Sale Price
                                        </th>
                                        <th class="text-left">
                                            Sub Total
                                        </th>
                                        <th class="text-left">
                                            Tax Amount
                                        </th>
                                        <th class="text-left">
                                            Discount Amount
                                        </th>
                                        <th class="text-left">
                                            Total Price
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr
                                            v-for="(product, index) in getCurrentOrder.products"
                                            :key="index"
                                    >
                                        <td>{{ product.quantity }}</td>
                                        <td>{{ product.sku }}</td>
                                        <td>{{ product.name }}</td>
                                        <td>{{ product.category }}</td>
                                        <td class="pt-5 align-center">
                                            <v-select
                                                    v-model="product.status"
                                                    :items="getSkuStatuses"
                                                    outlined
                                                    dense
                                                    label="Sku Status"
                                            />
                                        </td>
                                        <td
                                                v-if="product.power !== null"
                                        >
                                            <v-btn
                                                    depressed
                                                    small
                                                    :disabled="!product.power"
                                                    color="light-green darken-1"
                                                    class="white--text text--darken-3"
                                                    target="_blank"
                                                    :href="getEGToolUrlFromOrderId(getCurrentOrder.id)"
                                            >
                                                View Power
                                            </v-btn>
                                        </td>
                                        <td v-else>
                                            N/A
                                        </td>
                                        <td>
                                            <template v-if="isPrescriptionEG(product.power, index)">
                                                <template v-if="!editingPrescriptionUrl[index]">
                                                    <a
                                                            :href="isPrescriptionEG(product.power, index)"
                                                            target="_blank"
                                                    >
                                                        View Prescription
                                                        <v-icon>mdi-open-in-new</v-icon>
                                                    </a>
                                                    <v-icon
                                                            @click="editPrescriptionUrl(index)"
                                                    >
                                                        mdi-pencil
                                                    </v-icon>
                                                </template>
                                                <template v-if="!!editingPrescriptionUrl[index]">
                                                    <v-text-field
                                                            v-model="product.power.prescription_url"
                                                    />
                                                </template>
                                            </template>
                                            <template v-else>
                                                N/A
                                            </template>
                                        </td>
                                        <td>{{ product.original_price }}</td>
                                        <td>{{ product.price_incl_tax }}</td>
                                        <td>{{ product.sub_total }}</td>
                                        <td>{{ product.tax_amount }}</td>
                                        <td>{{ product.discount_amount }}</td>
                                        <td>{{ product.row_total_incl_tax }}</td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>

                        <v-card class="pa-5 mt-7">
                            <v-card-title
                                    class="text-uppercase pb-0"
                            >
                                <v-row>
                                    <v-col class="col-8 ml-1">
                                        Order Amounts
                                    </v-col>
                                    <v-col>
                                        <v-btn
                                                depressed
                                                small
                                                color="light-green darken-1"
                                                class="white--text text--darken-3 float-right"
                                                @click="editAmounts"
                                        >
                                            Edit Amounts
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-row class="pa-5 pt-2 pb-0">
                                <v-col md="3">
                                    <v-text-field
                                            v-model="getCurrentOrder.discount_code"
                                            outlined
                                            :error-messages="errors"
                                            label="Coupon Code"
                                            disabled
                                    />
                                </v-col>
                                <v-col
                                        md="3"
                                        class="pa-3 pl-1 pb-0"
                                >
                                    <v-text-field
                                            v-model="getCurrentOrder.payment_method"
                                            outlined
                                            :error-messages="errors"
                                            label="Payment Method"
                                            disabled
                                    />
                                </v-col>
                                <v-col
                                        md="3"
                                        class="pa-3 pl-1 pb-0"
                                >
                                    <v-text-field
                                            v-model="getCurrentOrder.discount_amount"
                                            outlined
                                            :error-messages="errors"
                                            label="Discount Amount"
                                            :disabled="disable_order_amounts"
                                            :prefix="orderCurrency"
                                    />
                                </v-col>
                                <v-col
                                        md="3"
                                        class="pa-3 pl-1 pb-0"
                                >
                                    <v-text-field
                                            v-model="getCurrentOrder.shipping_fee"
                                            outlined
                                            :error-messages="errors"
                                            label="Shipping Fee"
                                            :disabled="disable_order_amounts"
                                            :prefix="orderCurrency"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="pa-5 pt-0 pb-0">
                                <v-col
                                        md="3"
                                        class="pt-0"
                                >
                                    <v-text-field
                                            v-model="getCurrentOrder.cod_fee"
                                            outlined
                                            :error-messages="errors"
                                            label="COD Fee"
                                            :disabled="disable_order_amounts"
                                            :prefix="orderCurrency"
                                    />
                                </v-col>
                                <v-col
                                        md="3"
                                        class="pa-3 pt-0 pl-1 pb-0"
                                >
                                    <v-text-field
                                            v-model="getCurrentOrder.grand_total"
                                            outlined
                                            :error-messages="errors"
                                            label="Grand Total Fee"
                                            :disabled="disable_order_amounts"
                                            :prefix="orderCurrency"
                                    />
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-card class="pa-5 mt-7">
                            <v-card-title
                                    class="text-uppercase pb-0 ml-1"
                            >
                                Comments and Logs
                            </v-card-title>
                            <v-row class="pa-5 pb-0">
                                <v-col
                                        md="6"
                                        class="pt-0"
                                >
                                    <validation-provider
                                            v-slot="{ errors }"
                                            name="Comment"
                                    >
                                        <v-textarea
                                                v-model="getCurrentOrder.comment"
                                                outlined
                                                :error-messages="errors"
                                                label="Comment"
                                        />
                                    </validation-provider>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                        md="6"
                                        xs="12"
                                        class="pl-1 pt-0 scrolldiv"
                                >
                                    <v-list-item class="ml-3 pt-0">
                                        <v-list-item-content class="pb-0">
                                            <div class="grey--text mb-2 text-uppercase pt-2">
                                                User Comments
                                            </div>
                                            <v-list-item-subtitle
                                                    v-if="getCurrentOrder.comments.length === 0"
                                                    class="grey--text mt-1"
                                            >
                                                No comments yet.
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item
                                            v-for="(comment, index) in getCurrentOrder.comments"
                                            :key="`comment-${index}`"
                                            two-line
                                            class="ml-3"
                                    >
                                        <v-list-item-content class="pt-1">
                                            <v-list-item-subtitle class="grey--text pb-2">
                                                {{ comment.user_email }} - {{
                                                changeTimestampToDate(comment.timestamp)
                                                }}
                                            </v-list-item-subtitle>
                                            <!-- eslint-disable vue/no-v-html -->
                                            <v-list-item-title
                                                    class="text-wrap pb-2"
                                                    v-html="nl2br(comment.comment)"
                                            />
                                            <!-- eslint-enable vue/no-v-html -->
                                            <v-divider/>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col
                                        md="6"
                                        xs="12"
                                        class="pt-0 scrolldiv"
                                >
                                    <v-list-item class="ml-3">
                                        <v-list-item-content class="pb-0">
                                            <div class="grey--text mb-2 text-uppercase">
                                                System Logs
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                            v-for="(log, index) in getCurrentOrder.status_change_logs"
                                            :key="`log-${index}`"
                                            two-line
                                            class="ml-3"
                                    >
                                        <v-list-item-content class="pt-0">
                                            <v-list-item-subtitle class="grey--text pb-2">
                                                {{ log.user_email }} - {{ changeTimestampToDate(log.timestamp) }}
                                            </v-list-item-subtitle>
                                            <v-list-item-title
                                                    class="text-wrap pb-2"
                                            >
                                                {{ log.log }}
                                            </v-list-item-title>
                                            <v-divider/>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-btn
                                    fixed
                                    color="green"
                                    dark
                                    bottom
                                    right
                                    fab
                                    type="submit"
                                    :disabled="shouldDisableUpdate"
                                    :loading="savingOrder"
                            >
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                        </v-card>

                        <v-card class="pa-5 mt-7">
                            <v-card-title
                                    class="text-uppercase pb-0 ml-1"
                            >
                                Prescription Reminder Logs
                            </v-card-title>
                            <v-card-text>
                                <v-row
                                        class="pa-0"
                                >
                                    <v-col
                                            id="vertical"
                                            class="pa-0 scrolldiv"
                                            md="6"
                                            xs="12"
                                    >
                                        <v-list-item
                                                v-if="getCurrentOrder.prescription_reminders.length === 0"
                                                class="pa-0"
                                        >
                                            <v-list-item-content
                                                    class="pa-0"
                                            >
                                                <v-list-item-subtitle

                                                        class="grey--text"
                                                >
                                                    No prescription reminders sent yet.
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item
                                                v-for="(reminder, index) in getCurrentOrder.prescription_reminders"
                                                :key="`reminder-${index}`"
                                                class="mt-7"
                                                two-line
                                        >
                                            <v-list-item-content class="pa-0">
                                                <v-list-item-subtitle class="grey--text pb-2">
                                                    <div class="mb-3">
                                                        {{ reminder.triggered_by }} -
                                                        {{ changeTimestampToDate(reminder.triggered_at) }}
                                                    </div>

                                                    <v-chip
                                                            :class="{ 'red':reminder.status === 'failed', 'green lighten-1':reminder.status !=='failed' }"
                                                            color="white--text"
                                                    >
                                                        {{ reminder.status.toUpperCase() }}
                                                    </v-chip>

                                                    <template v-if="reminder.status === 'failed'">
                                                        {{
                                                        reminder.failed_reason.replace("SendEmailReminderForPrescription:", "")
                                                        }}
                                                    </template>
                                                </v-list-item-subtitle>
                                                <v-divider/>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-btn
                                    fixed
                                    color="green"
                                    dark
                                    bottom
                                    right
                                    fab
                                    type="submit"
                                    :disabled="shouldDisableUpdate"
                                    :loading="savingOrder"
                            >
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                        </v-card>
                    </form>
                </validation-observer>
            </v-col>
        </v-row>
        <v-dialog
                v-model="showPowerDialog"
                max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="headline">Manage Power:</span>
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-container>
                        <v-row>
                            <v-col>
                                &nbsp;
                            </v-col>
                            <v-col class="font-weight-bold pb-0">
                                SPH
                            </v-col>
                            <v-col class="font-weight-bold pb-0">
                                CYL
                            </v-col>
                            <v-col class="font-weight-bold pb-0">
                                Axis
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="font-weight-bold">
                                Right
                            </v-col>
                            <v-col>
                                <v-select
                                        v-model="currentProduct.power.rsph"
                                        :items="getSphereRange"
                                        label="RSPH"
                                />
                            </v-col>
                            <v-col>
                                <v-select
                                        v-model="currentProduct.power.rcyl"
                                        :items="getSphereRange"
                                        label="RCYL"
                                />
                            </v-col>
                            <v-col>
                                <v-select
                                        v-model="currentProduct.power.raxis"
                                        :items="getAxisRange"
                                        label="RAXIS"
                                />
                            </v-col>
                        </v-row>
                        <v-divider class="mb-5 mt-5"/>
                        <v-row>
                            <v-col class="font-weight-bold">
                                Left
                            </v-col>
                            <v-col class="">
                                <v-select
                                        v-model="currentProduct.power.lsph"
                                        :items="getSphereRange"
                                        label="LSPH"
                                />
                            </v-col>
                            <v-col class="">
                                <v-select
                                        v-model="currentProduct.power.lcyl"
                                        :items="getCylinderRange"
                                        label="LCYL"
                                />
                            </v-col>
                            <v-col class="">
                                <v-select
                                        v-model="currentProduct.power.laxis"
                                        :items="getAxisRange"
                                        label="LAXIS"
                                />
                            </v-col>
                        </v-row>
                        <v-divider class="mb-5 mt-5"/>
                        <v-row class="pb-0">
                            <v-col class="font-weight-bold pb-0">
                                PD Value
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0">
                                <v-select
                                        v-model="currentProduct.power.pd"
                                        :items="getPdRange"
                                        label="PD"
                                />
                            </v-col>
                            <v-col class="pt-0">
                                <v-select
                                        v-model="currentProduct.power.lens_type"
                                        :items="getLensTypes"
                                        label="Lens Type"
                                />
                            </v-col>
                            <v-col class="pt-0">
                                <v-select
                                        v-model="currentProduct.power.coating"
                                        :items="getCoatings"
                                        label="Coating"
                                />
                            </v-col>
                        </v-row>
                        <v-divider class="mb-5 mt-5"/>
                        <v-row class="pb-0">
                            <v-col
                                    class="font-weight-bold pb-0"
                            >
                                Lens Thickness
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                    md="5"
                                    class=""
                            >
                                <v-text-field
                                        v-model="currentProduct.power.thickness"
                                        :rules="[rules.notdecimal]"
                                        label="Thickness"
                                />
                            </v-col>
                        </v-row>
                        <v-divider class="mb-5 mt-5"/>
                    </v-container>
                </v-card-text>

                <v-card-actions class="pb-8 pt-0 pr-9">
                    <v-spacer/>
                    <v-btn
                            :disabled="savingOrder"
                            depressed
                            :class="[savingOrder ? 'grey lighten-3 white--text': 'grey darken-3 white--text', 'pa-3']"
                            text
                            @click="showPowerDialog = !showPowerDialog"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {required, email, max} from 'vee-validate/dist/rules'
import {extend, ValidationObserver, ValidationProvider, setInteractionMode} from 'vee-validate'
import {mapGetters} from 'vuex'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import DatePicker from "../components/common/DatePicker"
import strings from "../mixins/strings"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import Configuration from "../utils/config";

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})
extend('required_if', {
  ...required,
  message: '{_field_} can not be empty',
})


export default {
  components: {
    DatePicker,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [strings],
  data: () => ({
    errors: '',
    showPowerDialog: false,
    currentProduct: {
      power: {}
    },
    name: '',
    email: '',
    select: null,
    editingPrescriptionUrl: {"0": false, "1": false},
    savingOrder: false,
    disable_update: false,
    disable_order_amounts: true,
    unsubscribe: () => {
    },
    rules: {
      notdecimal: (number) => {
        let state = true
        if (number !== '') {
          if (!/^["|']{0,1}[-]{0,1}\d{0,}(\.{0,1}\d+)["|']{0,1}$/.test(number)) {
            state = false
          }
          number - parseInt(number) ? state = true : state = false
        }

        return number !== '' && state || 'Not a valid decimal'
      },
    },
  }),
  computed: {
    ...mapGetters('orders', [
      'getCurrentOrder',
      'getSavingOrderStatus',
      'getSavingError',
      'triggeringReminder',
    ]),
    ...mapGetters('metas', [
      'getCities',
      'getFulfilmentCentres',
      'getPriceRange',
      'getOrderStatuses',
      'getProductCategories',
      'getHoldStatuses',
      'getCancellationReasons',
      'getSkuStatuses',
      'getPaymentMethods',
      'getStores',
      'getMagentoStatuses',
      'getShippingTypes',
      'getSphereRange',
      'getCylinderRange',
      'getPdRange',
      'getAxisRange',
      'getShippingTypes',
      'getCoatings',
      'getLensTypes',
      'getCouriers',
    ]),
    orderCurrency() {
      if (this.getCurrentOrder.store_details) {
        return this.getCurrentOrder.store_details.currency
      }
      return ''
    },
    formattedCreatedAt() {
      if (this.getCurrentOrder.order_created_at) {
        return dayjs.tz(this.getCurrentOrder.order_created_at, "Asia/Dubai").format('lll')
      }

      return ''
    },
    shouldShowTriggerEmailBtn() {
      const currentOrder = this.getCurrentOrder
      let hasEg = false
      currentOrder.products.forEach(product => {
        if (product.category === "EG") {
          hasEg = true
        }
      })

      return hasEg && currentOrder.prescription_type === ""
    },
    shouldDisableUpdate() {
        return this.getCurrentOrder.order_type === "Return"
    },
  },
  mounted() {
    dayjs.extend(localizedFormat)
    dayjs.extend(utc)
    dayjs.extend(timezone)

    this.$store.dispatch('metas/fetchFulfilmentCentres')
    this.$store.dispatch('metas/fetchCouriers')

    this.$store.dispatch('orders/fetchOrder', this.$route.params.id)
    const self = this
    self.unsubscribe = self.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'orders/ORDER_SAVED':
          self.savingOrder = false
          self.showPowerDialog = false
          self.disableAmountsOnSave()
          self.getCurrentOrder.products.forEach((product, index) => {
            self.editingPrescriptionUrl[index] = false
          })
          self.$store.dispatch('notifications/showSuccess', 'Order successfully saved')
          break
        case 'orders/ORDER_FETCHED':
          self.getCurrentOrder.products.forEach((product, index) => {
            self.editingPrescriptionUrl[index] = false
          })
          break
        case 'orders/ORDER_SAVING_FAILED':
          self.savingOrder = false
          let errorMessage = "'Failed to save order! Please try again!'"
          if (self.$store.getters['orders/getSavingError']) {
            errorMessage = self.$store.getters['orders/getSavingError']
          }
          self.$store.dispatch('notifications/showError', errorMessage)

          break
        case 'orders/POWER_SAVED':
          self.$store.dispatch('notifications/showSuccess', 'Power saved!')
          self.showPowerDialog = false
          break
        case 'orders/ORDER_FETCHING_FAILED':
          self.$store.dispatch('notifications/showError', 'Error fetching order details. Please try again!')
          break
        case 'orders/PRESCRIPTION_REMINDER_SENT':
          self.$store.dispatch('notifications/showSuccess', 'Reminder Sent!')
          break
        case 'orders/PRESCRIPTION_REMINDER_FAILED':
          self.$store.dispatch('notifications/showError', 'Error sending prescription reminder!')
          break

      }
    })
  },
  destroyed() {
    this.unsubscribe()
  },
  methods: {
    isPrescriptionEG(power, index) {
      if (!power) {
        return false
      }

      return (power.lens_type === "PRESCRIPTION" && this.editingPrescriptionUrl[index]) || power.prescription_url
    },
    editPrescriptionUrl(index) {
      this.editingPrescriptionUrl[index] = true
    },
    triggerReminder() {
      if (confirm("Are you sure, you want to send the prescription reminder email?")) {
        this.$store.dispatch('orders/triggerPrescriptionReminder', this.$store.getters['orders/getCurrentOrder'].id)
      }
    },
    getUrlFromPower(power) {
      return power ? power.prescription_url : ''
    },
    getMagentoOrderUrlFromOrderId(id) {
      return Configuration.value('magentoOrderUrl') + id
    },
    getEGToolUrlFromOrderId(id) {
      return Configuration.value('egToolUrl') + id
    },
    changeTimestampToDate(timestamp) {
      return dayjs(timestamp).format('lll')
    },
    async submit() {
      const orderStatus = this.$store.getters['orders/getCurrentOrder']['order_status']
      const oosStatus = this.$store.getters['orders/getCurrentOrder']['secondary_order_status']
      const cancellationReason = this.$store.getters['orders/getCurrentOrder']['cancellation_reason']
      let hasError = false

      if (!this.validateRequiredIfOrderStatusAre(oosStatus, orderStatus, ['OOS', 'On_Hold'])) {
        hasError = true
        this.$refs.hold_status.applyResult({
          errors: ["OOS / Hold Status is required when Order Status is OOS or On Hold"],
          valid: false,
          failedRules: {}
        })
      }

      if (!this.validateRequiredIfOrderStatusAre(cancellationReason, orderStatus, ['Edited', 'Cancelled'])) {
        hasError = true
        this.$refs.cancellation_reason.applyResult({
          errors: ["Cancellation Reason is required when Order Status is Edited or Cancelled"],
          valid: false,
          failedRules: {}
        })
      }

      if (!hasError) {
        this.$nextTick(() => {
          this.savingOrder = true
          this.$store.dispatch('orders/saveOrder', this.$store.getters['orders/getCurrentOrder'])
        })
        return
      }
      this.savingOrder = false
      this.$store.dispatch('notifications/showError', 'Form has errors. Please fix!')
    },
    validateRequiredIfOrderStatusAre(value, orderStatus, conditionStatuses) {
      return (conditionStatuses.includes(orderStatus) && value) || !conditionStatuses.includes(orderStatus)
    },
    clear() {
      this.name = ''
      this.email = ''
      this.select = null
      this.checkbox = null
      this.$refs.observer.reset()
    },
    colorCodeOrderStatus(status) {
      switch (status) {
        case 'In_Stock':
          return 'green lighten-1'
        case 'Partially_In_Stock':
          return 'teal lighten-2'
        case 'Ordered':
          return 'amber lighten-1'
        case 'OOS':
          return 'grey lighten-1'
        case 'On_Hold':
          return 'blue lighten-1'
        case 'Cancelled':
          return 'red lighten-1'
        case 'Shipped':
          return 'light-blue lighten-2'
        case 'Edited':
          return 'yellow darken-4'
        default:
          return 'purple lighten-2'
      }
    },
    checkout(flag) {
      if (flag) {
        this.disable_update = true
        return
      }
      this.disable_update = false
    },
    editAmounts() {
      this.disable_order_amounts = !this.disable_order_amounts
    },
    disableAmountsOnSave() {
      this.disable_order_amounts = true
    }
  },
}
</script>
