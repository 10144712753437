<template>
  <v-app>
    <v-container
      fill-height
      fluid
      class="grey lighten-3"
    >
      <notification />
      <v-row
        align="center"
        justify="center"
      >
        <v-col>
          <v-card
            max-width="400"
            class="mx-auto"
          >
            <v-row>
              <v-col
                md="12"
                xs="12"
                class="pt-0 pb-0"
              >
                <v-img
                  :src="require('@/assets/bg.jpg')"
                >
                  <img
                    :src="require('@/assets/eyewa-logo-en.svg')"
                    width="200"
                    height="150"
                    class="d-flex justify-center center"
                  >
                </v-img>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                class="teal lighten-2 pb-0"
              >
                <div>
                  <h3 class="font-weight-medium white--text pb-5 d-flex justify-center mt-5">
                    Order Management System
                  </h3>
                </div>
              </v-col>
            </v-row>
            <v-row
              class="grey lighten-2"
              no-gutters
            >
              <v-col pa-0>
                <v-card-actions
                  class="pa-0"
                >
                  <router-view class="d-flex justify-center" />
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
  import Notification from "@/components/common/Notification";

  export default {
    name: 'LoginLayout',
    components: { Notification }
  }
</script>
<style scoped>
.abcRioButton {
  padding-right: 30px;
}
.center {
  margin:0 auto !important;
}
</style>