import {createProvider} from "../../vue-apollo"
import dayjs from 'dayjs'
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

const apolloProvider = createProvider()

const statusMap = {
  'Ordered': ['PO_Placed'],
  'Reqd_Processing': ['In_Process'],
  'PO_Placed': [
    'In_Stock',
    'Reqd_Processing',
    'Reqd_Prescription',
  ],
  'Reqd_Prescription': [
    'Reqd_Processing'
  ],
  'In_Process': ['In_Stock'],
}

const state = () => ({
  skus: [],
  label: '',
  fromStatuses: [
    'Ordered',
    'Reqd_Processing',
    'Reqd_Prescription',
    'PO_Placed',
    'In_Process',
  ],
  toStatuses: [],
  filters: {
    sku_qty: {
      from: null,
      to: null,
    },
    categories: [],
    gvpo_appearance: "",
    order_date: {
      from: null,
      to: null,
    },
    status: {
      from: '',
      to: '',
    },
    keywords: [],
  },
  selectedSKUs : [],
  loadingSKUs: false,
  transitionInProgress: false,
  transitionError: null,
})

const getters = {
  getSKUs(state) {
    return state.skus
  },
  getFromStatuses(state) {
    return state.fromStatuses
  },
  getToStatuses(state) {
    return state.toStatuses
  },
  getFilters(state) {
    return state.filters
  },
  getBooleanTypes: () => {
    return ['Yes', 'No', 'All']
  },
  loadingSKUs(state) {
    return state.loadingSKUs
  },
  getTransitionProgress(state) {
    return state.transitionInProgress
  },
  getTransitionError: (state) => {
    return state.transitionError
  },
  getSelectedSKUs(state) {
    return state.selectedSKUs
  },
}

const actions = {
  clearFilter({ commit }) {
    commit('FILTER_CLEARED')
  },
  async fetchSKUsForTransition({ commit, getters, rootGetters }, { notifyCompleted }) {
    commit('SKUS_REQUESTED')
    let variables = {
      fulfilment_centre: rootGetters['inventory/getCurrentFulfilmentCentre']
    }

    const filters = { ...getters.getFilters }

    for (let filter in filters) {
      switch (filter) {
        case "sku_qty":
          if (filters.sku_qty.from && filters.sku_qty.to) {
            variables.min_sku_qty = filters.sku_qty.from
            variables.max_sku_qty = filters.sku_qty.to
          }
        break
        case "status":
          if (filters.status.from) {
            variables.from_status = filters.status.from
          }

          if (filters.status.to) {
            variables.to_status = filters.status.to
          }
        break
        case "order_date":
          if (filters.order_date.from && filters.order_date.to) {
            variables.order_date_from = filters.order_date.from
            variables.order_date_to = filters.order_date.to
          }
        break
        case "categories":
        case "keywords":
        case "gvpo_appearance":
          variables[filter] = filters[filter]
        break
      }
    }

    const response = await apolloProvider.defaultClient.query({
      query: require("@/graphql/sku_status_transition/FetchSKUs.gql"),
      fetchPolicy: 'no-cache',
      variables
    })

    commit('SKUS_FETCHED', response.data.skuSearchQuery.data)
    
    if (notifyCompleted) {
      commit('LOADING_SKUS_COMPLETED')
    }
  },
  async transitionSKUStatuses({ commit, getters }) {    
    let variables = {
      'transition_skus': [],
      'from_status': getters.getFilters.status.from,
      'to_status': getters.getFilters.status.to
    }
    
    getters.getSelectedSKUs.map(({ sku, order_number }) => {
      variables.transition_skus.push({ sku, order_number })
    })
    try {
      await apolloProvider.defaultClient.mutate({
        mutation: require("@/graphql/sku_status_transition/SKUStatusTransition.gql"),
        variables
      })
    } catch (err) {
      commit('TRANSITION_ERRORED', err.graphQLErrors[0].message)
      return
    }
    commit('TRANSITION_COMPLETED')
  },
}

const formatComment = (gvpoCount, lastAppearedTimestamp) => {
  if (gvpoCount < 1 || !lastAppearedTimestamp) {
    return `<span class="font-weight-bold deep-orange--text darken-1">Has NOT appeared in GVPO</span>`
  }

  return `<span class="font-weight-bold">GVPO Count: 
<span class="deep-orange--text darken-1">${gvpoCount}</span>
<br/>Last Appeared:
</span>
<span>${dayjs(lastAppearedTimestamp).format('lll')}</span>`
}

const showComment = (fromStatus) => {
  switch (fromStatus) {
    case 'PO_Placed':
    case 'Reqd_Prescription':
    case 'In_Process':
      return false
    default:
      return true
  }
}

const mutations = {
  SKUS_FETCHED(state, skus) {
    const formattedSKUs = skus.map(sku => {
      sku.id = `${sku.order_number}_${sku.sku}_` + Math.random() * 10000000
      sku.comment = showComment(state.filters.status.from) ? formatComment(sku.gvpo_counter, sku.last_appeared_in_gvpo_timestamp) : ''

      return sku
    })
    state.skus = formattedSKUs
    state.loadingSKUs = false
    state.selectedSKUs = state.skus.filter(sku => sku.gvpo_counter > 0 || (sku.gvpo_counter == 0 && !showComment(state.filters.status.from)))
  },
  FROM_STATUS_SET(state) {
    const fromStatus = state.filters.status.from
    state.filters.status.to = ''
    state.skus = []
    state.toStatuses = statusMap[fromStatus]
  },
  TO_STATUS_SET(state) {
    state.skus = []
  },
  ADD_KEYWORD(state, keyword) {
    if (!state.filters.keywords.includes(keyword)) {
      state.filters.keywords.push(keyword)
    }
  },
  REMOVE_KEYWORD(state, index) {
    state.filters.keywords.splice(index, 1)
  },
  FILTER_CLEARED(state) {
    state.filters = Object.assign({}, {
      sku_qty: {
        from: null,
        to: null,
      },
      categories: [],
      gvpo_appearance: "",
      order_date: {
        from: null,
        to: null,
      },
      status: {
        from: '',
        to: '',
      },
      keywords: [],
    })
    state.skus = []
  },
  SKUS_REQUESTED(state) {
    state.loadingSKUs = true
  },
  LOADING_SKUS_COMPLETED() {
    //
  },
  TRANSITION_IN_PROGRESS(state) {
    state.transitionInProgress = true
  },
  TRANSITION_ERRORED(state, err) {
    state.transitionInProgress = false
    state.transitionError = err
  },
  TRANSITION_COMPLETED(state) {
    state.transitionInProgress = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
