export default {
  methods: {
    nl2br(comment) {
      return comment.replace(/(?:\r\n|\r|\n)/g, "<br>")
    },
    isUrl(urlStr) {
      let url;

      try {
        url = new URL(urlStr);
      } catch (_) {
        return false;
      }

      return (url.protocol === "http:" || url.protocol === "https:") && urlStr.includes('.');
    }
  }
}
