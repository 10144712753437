import {createProvider} from "../../vue-apollo"
import Configuration from  '@/utils/config'

import {defaultOrderTableHeaders, defaultFilters, defaultEnabledFilters, allowedColumns} from "../defaultFilter"

const enabledFilters = localStorage.getItem('enabled_filters') && localStorage.getItem('enabled_filters') !== null
  ? JSON.parse(localStorage.getItem('enabled_filters')) :
  defaultEnabledFilters.map(filter => filter.value)

let currentFilter = {}
let localStorageFilter = localStorage.getItem('current_filter')

//check src/utils/config.js -> CONFIG() function for explanation
const currentVersion = Configuration.value('cache_version');
const lastCacheVersion = localStorage.getItem('cache_version')

if (localStorageFilter && currentVersion == lastCacheVersion) {
  try {
    currentFilter = JSON.parse(localStorageFilter)
    if(currentFilter.columns === undefined || currentFilter.id === undefined){
      throw new Error("empty filter")
    }
  } catch (e) {
    //if JSON.parse fails mos likely the json payload is corrupt
    //so resetting to default
    currentFilter = JSON.parse(JSON.stringify({ ...defaultFilters }))
    localStorage.removeItem('current_filter')
    localStorage.setItem('current_filter', currentFilter)
  }
} else {
  currentFilter = JSON.parse(JSON.stringify({ ...defaultFilters }))
  localStorage.removeItem('current_filter')
  localStorage.setItem('current_filter', currentFilter)
  localStorage.setItem('cache_version', currentVersion)

}

const state = () => ({
  currentFilter,
  filters: [],
  orderTableHeaders: defaultOrderTableHeaders,
  enabledHeaders: currentFilter.columns,
  enabledFilters: enabledFilters,
})

const apolloProvider = createProvider()

const getters = {
  getCurrentFulfilmentCentre: (state) => {
    return state.currentFilter.order_fulfilment.values
  },
  excludeCurrentFulfilmentCentre(state, getters, rootState, rootGetters) {
    const currentFulfillmentCentre = getters.getCurrentFulfilmentCentre

    if (currentFulfillmentCentre.length === 1) {
      const [firstFc] = getters.getCurrentFulfilmentCentre
      return rootGetters['metas/getFulfilmentCentres'].filter(item => firstFc !== item.Code)
    }
    return rootGetters['metas/getFulfilmentCentres']
  },
  getHeaders: (state) => {
    if (state.currentFilter.columns !== undefined) {
      return defaultOrderTableHeaders.filter(header => state.currentFilter.columns.includes(header.value))
    }
    return defaultOrderTableHeaders
  },
  getDefaultHeaders: () => {
    return defaultOrderTableHeaders
  },
  getEnabledHeaders: (state) => {
    if (!state.currentFilter.columns.includes('order_number')) {
      state.currentFilter.columns.push('order_number')
    }
    return state.currentFilter.columns
  },
  getEnabledFilters: (state) => {
    return state.enabledFilters
  },

  getCurrentFilter: (state) => {
    return state.currentFilter
  },

  getFilters: (state) => {
    return state.filters
  },

  getAvailableFilters: (state) => {
    return defaultEnabledFilters
  },

  getNonEmptyCurrentFilters: (state, getters, rootState, rootGetters) => {
    const filters = state.currentFilter
    const appliedFilters = {}
    for (let filterIndex in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, filterIndex)) {
        let filter = filters[filterIndex]

        if (!filter) {
          continue
        }

        if (filter.__typename) {
          delete filter.__typename
        }

        if (filter.attributes) {
          delete filter.attributes.__typename
        }

        if (filter.values) {
          delete filter.values.__typename
        }

        if (typeof filter === 'string' && filter !== '') {
          appliedFilters[filterIndex] = filter
        }

        if (filter.values && ((filter.values.length > 0) || (filter.values.from && filter.values.to))) {
          appliedFilters[filterIndex] = filter
        }
      }
    }

    appliedFilters.columns = [...state.currentFilter.columns]

    if (appliedFilters.columns.includes('products')) {
      // we do not have these fields in FE
      // but backend requires these fields to be there
      // instead of `products` as a whole
      appliedFilters.columns = appliedFilters.columns.concat([
        "products.name",
        "products.sku",
        "products.quantity",
        "products.status",
        "products.power",
        "products.vendor",
        "products.sku_total",
        "products.prescription_url",

      ])
      const productIndex = appliedFilters.columns.indexOf('products')
      delete appliedFilters.columns[productIndex]
      //this makes array of unique columns
      appliedFilters.columns = [...new Set(appliedFilters.columns)]
    }

    appliedFilters.columns = appliedFilters.columns.filter(column => allowedColumns.includes(column))
    const currentFulfilmentCentre = rootGetters['metas/getCurrentFulfilmentCentre']

    if (currentFulfilmentCentre) {
      appliedFilters.order_fulfilment = {
        attributes: {
          field: "fulfilment_centre.id",
          type: 'opt-str'
        },
        values: currentFulfilmentCentre
      }
    }
    return appliedFilters
  },
}

const actions = {
  async deleteFilter({ commit }, id) {
    try {
      await apolloProvider.defaultClient.mutate({
        mutation: require("@/graphql/orders/DeleteFilter.gql"),
        fetchPolicy: 'no-cache',
        variables: {
          id
        }
      })

      commit("FILTER_DELETED", id)
      commit('CLEAR_FILTER')
    } catch (e) {
      commit('FILTER_DELETE_FAILED')
    }
  },
  addKeyword({ commit }, keyword) {
    commit('ADD_KEYWORD', keyword)
  },
  removeKeyword({ commit }, index) {
    commit('REMOVE_KEYWORD', index)
  },
  clearFilter({ commit }) {
    commit('CLEAR_FILTER')
  },

  async fetchFilters({ commit }) {
    const response = await apolloProvider.defaultClient.query({
      query: require("@/graphql/orders/FetchFilters.gql"),
      fetchPolicy: 'no-cache',
    })

    const allFilters = [{ id: 'default', name: 'Default' }].concat(response.data.savedSearchFilters)
    commit('FILTERS_FETCHED', allFilters)
  },

  async fetchFilter({ commit }, id) {

    if (id === 'default') {
      commit('FILTER_FETCHED', { ...defaultFilters })
      return
    }

    try {
      const response = await apolloProvider.defaultClient.query({
        query: require("@/graphql/orders/FetchFilter.gql"),
        fetchPolicy: 'no-cache',
        variables: {
          id
        }
      })
      commit('FILTER_FETCHED', response.data.savedSearchFilter)
    } catch (error) {
      console.log('failed fetching filter')
    }
  },
  addRemoveColumn({ commit }, selectedHeaders) {
    commit('ADD_REMOVE_COLUMN', selectedHeaders)
  },
  addRemoveFilter({ commit }, selectedFilters) {
    localStorage.setItem('enabled_filters', JSON.stringify(selectedFilters))
    commit('ADD_REMOVE_FILTERS', selectedFilters)
  },
  async saveFilter({ commit, rootState, rootGetters }, { asNew, name }) {
    const filters = rootState.filters.currentFilter
    const appliedFilters = rootGetters['filters/getNonEmptyCurrentFilters']

    if (asNew) {
      delete appliedFilters.id
      appliedFilters.filter_name = name
    }

    try {
      const response = await apolloProvider.defaultClient.mutate({
        mutation: require("@/graphql/orders/SaveFilter.gql"),
        fetchPolicy: 'no-cache',
        variables: appliedFilters
      })

      if (asNew) {
        commit('FILTER_CREATED', { name: appliedFilters.filter_name, id: response.data.saveSearchFilters.id })
        return
      }
      commit('FILTER_UPDATED', filters)

    } catch (error) {
      commit('FILTER_SAVE_FAILED', filters)
    }
  },
}

const mutations = {
  FILTER_DELETION_FAILED() {
    //
  },
  FILTER_DELETED(state, id) {
    const filterIndex = state.filters.findIndex(filter => filter.id === id)
    state.filters.splice(filterIndex, 1)
  },
  FILTER_CREATED(state, filter) {
    state.filters.push(filter)
  },
  FILTER_UPDATED(state, filterName) {
    localStorage.removeItem('current_filter')
    localStorage.setItem('current_filter', JSON.stringify(state.currentFilter))
  },
  ADD_KEYWORD(state, keyword) {
    const keywords = [...state.currentFilter.order_keywords.values]
    keywords.push(keyword)
    state.currentFilter.order_keywords.values = [...keywords]
  },
  REMOVE_KEYWORD(state, index) {
    const keywords = [...state.currentFilter.order_keywords.values]
    keywords.splice(index, 1)
    state.currentFilter.order_keywords.values = [...keywords]
  },
  CLEAR_FILTER(state) {
    //I just could not figure out to reset the object
    //Vue seems to cache the object with id key
    //for example when current filter is with id -> `default` and
    //some changes are made it caches the object with that id
    //when we reset the filter, i.e. revert to id ->  `default`
    //it restores to last modified state
    //which means it does not fully reset the object
    //hence copy pasting the large json in three different places
    //should look into this when we have time
    state.currentFilter = {
      id: 'default',
      related_order: {
        attributes: {
          field: "related_order",
          type: 'number'
        },
        values: [],
      },
      order_prescription_type: {
        attributes: {
          field: "prescription_type",
          type: 'str-options'
        },
        values: [],
      },
      order_general_search: {
        attributes: {
          field: "email,id,order_number,phone,awb_number,client_name,address,products.name,products.sku,related_order",
          type: 'string'
        },
        values: '',
      },
      order_date: {
        attributes: {
          field: "order_created_at",
          type: 'str-range'
        },
        values: {
          from: null,
          to: null
        }
      },
      order_shipping_date: {
        attributes: {
          field: "shipping_date",
          type: 'str-range'
        },
        values: {
          from: null,
          to: null
        }
      },
      delivery_date: {
        attributes: {
          field: "delivery_date",
          type: 'str-range'
        },
        values: {
          from: null,
          to: null
        }
      },
      order_category: {
        attributes: {
          field: "products.category",
          type: 'str-options'
        },
        values: []
      },
      order_status: {
        attributes: {
          field: "order_status",
          type: 'str-options'
        },
        values: []
      },
      order_type: {
        attributes: {
          field: "order_type",
          type: 'str-options'
        },
        values: []
      },
      order_hold_status: {
        attributes: {
          field: "secondary_order_status",
          type: 'str-options'
        },
        values: []
      },
      order_cancellation_reason: {
        attributes: {
          field: "cancellation_reason",
          type: 'str-options'
        },
        values: []
      },
      order_sku_status: {
        attributes: {
          field: "products.status",
          type: 'str-options'
        },
        values: []
      },
      order_payment_method: {
        attributes: {
          field: "payment_method",
          type: 'str-options'
        },
        values: []
      },
      order_shipping_city: {
        attributes: {
          field: "city",
          type: 'str-options'
        },
        values: []
      },
      order_shipping_country: {
        attributes: {
          field: "country",
          type: 'str-options'
        },
        values: []
      },
      order_store: {
        attributes: {
          field: "store_details.country_language",
          type: 'str-options'
        },
        values: []
      },
      order_shipping_type: {
        attributes: {
          field: "shipping_type",
          type: 'str-options'
        },
        values: []
      },
      order_require_fitting: {
        attributes: {
          field: "products.require_fitting",
          type: 'boolean'
        },
        values: null
      },
      order_total_skus: {
        attributes: {
          field: "total_skus",
          type: "float-range"
        },
        values: {
          from: null,
          to: null,
        }
      },
      order_cod_fee: {
        attributes: {
          field: "cod_fee",
          type: "float-range"
        },
        values: {
          from: null,
          to: null
        }
      },
      order_discount_amount: {
        attributes: {
          field: "discount_amount",
          type: "float-range"
        },
        values: {
          from: null,
          to: null
        }
      },
      order_grand_total: {
        attributes: {
          field: "grand_total",
          type: "float-range"
        },
        values: {
          from: null,
          to: null
        }
      },
      order_sku: {
        attributes: {
          field: "products.sku",
          type: 'string'
        },
        values: null
      },
      order_client_name: {
        attributes: {
          field: "client_name",
          type: 'string'
        },
        values: null
      },
      order_shipping_fee: {
        attributes: {
          field: "grand_total",
          type: "float-range"
        },
        values: {
          from: null,
          to: null
        }
      },
      order_shipping_courier_name: {
        attributes: {
          field: "shipping_courier_name",
          type: 'str-options'
        },
        values: []
      },
      order_transit_courier_name: {
        attributes: {
          field: "transit_courier_name",
          type: 'str-options'
        },
        values: []
      },
      order_transit_awb_number: {
        attributes: {
          field: "transit_awb_number",
          type: 'string'
        },
        values: null
      },
      order_shipped_to_fc: {
        attributes: {
          field: "shipped_to_fc",
          type: 'str-options'
        },
        values: []
      },
      order_pos_store_name: {
        attributes: {
          field: "pos_store_name",
          type: 'str-options'
        },
        values: []
      },
      per_page: null,
      page: 1,
      order_fulfilment: {
        attributes: {
          field: "fulfilment_centre.id",
          type: 'opt-str'
        },
        values: [
          'WH_01',
        ]
      },
      filter_name: "Default",
      order_keywords: {
        attributes: {
          field: "email,id,order_number,phone,awb_number,client_name,address,related_order",
          type: 'string'
        },
        values: []
      },
      columns: defaultOrderTableHeaders.map(header => header.value),
      sort_by: [],
    }
  },
  FILTERS_FETCHED(state, filters) {

    const id = 'default'
    filters.unshift({
      name: 'Default',
      id
    })

    state.filters = filters
  },
  FILTER_FETCHED(state, filters) {

    for (let filterIndex in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, filterIndex)) {
        let filter = filters[filterIndex]
        if (!filter) {
          delete filters[filterIndex]
        }
      }
    }
    const filterDefaults = JSON.parse(JSON.stringify(defaultFilters))
    state.currentFilter = Object.assign(filterDefaults, filters)

    const hasProductColumn = !!state.currentFilter.columns.filter(column => column.includes('products.')).length
    if(hasProductColumn) {
      state.currentFilter.columns.push('products')
    }
    localStorage.removeItem('current_filter')
    localStorage.setItem('current_filter', JSON.stringify(state.currentFilter))
  },
  ADD_REMOVE_COLUMN(state, selectedHeaders) {
    let columns = [...selectedHeaders]
    if (!selectedHeaders.includes('products')) {
      columns = columns.filter(column => !column.includes('products.'))
    }
    state.currentFilter.columns = columns
  },
  ADD_REMOVE_FILTERS(state, enabledFilters) {
    state.enabledFilters = [...enabledFilters]
  },
  FILTER_SAVE_FAILED(state) {
    //
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
