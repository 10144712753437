<template>
  <div>
    <v-row>
      <v-col
          sm="6"
          md="3"
          lg="3"
          xs="12"
      >
        <v-select
            v-model="getCurrentFilter.order_fulfilment.values"
            :items="fulfilmentCentres"
            label="Fulfilment Centre"
            item-text="DisplayName"
            item-value="Code"
            filled
            multiple
            @change="update"
        >
          <template v-slot:prepend-item>
            <v-list-item
                ripple
                @click="toggle"
            >
              <v-list-item-action>
                <v-icon :color="getCurrentFilter.order_fulfilment.values.length > 0 ? 'indigo darken-4' : ''">
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title ref="btnToggle">
                  Select All
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"/>
          </template>
          <template
              slot="selection"
              slot-scope="data"
          >
            <span v-if="getCurrentFulfilmentCentre.length > 1">
              {{ data.item.Code }}
              <span v-if="data.index < (getCurrentFulfilmentCentre.length - 1)">,</span>
            </span>
            <span v-else>{{ data.item.DisplayName }}</span>
          </template>
        </v-select>
      </v-col>
      <v-col
          sm="6"
          md="5"
          lg="6"
      >
        <v-text-field
            v-model="getCurrentFilter.order_general_search.values"
            placeholder="Search By Customer or Order Information"
            outlined
            prepend-inner-icon="mdi-magnify"
            @keypress.enter="searchOrder"
        />
      </v-col>
      <v-col>
        <v-btn
            x-large
            depressed
            :loading="loading"
            color="light-blue darken-1"
            class="white--text text--darken-3"
            @click="searchOrder"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
          sm="3"
          md="3"
      >
        <v-select
            v-model="enabledHeaders"
            label="Show / Hide Columns"
            :items="getDefaultHeaders"
            multiple
            outlined
            @change="addRemoveColumn"
        >
          <template v-slot:selection="{ index }">
            <span
                v-if="index === 0"
            >
              Column Headers
            </span>
          </template>
        </v-select>
      </v-col>

      <v-col
          sm="3"
          md="3"
          class="pb-0"
      >
        <v-select
            v-model="getCurrentFilter.id"
            :items="getFilters"
            label="Saved Filters"
            item-text="name"
            item-value="id"
            outlined
            @change="searchFilter"
        />
      </v-col>
      <v-col
          md="5"
          class="pb-0 float-left"
      >
        <v-btn
            large
            depressed
            :disabled="shouldDisableUpdate"
            :loading="savingFilter"
            color="white--text text--darken-3"
            class="light-green darken-1 mr-2"
            @click="saveFilter(false)"
        >
          Update Filter
        </v-btn>
        <v-btn
            :disabled="shouldDisableDelete"
            large
            depressed
            class="white--text text--darken-3 mr-2"
            color="deep-orange darken-1"
            @click="deleteFilter"
        >
          Delete Filter
        </v-btn>
        <v-btn
            large
            depressed
            class="white--text text--darken-3 mr-1"
            color="deep-orange darken-1"
            @click="clearFilter"
        >
          Clear All
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-0 pb-7">
        <v-btn
            large
            depressed
            :loading="savingFilter"
            color="light-blue darken-1"
            class="white--text text--darken-3"
            @click="showFilter()"
        >
          <span>Show/Hide Filters</span>
          <v-icon
              v-if="!showFilters"
              class="pl-3"
          >
            mdi-chevron-right
          </v-icon>
          <v-icon
              v-else
              class="pl-3"
          >
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-card
        v-if="showFilters"
        elevation="3"
        class="pa-7"
    >
      <v-row>
        <v-col
            sm="2"
            md="2"
        >
          <h2>
            Search Filters
          </h2>
        </v-col>
        <v-row>
          <v-col
              sm="6"
              md="6"
          >
            <v-select
                v-model="enabledFilters"
                label="Show/Hide Filter Items"
                :items="getAvailableFilters"
                outlined
                multiple
                class="pa-1"
                @change="addRemoveFilter"
            >
              <template v-slot:selection="{ index }">
                <span
                    v-if="index === 1"
                    class="grey--text caption"
                >
                  {{ enabledFilters.length - 1 }} active filters
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-col
            md="5"
        >
          <v-dialog
              v-model="saveSearchDialog"
              persistent
              max-width="600"
          >
            <template v-slot:activator="{ attrs }">
              <v-btn
                  depressed
                  color="deep-orange darken-1"
                  class="white--text pa-3 float-right"
                  @click="clearFilter"
              >
                Clear All
              </v-btn>
              <v-btn
                  depressed
                  :loading="savingFilter"
                  class="white--text text--darken-3 pa-3 mr-5 float-right"
                  v-bind="attrs"
                  color="light-green darken-1"
                  @click="showSaveFilterDialog"
              >
                <v-icon>mdi-filter</v-icon>
                Save Filter
              </v-btn>
            </template>

            <validation-observer
                ref="saveFilterForm"
            >
              <v-card>
                <v-card-title>
                  <span class="headline">Filter Details</span>
                </v-card-title>
                <v-card-text>
                  <validation-provider
                      v-slot="{ errors }"
                      name="Filter Name"
                      rules="required"
                  >
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                              v-model="filter_name"
                              label="Filter Name*"
                              required
                              :error-messages="errors"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </validation-provider>
                </v-card-text>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="saveSearchDialog = false"
                  >
                    Close
                  </v-btn>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="saveFilter(true)"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </validation-observer>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <v-row>
            <v-col class="pt-0 pb-5">
              <v-divider/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            class="pl-3 pt-10 pb-5"
        >
          <v-row>
            <v-col
                v-if="enabledFilters.includes('related_order')"
                md="3"
            >
              <v-text-field
                  v-model="getCurrentFilter.related_order.values"
                  type="number"
                  label="Related Order"
              />
            </v-col>
            <v-col
                v-if="enabledFilters.includes('order_date')"
                md="3"
            >
              <range-picker
                  v-model="getCurrentFilter.order_date.values"
                  label="Order Date"
                  class="pa-1"
                  @range-selected="orderCreatedDateSelected"
              />
            </v-col>
            <v-col
                v-if="enabledFilters.includes('shipping_date')"
                md="3"
            >
              <range-picker
                  v-model="getCurrentFilter.order_shipping_date.values"
                  label="Shipping Date"
                  class="pa-1"
                  @range-selected="shippingDateSelected"
              />
            </v-col>
            <v-col
                v-if="enabledFilters.includes('delivery_date')"
                md="3"
            >
              <range-picker
                  v-model="getCurrentFilter.delivery_date.values"
                  label="Delivery Date"
                  class="pa-1"
                  @range-selected="deliveryDateSelected"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            v-if="enabledFilters.includes('order_status')"
            class="pl-2"
            md="3"
        >
          <v-select
              v-model="getCurrentFilter.order_status.values"
              :items="getOrderStatuses"
              label="Order Status"
              outlined
              multiple
              class="pa-1"
          >
            <template v-slot:selection="{ index }">
              <span
                  v-if="index === 0"
              >
                Order Status
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_hold_status')"
            class="pl-2"
            md="3"
        >
          <v-select
              v-model="getCurrentFilter.order_hold_status.values"
              :items="getHoldStatuses"
              label="OOS/Hold Status"
              outlined
              multiple
              class="pa-1"
          >
            <template v-slot:selection="{ index }">
              <span
                  v-if="index === 0"
              >
                OOS/Hold Status
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_cancellation_reason')"
            class="pl-2"
            md="3"
        >
          <v-select
              v-model="getCurrentFilter.order_cancellation_reason.values"
              :items="getCancellationReasons"
              label="Edited/Cancellation Reason "
              outlined
              multiple
              class="pa-1"
          >
            <template v-slot:selection="{ index }">
              <span
                  v-if="index === 0"
              >
                Edited/Cancellation Reason
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_sku_status')"
            class="pl-2"
            md="3"
        >
          <v-select
              v-model="getCurrentFilter.order_sku_status.values"
              :items="getSkuStatuses"
              label="Sku Status"
              outlined
              multiple
              class="pa-1"
          >
            <template v-slot:selection="{ index }">
              <span
                  v-if="index === 0"
              >
                Sku Status
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            v-if="enabledFilters.includes('order_prescription_type')"
            class="pl-2"
            md="3"
        >
          <v-select
              v-model="getCurrentFilter.order_prescription_type.values"
              :items="getPrescriptionTypes"
              label="Prescription Type"
              outlined
              multiple
              class="pa-1"
          >
            <template v-slot:selection="{ index }">
              <span
                  v-if="index === 0"
              >
                Prescription Type
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_category')"
            class="pl-2"
            md="3"
        >
          <v-select
              v-model="getCurrentFilter.order_category.values"
              :items="getProductCategories"
              label="Product Category"
              outlined
              multiple
              class="pa-1"
          >
            <template v-slot:selection="{ index }">
              <span
                  v-if="index === 0"
              >
                Product Category
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_payment_method')"
            class="pl-2"
            md="3"
        >
          <v-select
              v-model="getCurrentFilter.order_payment_method.values"
              :items="getPaymentMethods"
              label="Payment Method"
              outlined
              multiple
              class="pa-1"
          >
            <template v-slot:selection="{ index }">
              <span
                  v-if="index === 0"
              >
                Payment Method
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_type')"
            class="pl-2"
            md="3"
        >
          <v-select
              v-model="getCurrentFilter.order_type.values"
              :items="getOrderTypes"
              label="Order Type"
              outlined
              multiple
              class="pa-1"
              item-value="key"
              item-text="key"
              autocomplete="nope"
              :search-input.sync="orderTypeInput"
              @input="orderTypeInput=null"
          >
            <template v-slot:selection="{ index, item }">
              <span v-if="index === 0 && multipleOrderTypeSelected">
                Multiple
              </span>
              <span v-if="index === 0 && !multipleOrderTypeSelected">
                {{ item.key }}
              </span>
            </template>
          </v-select>
        </v-col>

        <v-col
            v-if="enabledFilters.includes('shipping_courier_name')"
            class="pl-2"
            md="3"
        >
          <v-autocomplete
              v-model="getCurrentFilter.order_shipping_courier_name.values"
              :items="getCouriers"
              label="Courier Name"
              multiple
              class="pa-1"
              item-value="key"
              item-text="key"
              autocomplete="nope"
              :search-input.sync="courierInput"
              @input="courierInput=null"
          >
            <template v-slot:selection="{ index, item }">
              <span
                  v-if="index === 0 && multipleCouriersSelected"
              >
                Multiple
              </span>
              <span
                  v-if="index === 0 && !multipleCouriersSelected"
              >
                {{ item }}
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_shipping_city')"
            class="pl-2"
            md="3"
        >
          <v-autocomplete
              v-model="getCurrentFilter.order_shipping_city.values"
              :items="getCities"
              label="City"
              multiple
              class="pa-1"
              item-value="key"
              item-text="key"
              autocomplete="nope"
              :search-input.sync="cityInput"
              @input="cityInput=null"
          >
            <template v-slot:selection="{ index, item }">
              <span
                  v-if="index === 0 && multipleCitiesSelected"
              >
                Multiple
              </span>
              <span
                  v-if="index === 0 && !multipleCitiesSelected"
              >
                {{ item.key }}
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_shipping_country')"
            class="pl-2"
            md="3"
        >
          <v-autocomplete
              v-model="getCurrentFilter.order_shipping_country.values"
              :items="getCountries"
              label="Country"
              item-text="country"
              item-value="code"
              multiple
              class="pa-1"
              autocomplete="nope"
              :search-input.sync="countryInput"
              @input="countryInput=null"
          >
            <template v-slot:selection="{ index, item }">
              <span
                  v-if="index === 0 && multipleCountriesSelected"
              >
                Multiple
              </span>
              <span
                  v-if="index === 0 && !multipleCountriesSelected"
              >
                {{ item.country }}
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_store')"
            class="pl-2"
            md="3"
        >
          <v-select
              v-model="getCurrentFilter.order_store.values"
              :items="getStores"
              label="Store"
              outlined
              multiple
              class="pa-1"
          >
            <template v-slot:selection="{ index }">
              <span
                  v-if="index === 0"
              >
                Store
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            v-if="enabledFilters.includes('order_shipping_type')"
            class="pl-2"
            md="3"
        >
          <v-select
              v-model="getCurrentFilter.order_shipping_type.values"
              :items="getShippingTypes"
              label="Shipping Type"
              outlined
              multiple
              class="pa-1 pt-0"
          >
            <template v-slot:selection="{ index }">
              <span
                  v-if="index === 0"
              >
                Shipping Type
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_require_fitting')"
            class="pl-2"
            md="3"
        >
          <v-select
              v-model="getCurrentFilter.order_require_fitting.values"
              :items="getBooleanTypes"
              label="Require Fitting"
              outlined
              class="pa-1 pt-0"
          />
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_total_skus')"
            md="3"
        >
          <number-range-picker
              v-model="getCurrentFilter.order_total_skus.values"
              label="Total Skus"
          />
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_grand_total')"
            md="3"
        >
          <number-range-picker
              v-model="getCurrentFilter.order_grand_total.values"
              label="Grand Total"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
            v-if="enabledFilters.includes('order_discount_amount')"
            md="3"
        >
          <number-range-picker
              v-model="getCurrentFilter.order_discount_amount.values"
              label="Discount Amount"
          />
        </v-col>

        <v-col
            v-if="enabledFilters.includes('order_shipping_fee')"
            md="3"
        >
          <number-range-picker
              v-model="getCurrentFilter.order_shipping_fee.values"
              label="Shipping Fee"
          />
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_cod_fee')"
            md="3"
        >
          <number-range-picker
              v-model="getCurrentFilter.order_cod_fee.values"
              label="Cod Fee"
          />
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_transit_courier_name')"
            class="pl-2"
            md="3"
        >
          <v-autocomplete
              v-model="getCurrentFilter.order_transit_courier_name.values"
              :items="getCouriers"
              label="Transit Courier Name"
              multiple
              class="pa-1"
              item-value="key"
              item-text="key"
              autocomplete="nope"
              :search-input.sync="transitCourierInput"
              @input="transitCourierInput=null"
          >
            <template v-slot:selection="{ index, item }">
              <span
                  v-if="index === 0 && multipleTransitCouriersSelected"
              >
                Multiple
              </span>
              <span
                  v-if="index === 0 && !multipleTransitCouriersSelected"
              >
                {{ item }}
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_shipped_to_fc')"
            class="pl-2"
            md="3"
        >
          <v-autocomplete
              v-model="getCurrentFilter.order_shipped_to_fc.values"
              :items="fulfilmentCentresForShippedToFC"
              label="Shipped To FC"
              multiple
              class="pa-1"
              item-text="DisplayName"
              item-value="Code"
              autocomplete="nope"
              :search-input.sync="shippedToFCInput"
              @input="shippedToFCInput=null"
          >
            <template v-slot:selection="{ index, item }">
              <span
                  v-if="index === 0 && multipleShippedToFCSelected"
              >
                Multiple
              </span>
              <span
                  v-if="index === 0 && !multipleShippedToFCSelected"
              >
                {{ item.Code }}
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_transit_awb_number')"
            md="3"
        >
          <v-text-field
              v-model="getCurrentFilter.order_transit_awb_number.values"
              type="string"
              label="Transit AWB Number"
          />
        </v-col>
        <v-col
            v-if="enabledFilters.includes('order_pos_store_name')"
            class="pl-2"
            md="3"
        >
          <v-autocomplete
              v-model="getCurrentFilter.order_pos_store_name.values"
              :items="getPOSStoresFromAPI"
              label="POS Stores"
              multiple
              class="pa-1"
              item-text="key"
              item-value="key"
              autocomplete="nope"
              :search-input.sync="posStoreNamesInput"
              @input="posStoreNamesInput=null"
          >
            <template v-slot:selection="{ index, item }">
              <span
                  v-if="index === 0 && multiplePOSStoreNamesInputSelected"
              >
                Multiple
              </span>
              <span
                  v-if="index === 0 && !multiplePOSStoreNamesInputSelected"
              >
                {{ item }}
              </span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            md="12"
        >
          <v-btn
              large
              depressed
              :loading="loading"
              color="light-blue darken-1"
              class="white--text text--darken-3 float-right"
              @click="searchOrder"
          >
            Search
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row :class="{ 'pt-7': showFilters }">
      <v-col
          v-if="enabledFilters.includes('order_keywords')"
          sm="6"
          md="5"
          lg="3"
          xs="12"
          class="pb-4"
      >
        <v-text-field
            v-model="currentKeyword"
            outlined
            placeholder="Press Enter to search"
            label="Keyword Search"
            hide-details="auto"
            @keydown.enter="addKeyword"
        />
      </v-col>
      <v-col>
        <v-chip
            v-for="(keyword, index) in getCurrentFilter.order_keywords.values"
            :key="`keyword-${index}`"
            class="ma-2"
            close
            color="red"
            label
            outlined
            @click:close="removeKeyword(index)"
        >
          {{ keyword }}
        </v-chip>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import RangePicker from "./common/RangePicker"
import NumberRangePicker from "./common/NumberRangePicker"
import {mapGetters} from 'vuex'
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from "vee-validate/dist/rules"

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})
setInteractionMode('eager')

export default {
  components: {
    RangePicker,
    NumberRangePicker,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      currentKeyword: '',
      order_fitting: false,
      enabledHeaders: [],
      saveSearchDialog: false,
      showFilters: false,
      current: '',
      unsubscribe: () => {
      },
      savingFilter: false,
      enabledFilters: [],
      filter_name: '',
      cityInput: null,
      countryInput: null,
      courierInput: null,
      transitCourierInput: null,
      shippedToFCInput: null,
      posStoreNamesInput: null,
      orderTypeInput: null,
    }
  },
  computed: {
    ...mapGetters('metas', [
      'getCities',
      'getFulfilmentCentres',
      'getPriceRange',
      'getOrderStatuses',
      'getProductCategories',
      'getHoldStatuses',
      'getCancellationReasons',
      'getSkuStatuses',
      'getPaymentMethods',
      'getStores',
      'getMagentoStatuses',
      'getShippingTypes',
      'getBooleanTypes',
      'getCountries',
      'getPrescriptionTypes',
      'getCouriers',
      'getOrderTypes',
      'getDefaultFulfilmentCentre',
      'getPOSStoresFromAPI',
    ]),
    ...mapGetters('filters', [
      'getCurrentFilter',
      'getFilters',
      'getDefaultHeaders',
      'getAvailableFilters',
      'getCurrentFulfilmentCentre',
    ]),
    ...mapGetters('orders', [
      'loading',
    ]),
    ...mapGetters('auth', [
      'getUser',
    ]),
    multipleCountriesSelected() {
      return this.getCurrentFilter.order_shipping_country.values.length > 1
    },
    multipleCitiesSelected() {
      return this.getCurrentFilter.order_shipping_city.values.length > 1
    },
    multipleCouriersSelected() {
      return this.getCurrentFilter.order_shipping_courier_name.values.length > 1
    },
    multipleOrderTypeSelected() {
      return this.getCurrentFilter.order_type.values.length > 1
    },
    multipleTransitCouriersSelected() {
      return this.getCurrentFilter.order_transit_courier_name.values.length > 1
    },
    multipleShippedToFCSelected() {
      return this.getCurrentFilter.order_shipped_to_fc.values.length > 1
    },
    multiplePOSStoreNamesInputSelected() {
      return this.getCurrentFilter.order_pos_store_name.values.length > 1
    },
    shouldDisableUpdate() {
      return this.getCurrentFilter.id === 'default'
    },
    shouldDisableDelete() {
      return this.getCurrentFilter.email !== this.getUser.email || this.getCurrentFilter.id === 'default'
    },
    fulfilmentCentresForShippedToFC() {
      return this.getFulfilmentCentres.map(fc => {
        fc.disabled = false
        return fc;
      })
    },
    fulfilmentCentres() {
      return this.getFulfilmentCentres.map(fc => {
        fc.disabled = false
        return fc;
      })
    },
    isAllFulfilmentSelected() {
      return this.getCurrentFilter.order_fulfilment.values.length === this.getFulfilmentCentres.length
    },
    icon() {
      if (this.isAllFulfilmentSelected) return 'mdi-close-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  mounted() {
    this.current = this.getCurrentFulfilmentCentre
    const self = this
    self.$store.dispatch('filters/fetchFilters')

    let localStorageFilter = localStorage.getItem('current_filter')
    if (localStorageFilter) {
      try {
        const currentFilter = JSON.parse(localStorageFilter)
        if (currentFilter.id) {
          this.$store.commit('filters/FILTER_FETCHED', currentFilter)
          self.enabledHeaders = currentFilter.columns
        }
      } catch (err) {
        this.$store.dispatch('filters/fetchFilter', 'default')
      }
    }

    self.$store.dispatch('metas/fetchCities')
    self.$store.dispatch('metas/fetchCouriers')
    self.$store.dispatch('metas/fetchPosStores')
    self.$store.dispatch('metas/fetchOrderTypes')
    self.enabledFilters = self.$store.state.filters.enabledFilters
    self.unsubscribe = self.$store.subscribe(mutation => {
      switch (mutation.type) {
        case 'filters/FILTER_FETCHED':
          self.enabledHeaders = self.$store.state.filters.currentFilter.columns
          self.$store.dispatch('orders/fetchOrders', {page: 1, itemsPerPage: 10})
          self.$store.dispatch('notifications/showSuccess', 'Filter applied!')
          break;
        case 'uploads/FILE_UPLOADED':
          self.$store.dispatch('notifications/showSuccess', 'File successfully processed!')
          break;
        case 'filters/FILTER_UPDATED':
        case 'filters/FILTER_CREATED':
          self.$store.dispatch('notifications/showSuccess', 'Filter successfully saved!')
          self.savingFilter = false
          break;
        case 'filters/FILTER_SAVE_FAILED':
          self.$store.dispatch('notifications/showError', 'Failed to save filter!')
          self.savingFilter = false
          break;
        case 'filters/FILTER_DELETED':
          self.$store.dispatch('notifications/showSuccess', 'Filter successfully deleted!')
          break;
        case 'filters/FILTER_DELETION_FAILED':
          self.$store.dispatch('notifications/showError', 'Filter cannot be deleted!')
          break;
        case 'filters/CLEAR_FILTER':
          self.enabledHeaders = self.$store.state.filters.currentFilter.columns
          self.$store.dispatch('orders/fetchOrders', {page: 1, itemsPerPage: 10})
          break;
      }
    })
  },
  destroyed() {
    this.unsubscribe()
    localStorage.removeItem('current_filter')
    localStorage.setItem('current_filter', JSON.stringify(this.$store.getters['filters/getCurrentFilter']))
  },
  methods: {
    showSaveFilterDialog() {
      this.saveSearchDialog = true
      this.filter_name = ''
    },
    deleteFilter() {
      const answer = confirm('Are you sure?')
      if (answer) {
        this.$store.dispatch('filters/deleteFilter', this.getCurrentFilter.id)
      }
    },
    update(centre) {
      this.$store.dispatch('metas/setCurrentFulfilmentCentre', centre)
      this.$store.dispatch('orders/fetchOrders', {page: 1, itemsPerPage: 10})
    },
    searchFilter() {
      this.$store.dispatch('filters/fetchFilter', this.getCurrentFilter.id)
    },
    clearFilter() {
      this.$store.dispatch('filters/clearFilter')
    },
    addKeyword() {
      this.$store.dispatch('filters/addKeyword', this.currentKeyword)
      this.currentKeyword = ''
      this.$store.dispatch('orders/fetchOrders', {page: 1, itemsPerPage: 10})
    },
    removeKeyword(index) {
      this.$store.dispatch('filters/removeKeyword', index)
      this.currentKeyword = ''
      this.$store.dispatch('orders/fetchOrders', {page: 1, itemsPerPage: 10})
    },

    shippingDateSelected() {

    },

    deliveryDateSelected() {

    },
    orderCreatedDateSelected() {

    },
    searchOrder() {
      //we commit this and listen in orders component
      this.$store.commit('orders/RESET_PAGINATION')
    },
    addRemoveColumn() {
      this.$store.dispatch('filters/addRemoveColumn', this.enabledHeaders)
    },
    addRemoveFilter() {
      this.$store.dispatch('filters/addRemoveFilter', this.enabledFilters)
    },
    async saveFilter(asNew) {
      let validForm = true

      //for new filter we need to validate name
      if (asNew) {
        validForm = await this.$refs.saveFilterForm.validate()
      }
      if (validForm) {
        this.savingFilter = true
        this.saveSearchDialog = false
        this.$store.dispatch('filters/saveFilter', {asNew, name: this.filter_name})
      }
    },
    showFilter() {
      this.showFilters = !this.showFilters
    },
    toggle() {
      this.$nextTick(() => {
        const selectedData = this.isAllFulfilmentSelected ? this.getDefaultFulfilmentCentre : this.getFulfilmentCentres.map(x => x.Code)
        this.getCurrentFilter.order_fulfilment.values = selectedData
        this.update(selectedData)
        this.$refs.btnToggle.innerText = this.isAllFulfilmentSelected ? 'Unselect All' : 'Select All';
      })
    },
  },
}
</script>
