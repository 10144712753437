import dotenv from 'dotenv'

dotenv.config()

function parseValueFromEnv(val) {
    // value was not replaced, it seems we are in development.
    // Remove $ and get current value from process.env
    const envName = val.substr(1)
    const envValue = process.env[envName]
    if (envValue) {
        return envValue
    } else {
        console.log(`Configuration: Environment variable "${envName}" is not defined`)
    }
}

export default class Configuration {
    static get CONFIG() {
        return {
            apiBaseURL: '$VUE_APP_API_BASE_URL',
            googleKey: '$VUE_APP_GOOGLE_KEY',
            graphqlEndpoint: '$VUE_APP_GRAPHQL_ENDPOINT',
            egToolUrl: '$VUE_APP_EG_TOOL_URL',
            magentoOrderUrl: '$VUE_APP_MAGENTO_ORDER_URL',
            features: {
                merchandiserUploadEnabled: '$VUE_APP_FEATURE_MERCHANDISER_UPLOAD_ENABLED',
            },
            //we store filters(key and values) in localstorage,
            // when we change the key(column) to query in backend,
            // it will query the old field because we still use the cached field in localstorage,
            //I introduced this tackle that, if version of localstorage and config does not match,
            //invalidate the cache in localstorage and store new filter data in localstorage
            //this can also work when invalidating other caches in the application
            //increase this by 1 to invalidate cache
            //PS: this needs to be a string, otherwise fails at `startsWith`
            cache_version: "28",
        }
    }

    static value(name) {
        if (!(name in this.CONFIG)) {
            console.log(`Configuration: There is no key named "${name}"`)
            return
        }

        const value = this.CONFIG[name]

        if (!value) {
            console.log(`Configuration: Value for "${name}" is not defined`)
            return
        }

        if (typeof value === 'object') {
            for (const key in value) {
                if (value.hasOwnProperty(key) && value[key].startsWith('$VUE_APP_')) {
                    value[key] = parseValueFromEnv(value[key]);
                }
            }
            return value;
        } else if (value.startsWith('$VUE_APP_')) {
            return parseValueFromEnv(value);
        } else {
            // value was already replaced, it seems we are in production.
            return value
        }
    }
}
