<template>
  <v-container>
    <v-row class="">
      <v-col
        sm="6"
        md="3"
        lg="3"
        xs="12"
      >
        <v-select
          v-model="currentFC"
          :items="getFulfilmentCentres"
          label="Fulfilment Centre"
          item-text="DisplayName"
          item-value="Code"
          filled
          @change="update"
        />
      </v-col>
      <v-col md="2">
        <v-select
          v-model="getFilters.status.from"
          label="From SKU Status"
          outlined
          :items="getFromStatuses"
          @change="setFromStatus"
        />
      </v-col>
      <v-col md="2">
        <v-select
          v-model="getFilters.status.to"
          :disabled="getToStatuses.length === 0"
          label="To SKU Status"
          outlined
          :items="getToStatuses"
          @change="clearList"
        />
      </v-col>
      <v-col>
        <v-btn
          :disabled="getFilters.status.to === ''"
          x-large
          depressed
          color="light-blue darken-1"
          class="white--text text--darken-3 mr-5"
          :loading="loadingSKUs"
          @click="fetchSKUsForTransition"
        >
          List All
        </v-btn>
        <v-btn
          :disabled="activateApplyBtn"
          x-large
          depressed
          class="white--text text--darken-3"
          color="light-green darken-1"
          @click="displayDialog"
        >
          <v-icon left>
            mdi-check-bold
          </v-icon>
          Approve Transition
        </v-btn>

        <v-dialog
          v-if="showDialog"
          v-model="showDialog"
          persistent
          max-width="400"
        >
          <v-card class="pb-3">
            <v-card-title class="subtitle blue-grey darken-3 white--text">
              Confirm Transition
            </v-card-title>
            <v-card-text
              color="black--text"
              class="mt-5 pr-5"
            >
              Are you sure you want to transition the
              <span class="font-weight-bold">{{ selectedSKUs.length }}</span>
              selected SKU(s) from
              <span class="font-weight-bold">{{ getFilters.status.from }}</span>
              to
              <span class="font-weight-bold">{{ getFilters.status.to }}?</span>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                depressed
                color="light-green darken-1 white--text"
                class="pa-3 pl-7 pr-7"
                @click="performTransition"
              >
                Yes
              </v-btn>
              <v-btn
                color="grey darken-3 white--text"
                class="pa-3"
                depressed
                @click="closeDialog()"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-if="displayLoader"
          v-model="displayLoader"
          persistent
          width="300"
        >
          <v-card
            color="blue-grey darken-3"
            dark
            class="pa-2"
          >
            <v-card-text class="mt-2">
              <span class="white--text"> {{ loaderMsg }}</span>
              <v-progress-linear
                indeterminate
                color="blue lighten-1"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        sm="6"
        md="3"
        lg="3"
        xs="12"
      >
        <v-btn
          large
          depressed
          color="light-blue darken-1"
          class="white--text text--darken-3"
          @click="showFilters = !showFilters"
        >
          <span>Show/Hide Filters</span>
          <v-icon
            v-if="!showFilters"
            class="pl-3"
          >
            mdi-chevron-right
          </v-icon>
          <v-icon
            v-else
            class="pl-3"
          >
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-card
      v-if="showFilters"
      elevation="3"
      class="pa-7 mt-5"
    >
      <v-row>
        <v-col>
          <h2>
            Search Filters
          </h2>
        </v-col>
        <v-col>
          <v-btn
            depressed
            color="deep-orange darken-1"
            class="white--text pa-3 float-right mb-5"
            @click="clearFilter"
          >
            Clear All
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <v-row>
            <v-col class="pt-0 pb-10">
              <v-divider />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <range-picker v-model="getFilters.order_date" />
        </v-col>
        <v-col>
          <v-select
            v-model="getFilters.categories"
            label="Product Category"
            outlined
            :items="getProductCategories"
            multiple
          >
            <template v-slot:selection="{ index }">
              <span v-if="index === 0">
                Product Category
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col>
          <v-select
            v-model="getFilters.gvpo_appearance"
            label="GVPO SKU Appearance"
            outlined
            :items="getBooleanTypes"
          />
        </v-col>
        <v-col>
          <number-range-picker
            v-model="getFilters.sku_qty"
            label="SKU Quantity"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-btn
          depressed
          :loading="loadingSKUs"
          color="light-blue darken-1"
          class="white--text text--darken-3 mb-2 ml-auto mr-3"
          large
          @click="fetchSKUsForTransition"
        >
          Search
        </v-btn>
      </v-row>
    </v-card>

    <v-row :class="[showFilters ? 'pt-7' : 'pt-4']">
      <v-col
        sm="6"
        md="5"
        lg="3"
        xs="12"
        class="pb-10"
      >
        <v-text-field
          v-model="keyword"
          outlined
          placeholder="Press Enter to search"
          label="Keyword Search"
          hide-details="auto"
          @keypress.enter="addKeyword"
        />
      </v-col>
      <v-col>
        <v-chip
          v-for="(word, index) in getFilters.keywords"
          :key="`keyword-${index}`"
          class="ma-2"
          close
          color="red"
          label
          outlined
          @click:close="removeKeyword(index)"
        >
          {{ word }}
        </v-chip>
      </v-col>
    </v-row>

    <v-alert
      v-if="showNotificationMsg"
      color="orange darken-1"
      dark
      icon="mdi-alert"
      border="left"
      class="pa-5 mb-5"
      dismissible
    >
      {{ notificationMsg }}
    </v-alert>

    <v-data-table
      v-model="selectedSKUs"
      show-select
      :headers="headers"
      :items="getSKUs"
      :loading="false"
      item-key="id"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[`item.order_number`]="{ item }">
        <router-link
          target="_blank"
          :to="{ name: 'Order', params: { id: item.order_number } }"
        >
          {{ item.order_number }}
        </router-link>
      </template>
      <template v-slot:[`item.power`]="{ item }">
        {{ formatPower(item.power) }}
      </template>
      <template v-slot:[`item.order_date`]="{ item }">
        <span>{{ formatTS(item.order_date) }}</span>
      </template>
      <template v-slot:[`item.comment`]="{ item }">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="item.comment" />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import RangePicker from "@/components/common/RangePicker"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { mapGetters, mapActions, mapMutations } from "vuex"
import Configuration from "../utils/config"
import NumberRangePicker from "../components/common/NumberRangePicker"

dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  components: {
    NumberRangePicker,
    RangePicker,
  },
  data() {
    return {
      showDialog: false,
      unsubscribe: () => {},
      headers: [
        { value: "order_number", text: "Order#" },
        { value: "order_date", text: "Order Date", width: 200 },
        { value: "sku", text: "SKU", width: 250 },
        { value: "product_name", text: "Product Name" },
        { value: "quantity", text: "Quantity", width: 100 },
        { value: "category", text: "Category", width: 200 },
        { value: "formatted_power", text: "Power", width: 300 },
        { value: "vendor", text: "Vendor", width: 100 },
        { value: "comment", text: "Comment", width: 300 },
      ],
      currentFC: "",
      keyword: "",
      showFilters: false,
      enableTransitionBtn: false,
      displayLoader: false,
      loaderMsg: "Please wait...",
      currentSelectedSKUs: 0,
    }
  },
  computed: {
    selectedSKUs: {
      set(items) {
        this.$store.state.skuStatusTransition.selectedSKUs = items
      },
      get() {
        return this.$store.getters["skuStatusTransition/getSelectedSKUs"]
      },
    },
    ...mapGetters("skuStatusTransition", [
      "getSKUs",
      "getToStatuses",
      "getFromStatuses",
      "getFilters",
      "getBooleanTypes",
      "loadingSKUs",
      "getTransitionProgress",
      "getTransitionError",
    ]),
    ...mapGetters("metas", ["getFulfilmentCentres", "getProductCategories"]),
    ...mapGetters("auth", ["getUser", "getToken"]),
    ...mapGetters("inventory", ["getCurrentFulfilmentCentre"]),
    notificationMsg() {
      if (this.getSKUs.length > 0) {
        switch (this.getFilters.status.to) {
          case "PO_Placed":
          case "In_Process":
            return "Unchecked SKUs did not appear in Generate Vendor PO even once, hence not pre-selected for the transition."
          break
          case "In_Stock":
            if (this.getFilters.status.from === "PO_Placed") {
              return "This transition is only applicable to Non-EG SKUs."
            }
            if (this.getFilters.status.from === "In_Process") {
              return "This transition is only applicable to EG SKUs."
            }
          break
          case "Reqd_Processing":
            if (this.getFilters.status.from === "PO_Placed") {
              return "This transition is only applicable to EG SKUs (LensType='NO POWER' or 'READING')."
            }
            if (this.getFilters.status.from === "Reqd_Prescription") {
              return "This transition is only applicable to EG SKUs (LensType='PRESCRIPTION')."
            }
          break
          case "Reqd_Prescription":
          return "This transition is only applicable to EG SKUs (LensType='PRESCRIPTION')."
          break
        }
      }

      return ""
    },
    activateApplyBtn() {
      return (
        this.getSKUs.length === 0 ||
        this.getFilters.status.to === "" ||
        !this.enableTransitionBtn ||
        this.selectedSKUs.length === 0
      )
    },
    showNotificationMsg() {
      return this.getFilters.status.to !== "" && this.getSKUs.length > 0
    },
  },
  mounted() {
    const self = this
    this.currentFC = this.getCurrentFulfilmentCentre
    this.$store.dispatch("metas/fetchFulfilmentCentres")

    self.unsubscribe = self.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "skuStatusTransition/SKUS_FETCHED":
          self.$store.dispatch('errors/removeErrors')
          if (this.getSKUs.length === 0) {
            this.$store.dispatch(
              "notifications/showError",
              "No results for this filter!"
            )
            this.enableTransitionBtn = false
          } else {
            this.enableTransitionBtn = true
          }
        break
        case "skuStatusTransition/FROM_STATUS_SET":
          this.enableTransitionBtn = false
        break
        case "skuStatusTransition/TRANSITION_ERRORED":
          let errors = ['Transition request failed! Please try again!']
          if (self.$store.getters['skuStatusTransition/getTransitionError']) {
            errors = self.$store.getters['skuStatusTransition/getTransitionError'].split(',')
          }
          let label = "Attempt to transition " + this.currentSelectedSKUs + " SKU(s) from " +
            this.getFilters.status.from + " => " +
            this.getFilters.status.to + " yielded the following errors!"
          self.$store.dispatch('errors/showErrors', { errors, label })
          this.enableTransitionBtn = true
          this.displayLoader = false
        break
        case "skuStatusTransition/TRANSITION_COMPLETED":
          this.enableTransitionBtn = false
          this.fetchSKUsForTransition( { notifyCompleted: true } )
          this.loaderMsg = "Successful. Reloading list..."
        break
        case "skuStatusTransition/LOADING_SKUS_COMPLETED":
          self.$store.dispatch('notifications/showSuccess', this.currentSelectedSKUs + " SKU(s) transitioned from " + this.getFilters.status.from + " => " +
            this.getFilters.status.to + " successfully.", )
          this.displayLoader = false
          this.loaderMsg = "Please wait..."
        break
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  methods: {
    ...mapActions("skuStatusTransition", [
      "clearFilter",
      "fetchSKUsForTransition",
      "transitionSKUStatuses",
    ]),
    ...mapMutations("skuStatusTransition", {
      setFromStatus: "FROM_STATUS_SET",
      clearList: "TO_STATUS_SET",
    }),
    formatTS(timestamp) {
      if (timestamp !== "") {
        return dayjs.tz(timestamp, "Asia/Dubai").format("lll")
      }
    },
    formatPower(power) {
      if (power) {
        delete power.__typename
      }
      return power
    },
    addKeyword() {
      this.$store.commit("skuStatusTransition/ADD_KEYWORD", this.keyword)
      this.keyword = ""
      this.fetchSKUsForTransition( { notifyCompleted: false } )
    },
    removeKeyword(index) {
      this.$store.commit("skuStatusTransition/REMOVE_KEYWORD", index)
      this.fetchSKUsForTransition( { notifyCompleted: false } )
    },
    update(centre) {
      this.$store.dispatch("inventory/setCurrentFC", centre)
    },
    displayDialog() {
      if (this.getSKUs.length > 0) {
        this.showDialog = true
      }
    },
    performTransition() {
      this.showDialog = false
      this.displayLoader = true
      this.currentSelectedSKUs = this.selectedSKUs.length
      this.$store.commit("skuStatusTransition/TRANSITION_IN_PROGRESS")
      this.transitionSKUStatuses()
    },
    closeDialog() {
      this.showDialog = false
    },
  },
}
</script>
