import Vue from 'vue'
import VueRouter from 'vue-router'
import Orders from '../views/Orders.vue'
import Login from "../views/Login"
import Order from "../views/Order"
import Inventory from "../views/Inventory"
import InventoryJIT from "../views/InventoryJIT"
import FulfilmentCentres from "../views/FulfilmentCentres"
import Uploads from "../views/Uploads"
import Admin from "../layouts/Admin"
import LoginLayout from "../layouts/Login"
import SKUStatusTransition from "../views/SKUStatusTransition"
import SystemSettings from "../views/SystemSettings"
import MerchandiserUploads from "../views/MerchandiserUploads"

Vue.use(VueRouter)

Vue.component('login-layout', LoginLayout);
Vue.component('admin-layout', Admin);

const routes = [
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: {
      layout : 'admin-layout',
      title: 'Orders List',
    }
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      layout : 'login-layout',
      title: 'Login',
    }
  },
  {
    path: '/orders/:id',
    name: 'Order',
    component: Order,
    meta: {
      layout : 'admin-layout',
      title: 'Order View',
    }
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: Inventory,
    meta: {
      layout : 'admin-layout',
      title: 'Inventory Management - Stock Sheet',
    }
  },
  {
    path: '/inventory-jit',
    name: 'InventoryJIT',
    component: InventoryJIT,
    meta: {
      layout : 'admin-layout',
      title: 'Inventory Management - Back Order',
    }
  },
  {
    path: '/fulfilment-centres',
    name: 'FulfilmentCentres',
    component: FulfilmentCentres,
    meta: {
      layout : 'admin-layout',
      title: 'Inventory Management - Fulfilment Centres',
    }
  },
  {
    path: '/uploads',
    name: 'Uploads',
    component: Uploads,
    meta: {
      layout : 'admin-layout',
      title: 'Uploads',
    }
  },
  {
    path: '/merchandiser-uploads',
    name: 'MerchandiserUploads',
    component: MerchandiserUploads,
    meta: {
      layout : 'admin-layout',
      title: 'Merchandiser Uploads',
    }
  },
  {
    path: '/sku-status-transition',
    name: 'SKUStatusTransition',
    component: SKUStatusTransition,
    meta: {
      layout : 'admin-layout',
      title: 'SKU Status Transition',
    }
  },
  {
    path: '/system-settings',
    name: 'SystemSettings',
    component: SystemSettings,
    meta: {
      layout : 'admin-layout',
      title: 'System Settings',
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Eyewa OMS`
  next()
})

export default router
