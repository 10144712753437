const defaultOrderTableHeaders = [
  { text: "Order#", value: "order_number", disabled: true, sortable: false },
  { text: "Related Order", value: "related_order", sortable: false },
  { text: "Order date", value: "order_created_at", sortable: true, width: 200 },
  { text: "Name", value: "client_name", sortable: false, width: 200 },
  { text: "Products", value: "products", sortable: false },
  { text: "Coupon code", value: "discount_code", sortable: false, width: 200 },
  { text: "Total SKUs", value: "total_skus", sortable: false },
  { text: "Order Status", value: "order_status", sortable: false },
  { text: "Order Type", value: "order_type", sortable: false },
  { text: "Fulfilment Centre", value: "fulfilment_centre", sortable: false },
  { text: "Comments", value: "comments", sortable: false },
  { text: "Shipping Date", value: "shipping_date", sortable: false, width: 120 },
  { text: "Delivery Date", value: "delivery_date", sortable: false, width: 120 },
  { text: "Grand total", value: "grand_total", sortable: false, width: 120 },
  { text: "Cancellation reason", value: "cancellation_reason", sortable: false, width: 300 },
  { text: "Payment method", value: "payment_method", sortable: false },
  { text: "Phone number", value: "phone", sortable: false },
  { text: "Email", value: "email", sortable: false, width: 300 },
  { text: "Address", value: "address", sortable: false, width: 200 },
  { text: "City", value: "city", sortable: false, width: 150 },
  { text: "Country", value: "country", sortable: false },
  { text: "Store?", value: "store", sortable: false, width: 250 },
  { text: "Pos store name", value: "pos_store_name", sortable: false , width: 250 },
  { text: "Discount amount", value: "discount_amount", sortable: false, width: 100 },
  { text: "Month", value: "month", sortable: false },
  { text: "Shipping fee", value: "shipping_fee", sortable: false, width: 100 },
  { text: "Courier Name", value: "shipping_courier_name", sortable: false, width: 150 },
  { text: "COD fee", value: "cod_fee", sortable: false, width: 100 },
  { text: "Shipping Type", value: "shipping_type", sortable: false, width: 200 },
  { text: "OOS/Hold Status", value: "secondary_order_status", sortable: false, width: 300 },
  { text: "AWB Number", value: "awb_number", sortable: false },
  { text: "Order Tracking Link", value: "order_tracking_number", sortable: false },
  { text: "Date - Ops OOS/Hold Update", value: "secondary_order_status_timestamp", sortable: false , width: 200 },
  { text: "Prescription Type", value: "prescription_type", sortable: false , width: 200 },
  { text: "Transit Courier Name", value: "transit_courier_name", sortable: false , width: 200 },
  { text: "Transit AWB Number", value: "transit_awb_number", sortable: false , width: 200 },
  { text: "Shipped To FC", value: "shipped_to_fc", sortable: false , width: 200 },
]

const keywordFields = [
  "email",
  "id",
  "order_number",
  "phone",
  "awb_number",
  "client_name",
  "address",
  "discount_code",
  "products.sku",
  "products.name",
  "comments.comment",
  "order_tracking_number",
  "products.power.rcyl",
  "products.power.lcyl",
  "products.power.raxis",
  "products.power.laxis",
  "products.power.rsph",
  "products.power.lsph",
  "products.power.pd",
  "products.power.lens_type",
  "products.power.coating",
  "shipping_courier_name",
  "related_order",
]

const defaultFilters = {
  id: 'default',
  order_prescription_type: {
    attributes: {
      field: "prescription_type",
      type: 'str-options'
    },
    values: []
  },
  order_general_search: {
    attributes: {
      field: "email,id,order_number,phone,awb_number,client_name,address,products.name,products.sku,related_order",
      type: 'string'
    },
    values: '',
  },
  order_date: {
    attributes: {
      field: "order_created_at",
      type: 'str-range'
    },
    values: {
      from: null,
      to: null
    }
  },
  order_shipping_date: {
    attributes: {
      field: "shipping_date",
      type: 'str-range'
    },
    values: {
      from: null,
      to: null
    }
  },
  delivery_date: {
    attributes: {
      field: "delivery_date",
      type: 'str-range'
    },
    values: {
      from: null,
      to: null
    }
  },
  order_category: {
    attributes: {
      field: "products.category",
      type: 'str-options'
    },
    values: []
  },
  order_status: {
    attributes: {
      field: "order_status",
      type: 'str-options'
    },
    values: []
  },
  order_type: {
    attributes: {
      field: "order_type",
      type: 'str-options'
    },
    values: []
  },
  order_hold_status: {
    attributes: {
      field: "secondary_order_status",
      type: 'str-options'
    },
    values: []
  },
  order_cancellation_reason: {
    attributes: {
      field: "cancellation_reason",
      type: 'str-options'
    },
    values: []
  },
  order_sku_status: {
    attributes: {
      field: "products.status",
      type: 'str-options'
    },
    values: []
  },
  order_payment_method: {
    attributes: {
      field: "payment_method",
      type: 'str-options'
    },
    values: []
  },
  order_shipping_city: {
    attributes: {
      field: "city",
      type: 'str-options'
    },
    values: []
  },
  order_shipping_country: {
    attributes: {
      field: "country",
      type: 'str-options'
    },
    values: []
  },
  order_store: {
    attributes: {
      field: "store_details.store_country_language",
      type: 'str-options'
    },
    values: []
  },
  order_shipping_type: {
    attributes: {
      field: "shipping_type",
      type: 'str-options'
    },
    values: []
  },
  order_require_fitting: {
    attributes: {
      field: "products.require_fitting",
      type: 'boolean'
    },
    values: null
  },
  order_total_skus: {
    attributes: {
      field: "total_skus",
      type: "float-range"
    },
    values: {
      from: null,
      to: null,
    }
  },
  order_cod_fee: {
    attributes: {
      field: "cod_fee",
      type: "float-range"
    },
    values: {
      from: null,
      to: null
    }
  },
  order_discount_amount: {
    attributes: {
      field: "discount_amount",
      type: "float-range"
    },
    values: {
      from: null,
      to: null
    }
  },
  order_grand_total: {
    attributes: {
      field: "grand_total",
      type: "float-range"
    },
    values: {
      from: null,
      to: null
    }
  },
  order_sku: {
    attributes: {
      field: "products.sku",
      type: 'string'
    },
    values: null
  },
  order_client_name: {
    attributes: {
      field: "client_name",
      type: 'string'
    },
    values: null
  },
  order_shipping_fee: {
    attributes: {
      field: "shipping_fee",
      type: "float-range"
    },
    values: {
      from: null,
      to: null
    }
  },
  order_shipping_courier_name: {
    attributes: {
      field: "shipping_courier_name",
      type: "str-options"
    },
    values: []
  },
  order_transit_courier_name: {
    attributes: {
      field: "transit_courier_name",
      type: "str-options"
    },
    values: []
  },
  order_transit_awb_number: {
    attributes: {
      field: "transit_awb_number",
      type: "string"
    },
    values: null
  },
  order_shipped_to_fc: {
    attributes: {
      field: "shipped_to_fc",
      type: 'opt-str'
    },
    values: []
  },
  order_pos_store_name: {
    attributes: {
      field: "pos_store_name",
      type: 'opt-str'
    },
    values: []
  },
  related_order: {
    attributes: {
      field: "related_order",
      type: "number"
    },
    values: []
  },
  per_page: null,
  page: 1,
  order_fulfilment: {
    attributes: {
      field: "fulfilment_centre.id",
      type: 'opt-str'
    },
    values: [
      'WH_01'
    ]
  },
  filter_name: "Default",
  order_keywords: {
    attributes: {
      field: keywordFields.join(','),
      type: 'string'
    },
    values: []
  },
  columns: defaultOrderTableHeaders.map(header => header.value),
  sort_by: [],
}

const defaultEnabledFilters = [
  {
    "value": "related_order",
    "text": "Related Order"
  },
  {
    "value": "order_date",
    "text": "Order Date"
  },
  {
    "value": "shipping_date",
    "text": "Shipping Date"
  },
  {
    "value": "order_category",
    "text": "Category"
  },
  {
    "value": "order_status",
    "text": "Status"
  },
  {
    "value": "order_type",
    "text": "Type"
  },
  {
    "value": "order_hold_status",
    "text": "OOS/Hold Status"
  },
  {
    "value": "order_cancellation_reason",
    "text": "Edited/Cancellation Reason"
  },
  {
    "value": "order_sku_status",
    "text": "Sku Status"
  },
  {
    "value": "order_payment_method",
    "text": "Payment Method"
  },
  {
    "value": "order_shipping_country",
    "text": "Shipping Country"
  },
  {
    "value": "order_shipping_city",
    "text": "Shipping City"
  },
  {
    "value": "order_store",
    "text": "Store"
  },
  {
    "value": "pos_store_name",
    "text": "POS Store Name"
  },
  {
    "value": "order_shipping_type",
    "text": "Shipping Type"
  },
  {
    "value": "order_total_skus",
    "text": "Total Skus"
  },
  {
    "value": "order_require_fitting",
    "text": "Require Fitting"
  },
  {
    "value": "order_cod_fee",
    "text": "Cod Fee"
  },
  {
    "value": "order_discount_amount",
    "text": "Discount Amount"
  },
  {
    "value": "order_grand_total",
    "text": "Grand Total"
  },
  {
    "value": "order_sku",
    "text": "Sku"
  },
  {
    "value": "order_client_name",
    "text": "Name"
  },
  {
    "value": "order_shipping_fee",
    "text": "Shipping Fee"
  },
  {
    "value": "shipping_courier_name",
    "text": "Courier Name"
  },
  {
    "value": "order_keywords",
    "text": "Keywords"
  },
  {
    "value": "order_prescription_type",
    "text": "Prescription Type"
  },
  {
    "value": "delivery_date",
    "text": "Delivery Date"
  },
  {
    "value": "order_transit_courier_name",
    "text": "Transit Courier Name"
  },
  {
    "value": "order_transit_awb_number",
    "text": "Transit AWB Number"
  },
  {
    "value": "order_shipped_to_fc",
    "text": "Shipped To FC"
  },
  {
    "value": "order_pos_store_name",
    "text": "POS Stores"
  },
]

const allowedColumns = [
  "order_status",
  "order_type",
  "payment_method",
  "secondary_order_status",
  "shipping_date",
  "store",
  "pos_store_name",
  "shipping_type",
  "order_number",
  "email",
  "city",
  "country",
  "discount_amount",
  "address",
  "discount_code",
  "fulfilment_centre",
  "related_orders",
  "comments",
  "cod_fee",
  "client_name",
  "cancellation_reason",
  "grand_total",
  "customer_surveyed",
  "month",
  "order_created_at",
  "phone",
  "shipping_fee",
  "shipping_courier_name",
  "secondary_order_status_timestamp",
  "awb_number",
  "order_tracking_number",
  "products.name",
  "products.sku",
  "products.quantity",
  "products.status",
  "products.power",
  "products.vendor",
  "products.sku_total",
  "products.prescription_url",
  "products.original_price",
  "products.price",
  "products.price_incl_tax",
  "products.base_original_price",
  "products.tax_amount",
  "products.base_discount_amount",
  "products.discount_amount",
  "products.row_total_incl_tax",
  "products.sub_total",
  "related_order",
  "prescription_type",
  "delivery_date",
  "transit_courier_name",
  "transit_awb_number",
  "shipped_to_fc",
]

export {
  defaultFilters,
  defaultOrderTableHeaders,
  defaultEnabledFilters,
  allowedColumns,
}
